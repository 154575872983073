import { Avatar, Button, Card, Col, Row, Tabs } from 'antd';
import BgProfile from '../../../assets/images/bg-profile.jpg';
import adminIcon from '../../../assets/images/adminIcon.png';
import AdminInfoCard from './AdminInfoCard';
import AdminUpdateForm from './AdminUpdateForm';
import FullLayout from '../../../layouts/layout-components/FullLayout';

const AdminProfile = () => {
	return (
		<FullLayout pageTitle="Admin">
			<div
				className="profile-nav-bg"
				style={{ backgroundImage: `url(${BgProfile})` }}
			/>

			<Card
				className="card-profile-head"
				style={{
					borderBottom: '0px',
					padding: '0px 16px',
					margin: '-53px 0 24px',
					boxShadow: '0 5px 10px rgb(0 0 0 / 12%)',
					borderRadius: '12px',
					height: '7rem',
					fontSize: '24px',
					justifySelf: 'center',
				}}
				title={
					<Row justify="space-between" align="middle" gutter={[24, 0]}>
						<Col span={24} md={12} className="col-info">
							<Avatar.Group>
								<Avatar size={74} shape="square" src={adminIcon} />

								<div>
									<h3>
										<strong> Obinna Emmanuel</strong>{' '}
									</h3>
									<p>Super Admin</p>
								</div>
							</Avatar.Group>
						</Col>
						<Col
							span={24}
							md={12}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							{/* <Radio.Group defaultValue="a">
                <Radio.Button value="a">OVERVIEW</Radio.Button>
                <Radio.Button value="b">TEAMS</Radio.Button>
                <Radio.Button value="c">PROJECTS</Radio.Button>
              </Radio.Group> */}
						</Col>
					</Row>
				}
			/>

			<Card
				bordered={false}
				title={<h6 className="font-semibold m-0">Profile Information</h6>}
				className="header-solid h-full card-profile-information"
				extra={<Button type="link" />}
				bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
			>
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane tab="Info" key="1">
						<AdminInfoCard />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Update" key="2">
						<AdminUpdateForm />
					</Tabs.TabPane>
				</Tabs>
			</Card>
		</FullLayout>
	);
};

export default AdminProfile;
