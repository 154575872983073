import FullLayout from '../../layouts/layout-components/FullLayout';
import Roles from './Roles/Roles';

const AdminTeam = () => {
	return (
		<FullLayout pageTitle="Admin Team">
			<Roles />
		</FullLayout>
	);
};

export default AdminTeam;
