import Web3 from 'web3';
import { Divider, Input, message } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import baseuri from '../../methods/baseuri';
import AdvancedSectionLayout from '../Layouts/Advanced';
import { supportCurrencyEnumInfo } from '../../methods/utils';

const FundingRequest = () => {
	const [data, setData] = useState<Array<any>>();

	const searchRequest = async (searchParam: any) => {
		let possibleParams: any = {
			email: undefined,
			transactionHash: undefined,
			receivingAddress: undefined,
		};

		// is eth address
		if (Web3.utils.isAddress(searchParam)) {
			possibleParams = { ...possibleParams, receivingAddress: searchParam };
		}

		// is email
		if (
			String(searchParam)
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		) {
			possibleParams = { ...possibleParams, email: searchParam };
		}

		// is hash
		if (
			String(searchParam)
				.toLowerCase()
				.match(/^0x([A-Fa-f0-9]{64})$/)
		) {
			possibleParams = { ...possibleParams, transactionHash: searchParam };
		}

		const search: any = {};
		const dataKeys = Object.keys(possibleParams);
		for (let i = 0; i < dataKeys.length; i += 1) {
			const params = possibleParams[dataKeys[i]];
			if (params !== undefined && String(params).length > 0) {
				search[dataKeys[i]] = params;
			}
		}

		if (Object.keys(search).length === 0) return;

		try {
			const res: any = await baseuri.get('/admin/funding-request', {
				params: { ...search },
			});
			setData(res.data.data);
			message.success('Data received');
		} catch (e) {
			console.error(e);
			message.error('Failed to get result');
		}
	};

	return (
		<AdvancedSectionLayout noMetamask title="Search Funding Request">
			<>
				<p>
					Search for a funding request with transaction hash, email or receiving
					address
				</p>
				<br />
				<Input.Search
					size="large"
					placeholder="Email / Address / Hash"
					enterButton="Search"
					onSearch={searchRequest}
				/>

				<Divider />

				{data &&
					data.map((info: any) => (
						<div key={info._id}>
							<div style={{ display: 'inline-block', marginRight: 30 }}>
								<p>Email</p>
								<p>
									<strong>{info.userEmail}</strong>
								</p>
							</div>
							<div style={{ display: 'inline-block', marginRight: 30 }}>
								<p>Received</p>
								<p>
									<strong>
										{info.amountReceived}{' '}
										{supportCurrencyEnumInfo(
											Number(info.currency)
										).currency?.toUpperCase()}
									</strong>
								</p>
							</div>
							<div style={{ display: 'inline-block' }}>
								<p>Receiving Address</p>
								<p>
									<strong>{info.receivingAddress}</strong>
								</p>
							</div>
							<Divider />
							<div>
								<p>Hash</p>
								<p>{info.transactionHash}</p>
							</div>
							<Divider />
							<p>Created: {moment(info.createdAt).format('lll')}</p>
							<p>Updated: {moment(info.updatedAt).format('lll')}</p>
						</div>
					))}
			</>
		</AdvancedSectionLayout>
	);
};

export default FundingRequest;
