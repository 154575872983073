import Web3 from 'web3';
// import { ConnectorNames } from './types';
import { connectorsByName } from './web3React';
import { nodes } from './node-url';
import _const from '../redux-types';
import removeAddress from './remove-address';
import { connectorLocalStorageKey } from './config';
import reduxStore from '../store';

export const DisconnectFromWallet = async () => {
	try {
		const connector: any = localStorage.getItem('connection');
		const { connectorID } = JSON.parse(connector);

		if (connectorID === 'walletconnect') {
			localStorage.removeItem(connectorID);
		}

		removeAddress();

		window.sessionStorage.removeItem(connectorLocalStorageKey);
		window.localStorage.removeItem('connection');

		const DashboardReducerAction: any = await reduxStore();
		DashboardReducerAction.dispatch({
			type: _const.PRISTINE,
		});

		window.location.reload();
	} catch (error) {
		console.error(error);
	}
};

async function switchOrAddNetworkToMetamask(chainId: number) {
	const hexChainId = `0x${chainId.toString(16)}`;

	try {
		// switch to the selected network
		await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: hexChainId }],
		});
	} catch (e: any) {
		if (e.code === 4902) {
			let params: any = {};

			// add bsc network
			if (chainId === 56) {
				params = {
					chainId: hexChainId,
					chainName: 'Binance Smart Chain Mainnet',
					nativeCurrency: {
						name: 'BNB',
						symbol: 'bnb',
						decimals: 18,
					},
					rpcUrls: nodes,
					blockExplorerUrls: ['https://bscscan.com/'],
				};
			}

			// add polygon
			if (chainId === 137) {
				params = {
					chainId: hexChainId,
					chainName: 'Polygon Mainnet',
					nativeCurrency: {
						name: 'MATIC',
						symbol: 'matic',
						decimals: 18,
					},
					rpcUrls: ['https://polygon-rpc.com'],
					blockExplorerUrls: ['https://polygonscan.com/'],
				};
			}

			try {
				// the network is added
				await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [params],
				});
			} catch (error) {
				console.error(error);
				// notify('error', 'Failed to add network to metamask')
			}
		}
	}
}

// Original
export const Login = (
	connectorID: any,
	chainId: number,
	walletName: string
) => {
	return async (dispatch: Function) => {
		try {
			let account: any = null;

			const connector: any = connectorsByName(connectorID, chainId);

			const dt = { chainId, connectorID, walletName };

			if (connector) {
				if (connectorID === 'injected') {
					await switchOrAddNetworkToMetamask(chainId);

					const connection = await connector.activate();

					connection.provider.on('accountsChanged', (code: any) => {
						const accountSwitch = code[0];
						if (accountSwitch) {
							if (accountSwitch) {
								dispatch({
									type: _const.ADDRESS,
									payload: { address: accountSwitch },
								});
							}
						} else {
							DisconnectFromWallet();
						}
					});

					account = connection.account;

					window.APPWEB3 = new Web3(Web3.givenProvider);
				}

				if (connectorID === 'walletconnect') {
					const [theAccount] = await connector.enable();
					account = theAccount;
					// very important 2 lines
					// eslint-disable-next-line no-proto
					delete connector.__proto__.request;
					// eslint-disable-next-line no-unused-expressions
					Object.prototype.hasOwnProperty.call(connector, 'request') &&
						delete connector.request;

					window.APPWEB3 = new Web3(connector as any);
				}

				localStorage.setItem('connection', JSON.stringify(dt));
				dispatch({
					type: _const.ADDRESS,
					payload: { walletInUse: walletName, chainId },
				});

				if (account) {
					dispatch({
						type: _const.NETWORK_CONNECT,
						payload: { ChainId: chainId },
					});

					// dispatch(getAllBalances(String(account), chainId));
					dispatch({
						type: _const.ADDRESS,
						payload: { address: account },
					});
				}
			} else {
				console.warn("Can't find connector \n The connector config is wrong");
			}
		} catch (error) {
			console.error(error);
		}
	};
};

export const recreateWeb3 = () => {
	return async (dispatch: Function) => {
		try {
			const dt = localStorage.getItem('connection');
			if (!dt) return;

			const connectionDetails = JSON.parse(dt);

			if (connectionDetails) {
				let account: any = null;

				const { walletName, chainId } = connectionDetails;

				dispatch({
					type: _const.ADDRESS,
					payload: { address: null, walletInUse: walletName, chainId },
				});

				const connector: any = connectorsByName(
					connectionDetails.connectorID,
					connectionDetails.chainId
				);

				if (connector) {
					if (connectionDetails.connectorID === 'injected') {
						await switchOrAddNetworkToMetamask(connectionDetails.chainId);

						const connection = await connector.activate();

						connection.provider.on('accountsChanged', (code: any) => {
							const accountSwitch = code[0];
							if (accountSwitch) {
								if (accountSwitch) {
									dispatch({
										type: _const.ADDRESS,
										payload: { address: accountSwitch },
									});
								}
							} else {
								DisconnectFromWallet();
							}
						});

						account = connection.account;

						window.APPWEB3 = await new Web3(Web3.givenProvider);
					}

					if (connectionDetails.connectorID === 'walletconnect') {
						const [theAccount] = await connector.enable();
						account = theAccount;

						// very important 2 lines
						// eslint-disable-next-line no-proto
						delete connector.__proto__.request;
						// eslint-disable-next-line no-unused-expressions
						Object.prototype.hasOwnProperty.call(connector, 'request') &&
							delete connector.request;

						const provider: any = await new Web3(connector as any);

						window.APPWEB3 = provider;
					}

					if (account) {
						// dispatch(getAllBalances(String(account), chainId));
						dispatch({
							type: _const.ADDRESS,
							payload: { address: account },
						});
					}
				} else {
					console.warn("Can't find connector \n The connector config is wrong");
				}
			} else {
				// console.log('Storage Data Not There Yet Show Modal');
				dispatch({
					type: _const.ADDRESS,
					payload: { chainId: 56, lender: 'xVault' },
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
};
