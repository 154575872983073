import { Button, Pagination } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';
import AddBlacklistedDevice from './AddBlacklistedDevice';

const Style = styled.div`
	display: flex;
	padding: 20px 0;
	border-bottom: 1px solid #ddd;
	justify-content: space-between;
`;

const BlacklistedDevices = () => {
	const [state, setState] = useState({
		records: [],
		totalRecords: 0,
		currentPage: 1,
	});

	const getData = async (page: any) => {
		try {
			const res: any = await baseuri.get('/advanced/blacklisted-device', {
				params: { page },
			});
			setState({ ...state, ...res.data?.data });
		} catch (e: any) {
			console.error(e);
		}
	};

	const deListDevice = (item: any) => {
		try {
			baseuri.put('/advanced/blacklisted-device', {
				blacklistedRecordId: item._id,
				blacklisted: !item.blacklisted,
			});
		} catch (e: any) {
			console.error(e);
		}
	};

	useEffect(() => {
		getData(1);
	}, []);

	return (
		<AdvancedSectionLayout>
			<h2>Blacklisted Devices</h2>
			<AddBlacklistedDevice />

			<div>
				{state.records?.map((item: any) => (
					<Style key={item._id}>
						<p>{item.blacklisted ? 'blacklisted' : 'de-listed'}</p>
						<p>@{item.userId?.username}</p>
						<div>
							<p>{item.deviceId}</p>
							<p>Device id</p>
						</div>
						<div>
							<p>{moment(item.createdAt).format('lll')}</p>
							<p>Created At</p>
						</div>
						<div>
							<Button onClick={() => deListDevice(item)}>De-list Device</Button>
						</div>
					</Style>
				))}

				<br />

				<Pagination
					current={state.currentPage}
					total={state.totalRecords}
					onChange={(page) => getData(page)}
					pageSize={10}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default BlacklistedDevices;
