import { FC } from 'react';
import { Layout } from 'antd';

interface IHeaderComponent {
	pageTitle: string;
}

const HeaderComponent: FC<IHeaderComponent> = ({ pageTitle }) => {
	const { Header } = Layout;
	// const [userInfo, setUserInfo] = useState({} as any);

	// const menu = (
	// 	<List
	// 		min-width="100%"
	// 		className="header-notifications-dropdown"
	// 		itemLayout="horizontal"
	// 		dataSource={data}
	// 		renderItem={(item) => (
	// 			<></>
	// 			// <List.Item>
	// 			//   <List.Item.Meta
	// 			//     avatar={<Avatar shape="square" src={item.avatar} />}
	// 			//     title={item.title}
	// 			//     description={item.description}
	// 			//   />
	// 			// </List.Item>
	// 		)}
	// 	/>
	// );
	// const menu2 = (
	// 	<Menu>
	// 		<Menu.Item>
	// 			<div className="dropdownBanner">
	// 				<Avatar size="large" icon={<UserOutlined />} />
	// 				<div>
	// 					<h3 className="textAvatar">{userInfo?.name}</h3>
	// 					<h5 className="textAvatar">{userInfo?.email}</h5>
	// 				</div>
	// 			</div>
	// 		</Menu.Item>
	// 		<Menu.Item icon={<UserOutlined />}>View Profile</Menu.Item>
	// 		<Menu.Item icon={<RetweetOutlined />}>
	// 			<Link to="/update-password">
	// 				<span>Update Password</span>
	// 			</Link>
	// 		</Menu.Item>
	// 		<Menu.Item icon={<PoweroffOutlined />}>
	// 			<span onClick={logout}>Logout </span>{' '}
	// 		</Menu.Item>
	// 	</Menu>
	// );

	return (
		<Header
			className=""
			style={{
				position: 'fixed',
				zIndex: 1,
				width: '100%',
				backgroundColor: '#ffffff',
			}}
		>
			<div>
				<p>{pageTitle}</p>
			</div>
			{/* <div style={{marginRight: "15%" }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Badge size="small" >
              <BellOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Badge>
          </Dropdown>
          <Dropdown overlay={menu2} trigger={["click"]}>
            <Avatar
              icon={<UserOutlined />}
              style={{ fontSize: "20px", color: "#08c", marginLeft: "10px" }}
            />
          </Dropdown>
        </div> */}
		</Header>
	);
};

export default HeaderComponent;
