import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface ILockSaving {
	id: any;
}

const LockFlexible: FC<ILockSaving> = ({ id }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		flexibleSavingsId: id,
		lockedAmount: 0,
		lockedComment: '',
		lockStatus: 'lock',
	});
	const update = async () => {
		setloading(true);
		try {
			const res: any = await baseuri.put('admin/lock-flexible-savings', data);

			if (res.status === 200) {
				message.success('updated successfully');
				setloading(false);
				setVisible(false);
			} else {
				message.info(res.data.message);
				setloading(false);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Lock Flexible
			</Button>
			<Modal
				width="350px"
				title="Lock this savings"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={update}>
						<Button loading={loading} type="primary">
							Lock
						</Button>
					</Popconfirm>,
				]}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<p>
					Lock Amount
					<br />
					<InputNumber
						defaultValue={data?.lockedAmount}
						onChange={(e) => setData({ ...data, lockedAmount: e })}
					/>
				</p>
				<br />
				<p>
					Comment :{' '}
					<TextArea
						value={data?.lockedComment}
						onChange={(e: any) =>
							setData({
								...data,
								lockedComment: e.target.value,
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default LockFlexible;
