import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IRecurrentDrawer {
	cronDetails: any[];
	handleSwitch: Function;
}

const RecurrentDrawer: FC<IRecurrentDrawer> = ({
	cronDetails,
	handleSwitch,
}) => {
	const [visible, setVisible] = useState(false);
	const [selectedTime, setSelectedTime] = useState(cronDetails[1]?.value);
	const [availableTime, SetAvailableTime] = useState([] as any);

	useEffect(() => {
		baseuri
			.get(`http://127.0.0.1:3333/v1/configs/cronjob-time`)
			.then((res: any) => {
				SetAvailableTime(res?.data?.data?.systemTime);
			})
			.catch((err: any) => {
				console.error(err);
			});
	}, []);
	const showModal = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const handleRecurrentUpdate = () => {
		handleSwitch(selectedTime, cronDetails[1]?.id);
		setVisible(false);
	};
	return (
		<>
			<strong>{cronDetails[1]?.name}</strong>{' '}
			<span>
				{cronDetails[1]?.value}{' '}
				<div className="edithIcon" role="none" onClick={showModal}>
					<EditOutlined />
				</div>
			</span>
			<Modal
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleRecurrentUpdate}>
						<Button type="primary">Update</Button>
					</Popconfirm>,
				]}
				onCancel={onClose}
			>
				<Divider />

				<p>Select Recurrent time interval</p>
				<Select
					size="large"
					defaultValue={cronDetails[1]?.value}
					style={{ width: '100%', marginTop: 5 }}
					placeholder="Please Select"
					onChange={(e: any) => setSelectedTime(e)}
				>
					{availableTime?.map((time: any) => (
						<Select.Option value={time} key={time}>
							{time}
						</Select.Option>
					))}
					{/* <Select.Option value="super">Super Admin</Select.Option> */}
				</Select>
			</Modal>
		</>
	);
};

export default RecurrentDrawer;
