import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IUpdateQueueItemStatus {
	whichQueue: 'deposit' | 'withdraw';
	queueItemId: any;
	removeItem: any;
}
const UpdateQueueItemStatus = ({
	queueItemId,
	whichQueue,
	removeItem,
}: IUpdateQueueItemStatus) => {
	const [visible, setVisible] = useState(false);

	const changeStatus = (status: any) => {
		const url =
			whichQueue === 'withdraw'
				? 'update-withdraw-queue-item'
				: 'update-deposit-queue-item';
		baseuri
			.put(`/admin/${url}`, { queueItemId, status })
			.then(() => {
				message.success('done');
				removeItem(status);
				setVisible(false);
			})
			.catch((e: any) => console.error(e));
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Change Status</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Update Status"
			>
				<Button onClick={() => changeStatus('pending')}>Set to Pending</Button>
				<Button onClick={() => changeStatus('processing')}>
					Set to Processing
				</Button>
				<Button onClick={() => changeStatus('cancelled')}>
					Set to Cancelled
				</Button>
				<Button onClick={() => changeStatus('done')}>Set to Success</Button>
			</Modal>
		</>
	);
};

export default UpdateQueueItemStatus;
