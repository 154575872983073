import { message, Pagination, Select, Space, Table } from 'antd';
import moment from 'moment';
import SelectItem from 'react-select';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import baseuri from '../../../methods/baseuri';
import { commas, Country, countryCode } from '../../../methods/utils';

const UsersByCountry = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState({
		totalRecords: 0,
		pageSize: 10,
		currentPage: 1,
		totalPage: 0,
		records: [],
	});
	const [params, setParams] = useState({
		countryType: 'zone',
		countryCode: 'ng',
	});

	const handleSearchByDate = async (page = 1) => {
		setLoading(true);
		setUsers({
			totalRecords: 0,
			pageSize: 10,
			currentPage: 1,
			totalPage: 0,
			records: [],
		});
		try {
			const res: any = await baseuri.get('/admin/users', {
				params: {
					...params,
					page,
					pageSize: 10,
				},
			});

			if (res.status === 200) {
				setUsers({ ...users, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
			render: (text: any) => <h3>{text}</h3>,
		},
		{
			title: 'Number of Referrals',
			dataIndex: 'referralCount',
			key: 'referralCount',
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			// key: '_id',
			render: (user: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${user._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];
	const countryTypeEnum = [
		{ name: 'Time Zone', value: 'zone' },
		{ name: 'IP Location', value: 'ip' },
		{ name: 'Residence', value: 'residence' },
	];

	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, [params]);
	const options = countryCode.map((option) => {
		return { value: option.code, label: option.name };
	});
	return (
		<div>
			<Space size="middle">
				{' '}
				<p>
					Country Type
					<Select
						size="large"
						style={{ width: '100%', marginTop: 5 }}
						value={params.countryType}
						placeholder="Please Select"
						onChange={(e: any) => setParams({ ...params, countryType: e })}
					>
						{countryTypeEnum?.map((type: any) => (
							<Select.Option value={type?.value} key={type?.value}>
								{type?.name}
							</Select.Option>
						))}
					</Select>
				</p>
				<p>
					Select Country
					{/* <Select
						size="large"
						style={{ width: '100%', marginTop: 5 }}
						value={Country(params.countryCode.toLocaleUpperCase())}
						placeholder="Please Select"
						onChange={(e: any) =>
							setParams({ ...params, countryCode: e.toLocaleLowerCase() })
						}
					>
						{countryCode?.map((country: any) => (
							<Select.Option value={country?.code} key={country?.code}>
								{country?.name}
							</Select.Option>
						))}
					</Select> */}
					<SelectItem
						options={options}
						value={Country(params.countryCode.toLocaleUpperCase())}
						placeholder={Country(params.countryCode.toLocaleUpperCase())}
						defaultValue={{ label: 'Nigeria', value: 'NG' }}
						onChange={(e: any) =>
							setParams({
								...params,
								countryCode: e.value.toLocaleLowerCase(),
							})
						}
					/>
				</p>
			</Space>

			<p>
				<br />
				<strong>{commas(users.totalRecords, 2)} records</strong>
			</p>
			{/* <p>
              <CSVComponent url={currentUrl} />
            </p> */}
			<Table
				loading={loading}
				columns={columns}
				dataSource={users.records}
				pagination={false}
			/>
			<br />
			<Pagination
				onChange={handleSearchByDate}
				disabled={loading}
				pageSize={10}
				current={users.currentPage}
				total={users.totalRecords}
				showSizeChanger={false}
			/>
		</div>
	);
};

export default UsersByCountry;
