export const ConnectorNames = {
	Injected: 'injected',
	WalletConnect: 'walletconnect',
	BSC: 'bsc',
	MathWallet: 'mathwallet',
};

// // used For Contract Creator
// export enum WalletConnectorNames {
// 	InjectedWallet = 'metamask',
// 	BinanceWallet = 'bscwallet',
// 	WalletConnect = 'walletConnect',
// }

export declare type Login = () => void;
export interface Config {
	title: string;
	icon: string;
	image: any;
	connectorId: any;
}

export interface ConfigVaults {
	code: string;
	image: any;
}
