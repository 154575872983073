import { Button, Image, message, Modal, Popconfirm, Space } from 'antd';
import { FC, useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

interface IAddAgentImage {
	userId: string;
}

const ImageMain = styled.div`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const videoConstraints = {
	// aspectRatio: 0.5625,
	width: 200,
	height: 200,
	facingMode: 'user',
};

const UploadSection = styled.div``;
const AddAgentImage: FC<IAddAgentImage> = ({ userId }) => {
	const [uploadLoading, setUploadLoading] = useState(false);
	const [loadingWebcam, setLoadingWebcam] = useState<boolean>(true);
	const [showModal, setShowModal] = useState(false);
	const [shopImage, setSetImage] = useState({
		loading: false,
		imageUrl: '',
		imageFile: '' as any,
	});

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('imageUrl', shopImage?.imageFile);

		try {
			setUploadLoading(true);
			const res: any = await baseuri.put(
				`/admin/update-shopagent-selfie?id=${userId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			if (res.status === 200) {
				setUploadLoading(false);
				setSetImage({
					imageUrl: '',
					loading: false,
					imageFile: '',
				});
				message.success('Image Uploaded Successfully');
			} else {
				setUploadLoading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setUploadLoading(false);
			message.error(e.response.data.data.message);
		}
	};

	const webcamRef = useRef<Webcam>(null);

	const capture = useCallback(async () => {
		const imageSrc: any = await webcamRef?.current?.getScreenshot();
		const blob = await fetch(imageSrc).then((res) => res.blob());

		setSetImage({
			imageUrl: imageSrc || '',
			loading: false,
			imageFile: blob,
		});
	}, [webcamRef]);

	const handleUserMedia = () => {
		setLoadingWebcam(true);
	};

	return (
		<>
			<Button type="primary" onClick={() => setShowModal(true)}>
				UPDATE
			</Button>
			<Modal
				title="Capture Agent Image"
				visible={showModal}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpload}>
						<Button
							loading={uploadLoading}
							type="primary"
							disabled={!shopImage?.imageUrl}
						>
							Update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setShowModal(false)}
			>
				<UploadSection>
					<ImageMain>
						{!shopImage?.imageUrl ? (
							<p>
								{loadingWebcam ? (
									<Webcam
										audio={false}
										// height={720}
										ref={webcamRef}
										mirrored
										screenshotFormat="image/jpeg"
										// width={1280}
										videoConstraints={videoConstraints}
										onUserMedia={handleUserMedia}
										// style={{loadingWebcam ? 'none' : 'block'}}
									/>
								) : (
									<p>Loading WebCam</p>
								)}
							</p>
						) : (
							<Image src={shopImage?.imageUrl} />
						)}
					</ImageMain>
					<Space>
						<Button
							type="primary"
							disabled={!shopImage?.imageUrl}
							onClick={() => setSetImage({ ...shopImage, imageUrl: '' })}
						>
							Clear
						</Button>
						<Button
							type="primary"
							disabled={!!shopImage.imageUrl}
							onClick={capture}
						>
							Capture photo
						</Button>
					</Space>
				</UploadSection>
			</Modal>
		</>
	);
};

export default AddAgentImage;
