import { message } from 'antd';
import baseuri from './baseuri';
import reduxTypes from './redux-types';

export const getDepositQueueItems = () => {
	return async (dispatch: Function) => {
		try {
			const res: any = await baseuri.get('/admin/deposit-queue', {
				params: { status: 'pending' },
			});
			dispatch({
				type: reduxTypes.DEPOSIT_QUEUES_ITEMS,
				payload: {
					deposits: res.data?.data,
				},
			});
		} catch (e) {
			console.error(e);
		}
	};
};

export const cancelQueueItem = () => {
	return async () => {
		try {
			await baseuri.put('/admin/cancel-deposit-queue-item', {});
			message.success('Done');
			// dispatch({})
		} catch (e) {
			console.error(e);
		}
	};
};
