import { Button, Modal, Popconfirm } from 'antd';
import { useState } from 'react';

const CustodianQueueProcessing = () => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<Button type="dashed" onClick={() => setVisible(true)} size="small">
				Queue Manager
			</Button>

			<Modal
				title="Update Custodian Queue Processing"
				visible={visible}
				footer={null}
				onCancel={() => setVisible(false)}
			>
				<p>
					This settings determines if the Custodian Wallet should process items
					on the Deposit or Withdrawal Queue.
				</p>
				<br />
				<Popconfirm title="Are you sure?">
					<Button type="primary">Change State</Button>
				</Popconfirm>
			</Modal>
		</>
	);
};

export default CustodianQueueProcessing;
