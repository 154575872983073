import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Space,
} from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EditorComponent from './EditorComponent';
// import PreviewNewsLetter from './PreviewNewsLetter';
import SelectMailList from './SelectMailList';
import SendTestNewsLetter from './SendTestNewsLetter';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
const CreateNewsLetter = () => {
	const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [newsletter, setNewsLetter] = useState({
		subject: '',
		bannerUrl: '',
		previewMessage: '',
		maillist: '',
		access_code: '3345constable',
		deliveryType: 'instant',
		creatorEmail: user?.email,
		content: '',
	});

	// const instantProcessing = [
	// 	{
	// 		name: 'YES',
	// 		value: true,
	// 	},
	// 	{
	// 		name: 'No',
	// 		value: false,
	// 	},
	// ];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await axios.post(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters/draft`,
				newsletter
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('NewsLetter Saved Successfully');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<div>
			<Button
				type="primary"
				shape="round"
				size="middle"
				ghost
				onClick={() => setVisible(true)}
			>
				Create NewsLetter
			</Button>
			<Modal
				title="Create Newsletter"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Save
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={newsletter?.access_code}
							onChange={(e: any) =>
								setNewsLetter({ ...newsletter, access_code: e.target.value })
							}
						/>
					</p>
					<p>
						Subject :{' '}
						<Input
							placeholder="input Title"
							value={newsletter?.subject}
							onChange={(e: any) =>
								setNewsLetter({ ...newsletter, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={newsletter?.previewMessage}
							onChange={(e: any) =>
								setNewsLetter({ ...newsletter, previewMessage: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={newsletter?.bannerUrl}
							onChange={(e: any) =>
								setNewsLetter({ ...newsletter, bannerUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://imgbb.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://imgbb.com
						</a>{' '}
						to Upload/generate banner url
					</p>
					{/* <p>
						<p>Delivery Type: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={newsletter.instantProcessing}
							onChange={(e) => {
								setNewsLetter({ ...newsletter, instantProcessing: e });
							}}
						>
							{instantProcessing?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p> */}
					<p>
						<p>Select MailList: </p>
						<SelectMailList
							newsletter={newsletter}
							setNewsLetter={setNewsLetter}
						/>
					</p>
					<Divider />
					<strong>Hi {`${'{{Name}}'}`}</strong>
					<p>
						<EditorComponent
							newsletter={newsletter}
							setNewsLetter={setNewsLetter}
						/>
					</p>
					<Space direction="horizontal">
						{/* <PreviewNewsLetter /> */}
						<SendTestNewsLetter newsletter={newsletter} />
					</Space>
					<strong>List Of Test Emails</strong>
					<p>cabafor@gmail.com</p>
					<p> mercyemmanuelotomowo@gmail.com</p>
					<p>ugochukwuaronu@gmail.com</p> <p>emekaece@gmail.com</p>{' '}
					<p>ifihear@gmail.com</p>
					<p>olowogbayigbenga@gmail.com</p> <p>ekwealorobinna112@gmail.com</p>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default CreateNewsLetter;
