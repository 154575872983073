import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import FullLayout from '../../../layouts/layout-components/FullLayout';

const Maintenance = () => {
	return (
		<FullLayout pageTitle="503">
			<Result
				status="500"
				title="503"
				subTitle="Server Maintenance Ongoing"
				extra={
					<Button type="primary">
						<Link to="/">Back Home</Link>
					</Button>
				}
			/>
		</FullLayout>
	);
};

export default Maintenance;
