import { Button, Divider, Modal, Table } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import {
//   enum2PlanSavingStatus,
//   enum2PlanWithdrawalStatus,
// } from "../../methods/transactions";
import { getRequests } from '../../methods/users';
import { getCurrentValue } from '../../methods/utils';
import LockFixed from '../views/LockedSavings/LockFixed';
import LockFlexible from '../views/LockedSavings/LockFlexible';

interface IPlanDetails {
	details: any;
	planType: 'flexible' | 'fixed';
}

const PlanDetailsModal: FC<IPlanDetails> = ({ details, planType }) => {
	const { authRole } = useSelector((store: any) => store.auth);
	const { sharePrices } = useSelector((store: any) => store.general);

	const [planVisible, setPlanVisible] = useState(false);

	const [plan, setPlan] = useState<any>({ _id: null });
	const { _id } = plan;

	useEffect(() => {
		if (!details) return;

		setPlan(details);
	}, [details, planType]);

	const handlePlanVisibility = () => {
		setPlanVisible(!planVisible);
	};

	return (
		<>
			<Button onClick={() => handlePlanVisibility()}>More</Button>

			<Modal
				footer={null}
				title={plan.planName}
				visible={planVisible}
				onCancel={() => setPlanVisible(false)}
			>
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<div>
						<p>Current Value</p>
						<p>
							{getCurrentValue(
								details?.shareBalance,
								plan.protocolName,
								sharePrices
							)}{' '}
							BUSD
						</p>
					</div>
					<div>
						<p>Share Balance</p>
						<p>{details?.shareBalance}</p>
					</div>
					<div>
						<p>Protocol</p>
						<p>{plan.protocolName}</p>
					</div>
				</div>
				<Divider />
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<div>
						<p>Start Time</p>
						<p>
							{moment
								.unix(details?.depositTime)
								.format('MMM DD, YYYY h:mm:ss a')}
						</p>
					</div>
					<div>
						<p>End Date</p>
						<p>
							{moment.unix(plan?.lockPeriod).format('MMM DD, YYYY h:mm:ss a')}
						</p>
					</div>
					<div>
						<p>Withdrawal</p>
						<p>
							{Number(plan.withdrawalTime) > 0 &&
								moment.unix(plan.withdrawalTime).format('lll')}
						</p>
					</div>
				</div>
				<Divider />
				<div>
					<p>First Deposit Amount:</p>
					<p>{details?.depositAmount} BUSD</p>
				</div>
				<Divider />
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<div>
						<p>Deposit Hash</p>
						<div>
							{details?.depositTransactionHash.map((hash: string) => (
								<a
									style={{ display: 'block' }}
									href={hash}
									target="_blank"
									rel="noreferrer"
								>
									Verify hash
								</a>
							))}
						</div>
					</div>
					<div>
						<p>Withdrawals</p>
						<div>
							{details?.withdrawalTransactionHash.map((hash: any) => (
								<a
									style={{ display: 'block' }}
									key={hash}
									href={hash}
									target="_blank"
									rel="noreferrer"
								>
									Verify hash
								</a>
							))}
						</div>
					</div>
				</div>
				<Divider />
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<div>
						<p>Plan State</p>
						<p>{details?.planState}</p>
					</div>
					<div>
						<p>Save Deposit Status</p>
						{/* <p>{enum2PlanSavingStatus(prop?.details?.saveStatus)}</p> */}
					</div>
				</div>
				<Divider />

				{authRole && authRole.name === 'Super Admin' && (
					<div>
						<Link to={`/savings-plan/${planType}/${_id}`}>Open Plan</Link>
					</div>
				)}
			</Modal>
		</>
	);
};

interface IUserPlans {
	userId: string;
}
const UserPlans: FC<IUserPlans> = ({ userId }) => {
	const { sharePrices } = useSelector((store: any) => store.general);

	const [plans, setUserPlans] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getRequests(`admin/user-plans?userId=${userId}`).then((user: any) => {
			setUserPlans(user?.data);

			setLoading(false);
		});
	}, []);

	const column = [
		{
			title: 'Plan Name',
			dataIndex: 'planName',
		},
		{
			title: 'Current Value',
			dataIndex: 'shareBalance',
			render: (share: any, data: any) =>
				getCurrentValue(share, data.protocolName, sharePrices),
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('MMM DD, YYYY h:mm:ss a'),
		},
		{
			title: 'End Date',
			dataIndex: 'lockPeriod',
			render: (text: any) => moment.unix(text).format('MMM DD, YYYY h:mm:ss a'),
		},
		{
			title: 'Last Updated',
			dataIndex: 'updatedAt',
			render: (text: any) => moment(text).format('MMM DD, YYYY h:mm:ss a'),
		},
		{
			title: 'Save Status',
			dataIndex: 'saveStatus',
			render: () => (
				<span>{/* <strong>{enum2PlanSavingStatus(text)}</strong> */}</span>
			),
		},
		{
			title: '',
			render: (text: any) => (
				<span>
					{text?.planName === 'FLEXI SAVE' ? (
						<LockFlexible id={text?._id} />
					) : (
						<LockFixed id={text?._id} />
					)}
				</span>
			),
		},
		{
			title: '',
			render: (text: any) => (
				<div>
					<PlanDetailsModal
						details={text}
						planType={text.lockPeriod ? 'fixed' : 'flexible'}
					/>
				</div>
			),
		},
	];

	return (
		<div>
			<Table
				loading={loading}
				columns={column}
				dataSource={plans}
				pagination={false}
			/>
		</div>
	);
};

export default UserPlans;
