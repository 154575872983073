import { Button, message, Result, Switch } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';
import RecurrentDrawer from './RecurrentDrawer';

const MainSection = styled.section`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;
`;

const ItemSection = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	width: 50%;
	align-items: flex-start;
	justify-content: space-between;
	margin: 10px;

	& .edithIcon {
		display: inline-flex;
		color: #0d0dac;
	}
`;

const BotSettings = () => {
	const [loading, setLoading] = useState(false);
	const [cronDetails, setCronDetails] = useState<Array<any>>([]);

	useEffect(() => {
		setLoading(true);
		baseuri
			.get(`http://127.0.0.1:3333/v1/configs`)
			.then((res: any) => {
				setCronDetails(res?.data?.data?.configs);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	const handleSwitch = (value: any, id: string) => {
		baseuri
			.patch(`http://127.0.0.1:3333/v1/configs/${id}`, {
				value: `${value}`,
			})
			.then(() => {
				// message.success(
				// 	`${res?.data?.data?.config.name} turned is ${res?.data?.data?.config.value === 'true' ? ' ON' : 'OFF'
				// 	}`
				// );
				setCronDetails(
					cronDetails?.map((data) => {
						if (data.id === id) {
							return {
								...data,
								value,
							};
						}
						return data;
					})
				);
			})
			.catch((err: any) => {
				console.error(err);
				message.error('something went wrong');
			});
	};
	return (
		<AdvancedSectionLayout title="Bot Configurations">
			{cronDetails[0]?.value ? (
				<MainSection>
					<ItemSection>
						<strong>{cronDetails[0]?.name}</strong>{' '}
						<Switch
							loading={loading}
							defaultChecked={cronDetails[0]?.value === 'true'}
							checkedChildren="On"
							unCheckedChildren="Off"
							onClick={(checked: boolean) =>
								handleSwitch(checked, cronDetails[0]?.id)
							}
						/>
					</ItemSection>
					<ItemSection>
						<strong>{cronDetails[2]?.name}</strong>{' '}
						<Switch
							loading={loading}
							defaultChecked={cronDetails[2]?.value === 'true'}
							checkedChildren="On"
							unCheckedChildren="Off"
							onClick={(checked: boolean) =>
								handleSwitch(checked, cronDetails[2]?.id)
							}
						/>
					</ItemSection>

					<ItemSection>
						<strong>{cronDetails[3]?.name}</strong>{' '}
						<Switch
							loading={loading}
							defaultChecked={cronDetails[3]?.value === 'true'}
							checkedChildren="On"
							unCheckedChildren="Off"
							onClick={(checked: boolean) =>
								handleSwitch(checked, cronDetails[3]?.id)
							}
						/>
					</ItemSection>

					<ItemSection>
						<strong>{cronDetails[4]?.name}</strong>{' '}
						<Switch
							loading={loading}
							defaultChecked={cronDetails[4]?.value === 'true'}
							checkedChildren="On"
							unCheckedChildren="Off"
							onClick={(checked: boolean) =>
								handleSwitch(checked, cronDetails[4]?.id)
							}
						/>
					</ItemSection>

					<ItemSection>
						<RecurrentDrawer
							cronDetails={cronDetails}
							handleSwitch={handleSwitch}
						/>
					</ItemSection>
				</MainSection>
			) : (
				<Result
					status="404"
					title="404"
					subTitle="Sorry, the page you visited does not exist. Please check your local CRON server"
					extra={<Button type="primary">Back Home</Button>}
				/>
			)}
		</AdvancedSectionLayout>
	);
};

export default BotSettings;
