import { message, Modal, Tag } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IUnlockSavings {
	details: any;
	url: string;
	text: string;
}
const UnlockSavings: FC<IUnlockSavings> = ({ details, url, text }) => {
	const removeCron = async () => {
		try {
			const res: any = await baseuri.put(url, {
				[text === 'fixed' ? 'fixedSavingsId' : 'flexibleSavingsId']:
					details?._id,
				lockedAmount: details?.lockedAmount,
				// lockedComment: 'its locked',
				lockStatus: 'unlock',
			});
			if (res.status === 200) {
				message.success('unlocked Successfully');
			} else {
				message.info('Could not complete operation');
			}
		} catch (e) {
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Unlock this Savings',
			onOk() {
				removeCron();
			},
		});
	};
	return (
		<DeleteSection>
			<Tag color="green" onClick={confirm}>
				Unlock
			</Tag>
		</DeleteSection>
	);
};

export default UnlockSavings;
