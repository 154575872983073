import { retrieveToken } from '../authenticate';
import reduxTypes from '../redux-types';

const authDetails = () => {
	return (dispatch: Function) => {
		const data = retrieveToken();

		if (data) {
			const user = {
				email: data.email,
				name: data.name,
				enable2FA: data.enable2FA,
			};

			const authRole = {
				name: data.role?.title,
				slug: data.role?.slug,
				isActive: data.role?.isActive,
			};

			dispatch({
				type: reduxTypes.AUTH_DETAILS,
				payload: { user, authRole },
			});
		}
	};
};

export default authDetails;
