import {
	Card,
	Table,
	Button,
	Modal,
	Divider,
	message,
	Tabs,
	Tag,
	Pagination,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import AddRole from './AddRole';
import AdminManagement from './AdminManagement';
import RolesTable from './RolesTable';

interface IRemoveUserWithRole {
	userId: string;
	// removed: any;
}
const RemoveUserWithRole: FC<IRemoveUserWithRole> = ({ userId }) => {
	const [loading, setloading] = useState(false);

	const removeRoleRecord = async () => {
		try {
			setloading(true);
			const res = await baseuri.delete(`admin/users/admin/${userId}`);
			if (res.status === 200) {
				setloading(false);
				message.success('Admin User Deleted Successfully');
			}
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete this Admin user?',
			onOk() {
				removeRoleRecord();
			},
		});
	};

	return (
		<Button danger size="small" loading={loading} onClick={confirm}>
			Remove
		</Button>
	);
};

const tableColumns = [
	{
		title: 'User',
		render: (text: any) => (
			<>
				<p>{text.fullName}</p>
				<p>{text.email}</p>
			</>
		),
	},
	{
		title: 'Last Login',
		dataIndex: 'lastLogin',
		render: (text: any) => (
			<>
				{moment(text).format('lll')} ({moment(text).fromNow()})
			</>
		),
	},
	{
		title: 'Roles',
		dataIndex: 'role',
		render: (text: any) => <Tag color="green">{text?.title}</Tag>,
	},
	{
		title: '',
		render: (text: any) => <AddRole userId={text._id} email={text.email} />,
	},
	//   {
	//     title: "",
	//     render: () => <Button size="small">Deactive</Button>,
	//   },
	{
		title: '',
		dataIndex: '_id',
		render: (text: any) => (
			<RemoveUserWithRole userId={text} />
			// <RemoveUserWithRole userId={text} removed={data.removed} />
		),
	},
];

const Roles = () => {
	const [loading, setLoading] = useState(false);

	const [adminUsers, setAdminUsers] = useState({
		records: [],
		current: 1,
		totalRecords: 0,
	});

	const fetchAdminUserRecords = async (page: any) => {
		try {
			setLoading(true);
			const res: any = await baseuri.get('admin/users/admin', {
				params: { page, pageSize: 20 },
			});
			if (res.status === 200) {
				setAdminUsers({ ...adminUsers, ...res.data.data });
			}
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAdminUserRecords(1);
	}, []);

	return (
		<Card>
			<AdminManagement />

			<Divider />
			<Tabs defaultActiveKey="1">
				<Tabs.TabPane tab="Admin Users" key="1">
					<Table
						pagination={false}
						loading={loading}
						columns={tableColumns}
						dataSource={adminUsers.records}
					/>

					<br />

					<Pagination
						onChange={fetchAdminUserRecords}
						disabled={loading}
						pageSize={20}
						current={adminUsers.current}
						total={adminUsers.totalRecords}
						showSizeChanger={false}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Roles" key="2">
					<RolesTable />
				</Tabs.TabPane>
			</Tabs>
		</Card>
	);
};

export default Roles;
