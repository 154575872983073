import { Button, Input, message, Modal } from 'antd';
import { FC, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import baseuri from '../../methods/baseuri';
import { saveToken } from '../../methods/authenticate';

interface IVerify2FA {
	email: string;
	password: string;
}
const Verify2FA: FC<IVerify2FA> = ({ email, password }) => {
	const [code, setCode] = useState('');

	const enterApp = async () => {
		try {
			const res: any = await baseuri.post('/admin/login', {
				code,
				email,
				password,
			});
			if (res.status === 200) {
				const auth = res.data.data;
				saveToken(auth);

				createBrowserHistory().push('/');
				createBrowserHistory().go(0);
			} else {
				message.error('2FA Verification failed.');
			}
		} catch (e) {
			message.error('Failed to authenticate user');
		}
	};

	return (
		<Modal width={320} closable={false} visible footer={null} title="2FA Code">
			<p>
				Get authentication code from your Google Authenticator mobile app and
				login.
			</p>

			<br />

			<form
				onSubmit={(e) => {
					e.preventDefault();
					enterApp();
				}}
			>
				<div>
					<Input
						value={code}
						onChange={(e) => setCode(e.target.value)}
						autoFocus
					/>
				</div>

				<br />

				<Button htmlType="submit" block type="primary">
					Proceed
				</Button>
			</form>
		</Modal>
	);
};

export default Verify2FA;
