import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import baseuri from '../../methods/baseuri';
import { commas, shortAmount } from '../../methods/utils';

const PlansBalance = () => {
	const [planDetails, setPlanDetails] = useState({} as any);

	useEffect(() => {
		baseuri
			.get(`admin/overall-plans`)
			.then((res: any) => {
				setPlanDetails(res?.data?.data);
			})
			.catch((err: any) => {
				console.error(err);
			});
	}, []);
	return (
		<>
			<p>
				<strong>Plan Details </strong>
			</p>
			<br />
			<div>
				<p>Fixed Plans</p>
				<div>
					<p>
						Active: {commas(planDetails?.total_active_fixed_plan, 2)} plans ($
						{commas(shortAmount(planDetails?.total_active_fixed_plan_to_usd))})
					</p>
					<p>Overall: {commas(planDetails?.total_all_fixed_plan, 2)} plans</p>
				</div>
			</div>
			<Divider />
			<div>
				<p>Flexible Plans</p>
				<div>
					<p>
						Active: {commas(planDetails?.total_active_flexible_plan, 2)} plans
						($
						{commas(
							shortAmount(planDetails?.total_active_flexible_plan_to_usd)
						)}
						)
					</p>
					<p>
						Overall: {commas(planDetails?.total_all_flexible_plan, 2)} plans
					</p>
				</div>
			</div>
		</>
	);
};

export default PlansBalance;
