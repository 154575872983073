/**
 * This function is used to create contracts web3 objects used for calling contract methods.
 * @param abi
 * @param contractAddress
 */
import Web3 from 'web3';

const web3Instance2 = new Web3('https://bsc-dataseed1.binance.org:443');

async function createContract2(abi: Array<any>, contractAddress: any) {
	if (web3Instance2) {
		return new web3Instance2.eth.Contract(abi, contractAddress);
	}
	throw Error("Can't create contract");
}

export default createContract2;
