import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from './theme/globalStyles';
import ConfirmSuperAdminAccount from './components/ConfirmSuperAdminAccount';
import Login from './components/Login';
import UpdatePassword from './components/UpdatePassword';
import { Authenticated } from './components/Authenticated';
import Authorize from './components/Authorize';
import FraudList from './components/Advanced/FraudList';
import WithdrawOut from './components/Advanced/WithdrawOut/WithdrawOut';
import WithdrawQueue from './components/Advanced/WithdrawQueue/Withdraw';
import DepositQueue from './components/Advanced/DepositQueue/DepositQueue';
import XendBridgeSellRequests from './components/Advanced/XendBridgeSellRequests/XendBridgeSellRequests';
import Dashboard from './components/Dashboard/Dashboard';
import FinderSearch from './components/Finder/Search';
import Financials from './components/Financials';
import AdminProfile from './components/views/AdminProfile/AdminProfile';
import SavingsPlan from './components/Advanced/Plan';
import UserProfile from './components/User/Profile';
import GrowthReports from './components/views/GrowthReports';
import FeatureReports from './components/views/FeatureReports';
import ReferralPayouts from './components/views/ReferralPayouts';
// import Transactions from './components/views/Transactions/Transactions';
import AllUsers from './components/views/AllUsers/AllUsers';
import Internals from './components/Internals';
import WalletBalanceSearch from './components/Advanced/WalletBalanceSearch';
import ViewRewardRecord from './components/Advanced/RewardRecord';
import getSharePrices from './methods/actions/share-prices';
import authDetails from './methods/actions/auth-details';
import Enable2FA from './components/Login/Enable2FA';
import AdminTeam from './components/AdminTeam';
import BotSettings from './components/Advanced/BotSettings';
import XforceAgents from './components/views/XforceAgents';
import { coordTokenName } from './methods/baseuri';
import UserAdvancedSearch from './components/Advanced/UserAdvancedSearch';
import LiquidityProviders from './components/Advanced/LiquidityProviders';
import RefReport from './components/views/RefReport';
import Accounts from './components/views/Accounts/index';
// import Transactions2 from './components/views/Transactions/Transactions2';
import Transaction3 from './components/views/Transactions/Transaction3';
import Greetings from './components/views/Greeting';
import NewsLetter from './components/views/NewsLetter';
import FundingRequest from './components/Advanced/FundingRequest';
import PlanAboutToMature from './components/Advanced/PlansToMature';
import WalletBalanceSearchGeneral from './components/Advanced/WalletBalanceSearch/general';
import UsersKYC from './components/views/UsersKYC';
import InternalSettings from './components/Advanced/InternalSettings/InternalSettings';
import Maintenance from './components/views/ErrorPages/Maintenance';
import BlacklistedDevices from './components/Advanced/BlacklistedDevices';
import KYCHistory from './components/views/UsersKYC/KYCHistory';
import KYCSuspect from './components/views/UsersKYC/KYCSuspect';
import DoubleFunding from './components/views/Transactions/DoubleFunding';
import LockedFixedSavings from './components/views/LockedSavings/LockedFixedSavings';
import LockedFlexibleSavings from './components/views/LockedSavings/LockedFlexibleSavings';

const App = () => {
	const dispatch = useDispatch();
	const { authRole } = useSelector((store: any) => store.auth);

	const accessAuth =
		(authRole?.name === 'Super Admin' && true) ||
		(authRole?.name === 'Compliance' && true);
	useEffect(() => {
		dispatch(getSharePrices());
		dispatch(authDetails());
	}, []);

	const getLocation = () => {
		//
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				localStorage.setItem(
					coordTokenName,
					JSON.stringify({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					})
				);
			});
		} else {
			localStorage.setItem(
				coordTokenName,
				JSON.stringify({
					latitude: null,
					longitude: null,
				})
			);
		}
	};

	useEffect(() => {
		getLocation();
	});

	return (
		<BrowserRouter>
			<GlobalStyles />

			<Switch>
				<Route exact path="/enable-2fa" component={Authorize(Enable2FA)} />
				<Route exact path="/login" component={Authenticated(Login)} />
				<Route exact path="/" component={Authorize(Dashboard)} />
				<Route exact path="/update-password" component={UpdatePassword} />
				<Route exact path="/503" component={Authorize(Maintenance)} />
				<Route
					exact
					path="/confirm-admin/:token"
					component={Authenticated(ConfirmSuperAdminAccount)}
				/>
				<Route exact path="/transactions" component={Authorize(Transaction3)} />
				<Route exact path="/greetings" component={Authorize(Greetings)} />
				<Route exact path="/newsletter" component={Authorize(NewsLetter)} />
				<Route exact path="/accounts" component={Authorize(Accounts)} />
				<Route exact path="/all-users" component={Authorize(AllUsers)} />
				<Route exact path="/users-kyc" component={Authorize(UsersKYC)} />
				<Route exact path="/manage-kyc/:id" component={Authorize(KYCHistory)} />
				<Route exact path="/kyc-suspects" component={Authorize(KYCSuspect)} />
				<Route
					exact
					path="/compliance/locked-fixed"
					component={Authorize(LockedFixedSavings)}
				/>
				<Route
					exact
					path="/compliance/locked-flexible"
					component={Authorize(LockedFlexibleSavings)}
				/>

				<Route exact path="/userType" component={Authorize(XforceAgents)} />
				<Route exact path="/referral-report" component={Authorize(RefReport)} />
				<Route
					exact
					path="/user-profile/:id"
					component={Authorize(UserProfile)}
				/>
				<Route
					exact
					path="/admin-profile"
					component={Authorize(AdminProfile)}
				/>

				<Route
					exact
					path="/user-search/:search"
					component={UserAdvancedSearch}
				/>
				<Route exact path="/user-search" component={UserAdvancedSearch} />

				<Route
					exact
					path="/balance-search"
					component={Authorize(WalletBalanceSearchGeneral)}
				/>

				<Route exact path="/double-funding" component={DoubleFunding} />

				{accessAuth && (
					<>
						<Route exact path="/internals" component={Authorize(Internals)} />
						<Route
							exact
							path="/payouts"
							component={Authorize(ReferralPayouts)}
						/>
						<Route
							exact
							path="/feature-report"
							component={Authorize(FeatureReports)}
						/>
						<Route
							exact
							path="/growth-report"
							component={Authorize(GrowthReports)}
						/>
						<Route
							exact
							path="/savings-plan/:type/:id"
							component={Authorize(SavingsPlan)}
						/>
						<Route exact path="/admin-team" component={Authorize(AdminTeam)} />
						<Route exact path="/financials" component={Authorize(Financials)} />
						<Route exact path="/finder" component={Authorize(FinderSearch)} />
						<Route exact path="/advanced/fraud-list" component={FraudList} />
						<Route
							exact
							path="/advanced/liquidity-providers"
							component={LiquidityProviders}
						/>
						<Route
							exact
							path="/advanced/withdraw-queue"
							component={WithdrawQueue}
						/>
						<Route
							exact
							path="/advanced/withdraw-out"
							component={WithdrawOut}
						/>
						<Route
							exact
							path="/advanced/deposit-queue"
							component={DepositQueue}
						/>
						<Route
							exact
							path="/advanced/double-funding"
							component={DoubleFunding}
						/>
						<Route
							exact
							path="/advanced/xend-bridge"
							component={XendBridgeSellRequests}
						/>
						<Route
							exact
							path="/advanced/wallet-balance-search"
							component={WalletBalanceSearch}
						/>
						<Route
							exact
							path="/advanced/view-reward-record"
							component={ViewRewardRecord}
						/>
						<Route
							exact
							path="/advanced/funding-requests"
							component={FundingRequest}
						/>
						<Route
							exact
							path="/advanced/plans-about-mature"
							component={PlanAboutToMature}
						/>
						<Route
							exact
							path="/advanced/blacklisted-devices"
							component={BlacklistedDevices}
						/>
						<Route
							exact
							path="/advanced/settings"
							component={InternalSettings}
						/>
						<Route exact path="/bot-settings" component={BotSettings} />
					</>
				)}
			</Switch>
		</BrowserRouter>
	);
};

export default App;
