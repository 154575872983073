import reduxTypes from '../redux-types';

const initialState = {
	records: [],
	totalFees: 0,
};

interface Action {
	type: string;
	payload: any;
}

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }: Action) => {
	switch (type) {
		case reduxTypes.FEES_RECORD:
			return { ...state, ...payload };

		default:
			return state;
	}
};
