import { Modal, Tag } from 'antd';
import { FC } from 'react';

interface IEachRole {
	permissions: any;
}
const EachRole: FC<IEachRole> = ({ permissions }) => {
	const viewPermissions = () => {
		Modal.confirm({
			title: 'List of accessible permissions ',
			content: (
				<>
					{permissions?.map((e: string) => (
						<p key={e}>{e}</p>
					))}
					{!permissions && null}
				</>
			),
		});
	};

	return (
		<Tag
			color="cyan"
			style={{ textTransform: 'uppercase', cursor: 'pointer' }}
			onClick={viewPermissions}
		>
			Permissions
		</Tag>
	);
};

interface IEditRoles {
	permissions: any;
}

const EditRoles: FC<IEditRoles> = ({ permissions }) => {
	return <EachRole permissions={permissions} />;
};

export default EditRoles;
