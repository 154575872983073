import {
	Button,
	Card,
	Divider,
	message,
	Modal,
	Pagination,
	Popconfirm,
	Progress,
	Select,
	Table,
	Tag,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { format } from 'timeago.js';
import baseuri from '../../../methods/baseuri';
import retrieveProcessingList from '../../../methods/processing';
import { commas } from '../../../methods/utils';
import { getAmounts } from '../../../methods/web3/amounts';
import { assets } from '../../../methods/web3/assets';
import { withdrawFromProtocol } from '../../../methods/web3/withdraw';
import AdvancedSectionLayout from '../../Layouts/Advanced';
import {
	Selection,
	CreateProcessingList,
	ProcessDetails,
	ViewProcessItems,
	QueueStatusStyle,
} from '../DepositQueue/DepositQueue';
import QueueStatuses from '../DepositQueue/QueueStatuses';
import ProcessingList from '../ProcessingList';
import UpdateQueueItemStatus from './UpdateQueueItemStatus';

interface IProcessWithdrawals {
	processListId: string;
	selected: Array<string>;
	amount: any;
	protocol: any;
}
const ProcessWithdrawals: FC<IProcessWithdrawals> = ({
	processListId,
	protocol,
	selected,
	amount,
}) => {
	const dispatch = useDispatch();

	const { address } = useSelector((store: any) => store.web3conn);

	const [visible, setVisible] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [activity, setActivity] = useState({
		activity: '',
		status: undefined,
		percent: 0,
	});

	const [success, setSuccess] = useState<any>(null);

	const sendData = (data: any) => {
		setActivity({ ...activity, ...data });
	};

	const doWithdrawal = () => {
		try {
			const planIdAndType = selected.map((x: any) => {
				return `${x.planId} ${x.planType} ${x._id}`;
			});

			dispatch(
				withdrawFromProtocol({
					planIdAndType,
					sendData,
					processListId,
					asset: assets[0],
					client: address,
					amount,
					successData: setSuccess,
					protocol,
				})
			);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<Button
				onClick={() => setVisible(true)}
				disabled={Number(amount) === 0}
				type="primary"
			>
				Process List
			</Button>

			<Modal
				footer={[
					<Button onClick={() => setVisible(false)}>Cancel</Button>,
					<Popconfirm
						title="Final Confirmation. Are you sure?"
						onConfirm={() => {
							doWithdrawal();
							setVisible(false);
							setProcessing(true);
						}}
					>
						<Button type="primary">Continue</Button>
					</Popconfirm>,
				]}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Process Withdrawal Batch"
			>
				<p>
					You are attempting to withdraw the sum of{' '}
					<strong>{commas(amount)} BUSD</strong> for{' '}
					<strong>{selected.length} withdraw queue items</strong>. Confirm that
					you want to perform this transaction before continuing. Clicking{' '}
					<strong>Continue</strong> will process this transaction. Click Cancel
					to go back.
				</p>
			</Modal>

			<Modal
				footer={null}
				title="Processing..."
				onCancel={() => {
					setProcessing(false);
					dispatch(retrieveProcessingList());
				}}
				visible={processing}
				closable
			>
				<p>Please do not close this modal</p>

				<br />

				<p>Current activity: {activity.activity}</p>
				<Progress percent={activity.percent} status={activity.status} />

				<br />
				<br />

				{success && (
					<Button
						onClick={() => {
							setProcessing(false);
							dispatch(retrieveProcessingList());
							dispatch(getAmounts(address));
						}}
					>
						Close
					</Button>
				)}
			</Modal>
		</>
	);
};

interface ICancelWithdrawRequest {
	data: any;
}
const CancelWithdrawRequest: FC<ICancelWithdrawRequest> = ({ data }) => {
	const cancelRequest = () => {
		Modal.confirm({
			title: 'Cancel Withdraw Request',
			onOk: async () => {
				try {
					const res = await baseuri.put('/admin/cancel-withdraw-queue-item', {
						queueItemId: data._id,
					});
					if (Number(res.status) === 200) {
						message.success('queue item cancelled');
					}
				} catch (e) {
					console.error(e);
				}
			},
		});
	};

	return (
		<>
			{data.status === 'pending' && (
				<Button onClick={() => cancelRequest()} danger>
					Cancel
				</Button>
			)}
			{!data.status && null}
		</>
	);
};
interface IUpdateQueueItem {
	item: any;
	updateItem: Function;
}
const DateSection = styled.div`
	display: flex;
	gap: 1em;
`;
const UpdateQueueItem: FC<IUpdateQueueItem> = ({ item, updateItem }) => {
	const showButton = () => {
		const isProcessing = item.status === 'processing';
		const processIsDone = item.processList?.status === 2;
		if (isProcessing && processIsDone) return true;
		return false;
	};

	const updateQueueItem = () => {
		Modal.confirm({
			title: 'Update queue item',
			content:
				"You are trying to update this queue item. This action will update the user's wallet balance. Continue if you are sure you know what you're doing.",
			onOk: async () => {
				try {
					const res = await baseuri.post(
						'/advanced/update-queue-after-withdrawal',
						{ queueId: item._id }
					);
					if (res.status === 200) {
						message.success('queue updated');
						updateItem(item._id);
					}
				} catch (e) {
					console.error(e);
				}
			},
		});
	};

	return (
		<>
			{!showButton() && null}
			{showButton() && (
				<Button type="primary" size="small" onClick={() => updateQueueItem()}>
					Update
				</Button>
			)}
		</>
	);
};

const WithdrawQueue = () => {
	const dispatch = useDispatch();

	const { amount, xBUSD, bnb } = useSelector((store: any) => store.web3conn);
	const { list } = useSelector((store: any) => store.processList);

	const [records, setRecord] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [queueItemStatus, setQueueItemStatus] = useState('pending');
	const [loading, setLoading] = useState(false);

	const [activeProcess, setActiveProcess] = useState<any>(null);
	const [processingAmount, setProcessingAmount] = useState(0);

	const [selectedPlans, setSelectedPlans] = useState<Array<any>>([]);

	const [updatedFired, setUpdateFired] = useState(false);

	const pageSize = 30;

	const fetchList = async (fetchPage: number) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/withdraw-queue', {
				params: { page: fetchPage, status: queueItemStatus, pageSize },
			});
			const { data } = res.data;
			setRecord(data?.records);
			setTotalRecords(data?.totalRecords);
			setPage(data?.currentPage);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	};
	const onChange = (fetchPage: any) => {
		fetchList(fetchPage);
	};

	useEffect(() => {
		fetchList(1);
		dispatch(retrieveProcessingList());
	}, []);

	useEffect(() => {
		fetchList(1);
	}, [queueItemStatus]);

	useEffect(() => {
		const pending = list.filter((x: any) => Number(x.status) !== 2);
		if (pending.length > 0) {
			setActiveProcess(pending[0]);
		}
	}, [list]);

	useEffect(() => {
		if (activeProcess) {
			setSelectedPlans(activeProcess.items);
		}
	}, [activeProcess]);

	useEffect(() => {
		const cumulative = selectedPlans.reduce((a, b) => a + b.amount, 0);
		setProcessingAmount(cumulative);
	}, [selectedPlans, records]);

	const setColor = (status: string) => {
		switch (status) {
			case 'done':
				return 'green';
			case 'pending':
				return 'orange';
			case 'failed':
				return 'red';
			case 'processing':
				return 'blue';
			default:
				return 'gray';
		}
	};

	const completeProcess = async () => {
		try {
			setUpdateFired(true);
			const res = await baseuri.post('/advanced/process-batch', {
				processListId: activeProcess._id,
			});

			if (res.status === 200) {
				dispatch(retrieveProcessingList());
			}
			setUpdateFired(false);
		} catch (e) {
			console.error(e);
			setUpdateFired(false);
		}
	};

	const addToProccessList = async (data: any) => {
		const queueId = data._id;

		try {
			const res = await baseuri.post(
				'/advanced/add-queue-item-to-process-list',
				{ queueItemId: queueId, processListId: activeProcess?._id }
			);
			if (res.status === 200) {
				// set to processing
				const editRecords = records.map((x: any) => {
					let y = x;
					if (x._id === data._id) {
						y = { ...x, status: 'processing' };
					}
					return y;
				});
				setRecord(editRecords);

				// add to list
				const newList = selectedPlans.concat([data]);
				setSelectedPlans(newList);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const setToDone = (id: string) => {
		const editRecords = records.map((x: any) => {
			let y = x;
			if (x._id === id) {
				y = { ...x, status: 'done' };
			}
			return y;
		});
		setRecord(editRecords);
	};

	const rowSelectChange = (action: 'add' | 'remove', data: any) => {
		if (action === 'add') {
			addToProccessList(data);
		}
		// if (action === 'remove') {
		// }
	};

	const updateStatus = (queueId: any, status: any) => {
		const editRecords = records.map((x: any) => {
			let y = x;
			if (x._id === queueId) {
				y = { ...x, status };
			}
			return y;
		});
		setRecord(editRecords);
	};

	const columns = [
		{
			key: 98,
			title: '',
			render: (x: any) => <Selection data={x} selectChange={rowSelectChange} />,
		},
		{
			key: 0,
			title: 'Name',
			render: (x: any) => (
				<>
					<p>
						<Link
							to={`/user-profile/${x.walletId?.userId?._id}`}
							title="View Profile"
						>
							{x.walletId?.userId?.fullName}
						</Link>
					</p>
					<p>
						<Link
							to={`/user-profile/${x.walletId?.userId?._id}`}
							title="View Profile"
						>
							@{x.walletId?.userId?.username}
						</Link>
					</p>
				</>
			),
		},
		// {
		// 	key: 1,
		// 	title: "Email",
		// 	render: (x: any) => x.walletId?.userId?.email
		// },
		{
			key: 2,
			title: 'Status',
			dataIndex: 'status',
			render: (x: any) => <Tag color={setColor(x)}>{x}</Tag>,
		},
		{
			key: 3,
			title: 'Amount',
			dataIndex: 'amount',
			render: (amnt: any) => `${amnt} BUSD`,
		},
		{
			key: 99,
			title: 'Processed By',
			dataIndex: 'processList',
			render: (data: any) => <p>{data?.creator?.email}</p>,
		},
		{
			key: 4,
			title: 'Date Updated',
			dataIndex: 'updatedAt',
			render: (x: any) => (
				<DateSection>
					{' '}
					{moment(x).format('MMM Do YY, h:mm:ss a')}{' '}
					<strong>{format(x)}</strong>
				</DateSection>
			),
		},
		{
			key: 49,
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('MMM Do YY, h:mm:ss a'),
		},
		{
			key: 9,
			title: '',
			render: (x: any) => <UpdateQueueItem item={x} updateItem={setToDone} />,
		},
		{
			key: 10,
			title: '',
			render: (x: any) => <CancelWithdrawRequest data={x} />,
		},
		{
			key: 30,
			title: '',
			render: (x: any) => (
				<UpdateQueueItemStatus
					removeItem={(status: any) => updateStatus(x._id, status)}
					queueItemId={x._id}
					whichQueue="withdraw"
				/>
			),
		},
	];

	return (
		<AdvancedSectionLayout title="Savings Plan Withdrawal">
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<ProcessingList name="Processing List" />
				</div>
				<br />

				<br />

				<Card>
					<ProcessDetails>
						<div>
							<p>Balances</p>
							<p>{commas(amount)} BUSD</p>
							<p>{commas(xBUSD)} xBUSD</p>
							<p>{commas(bnb)} BNB</p>
						</div>
						<div>
							{!activeProcess && <CreateProcessingList type="withdraw" />}
							{activeProcess && activeProcess.type === 'withdraw' && (
								<div>
									<p>
										status:{' '}
										{Number(activeProcess.status) === 0 && 'Yet to be saved'}
										{Number(activeProcess.status) === 1 && 'To be propagated'}
									</p>
									<p>
										Active Process: Created{' '}
										{moment(activeProcess.createdAt).format('lll')}
									</p>
									<p>
										Total amount in list{' '}
										<strong>{commas(processingAmount)} BUSD</strong>
									</p>
									<p>
										{activeProcess.transactionHash && (
											<a
												target="_blank"
												href={`https://bscscan.com/tx/${activeProcess.transactionHash}`}
												rel="noreferrer"
											>
												hash
											</a>
										)}
									</p>

									<br />

									{activeProcess.transactionHash && (
										<Button
											loading={updatedFired}
											disabled={updatedFired}
											onClick={() => completeProcess()}
										>
											Update Queue and Plans
										</Button>
									)}

									<br />

									<ViewProcessItems
										processStatus={activeProcess.status}
										processListId={activeProcess._id}
										items={activeProcess.items}
									/>
								</div>
							)}
						</div>
						<div>
							{Number(activeProcess?.status) === 0 &&
								activeProcess.type === 'withdraw' && (
									<ProcessWithdrawals
										protocol={activeProcess.protocol}
										processListId={activeProcess?._id}
										selected={selectedPlans}
										amount={processingAmount}
									/>
								)}
						</div>
					</ProcessDetails>
				</Card>

				<br />
				<QueueStatusStyle>
					<div>
						<p>Change Status</p>
						<Select
							style={{ minWidth: 100 }}
							defaultValue={queueItemStatus}
							onChange={(e) => setQueueItemStatus(e)}
						>
							<Select.Option value="pending">Pending Items</Select.Option>
							<Select.Option value="done">Completed Items</Select.Option>
							<Select.Option value="processing">Processing Items</Select.Option>
							<Select.Option value="cancelled">Canceled Items</Select.Option>
						</Select>
					</div>
					<QueueStatuses queue="withdraw" />
				</QueueStatusStyle>

				<Divider />
				<p>
					<strong>
						{totalRecords} items {queueItemStatus}
					</strong>
				</p>
				<br />

				<Table
					loading={loading}
					dataSource={records}
					columns={columns}
					pagination={false}
				/>
				<br />
				<Pagination
					onChange={onChange}
					current={page}
					pageSize={pageSize}
					total={totalRecords}
					showSizeChanger={false}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default WithdrawQueue;
