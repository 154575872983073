import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

interface IAddAgentID {
	userId: string;
}
const EditInfo = styled.div`
	display: inline-flex;
	margin: 2px 10px;
	color: blue;
	cursor: pointer;
`;
const AddOtherInfo: FC<IAddAgentID> = ({ userId }) => {
	const [uploadLoading, setUploadLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [agentInfo, setAgentInfo] = useState({ state: '' });

	const handleUpload = async () => {
		try {
			setUploadLoading(true);
			const res: any = await baseuri.post(
				`admin/update-location?userid=${userId}`,
				{
					storeState: agentInfo.state,
				}
			);
			if (res.status === 200) {
				setUploadLoading(false);
				setAgentInfo({ ...agentInfo, state: '' });

				message.success(res?.data?.data);
			} else {
				setUploadLoading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setUploadLoading(false);
			message.error(e.response?.data?.data?.message);
		}
	};

	return (
		<>
			<EditInfo onClick={() => setShowModal(true)}>edit info</EditInfo>
			<Modal
				title="Update Other Agent Info"
				visible={showModal}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpload}>
						<Button
							loading={uploadLoading}
							type="primary"
							disabled={!agentInfo.state}
						>
							Update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setShowModal(false)}
			>
				State:{' '}
				<Input
					placeholder="Input State"
					value={agentInfo.state}
					onChange={(e) =>
						setAgentInfo({ ...agentInfo, state: e.target.value })
					}
				/>{' '}
			</Modal>
		</>
	);
};

export default AddOtherInfo;
