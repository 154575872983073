// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import Axios from 'axios';
// import { message } from 'antd';
import { deleteToken } from './authenticate';
import { isAuth } from '../components/Authenticated';

const url = process.env.REACT_APP_BASE_URI;

const tokenName = 'xfma-token';
export const coordTokenName = 'xfma-token-coords';
let token: any = localStorage.getItem(tokenName);
token = !token || token === undefined ? null : JSON.parse(token);

let coordstoken: any = localStorage.getItem(coordTokenName);
coordstoken =
	!coordstoken || coordstoken === undefined ? null : JSON.parse(coordstoken);

const baseuri = Axios.create({
	baseURL: url,
	headers: {
		Authorization: `Bearer ${token?.token}`,
		longitude: coordstoken?.longitude,
		latitude: coordstoken?.latitude,
	},
});

baseuri.interceptors.request.use(
	(config) => {
		if (isAuth()) {
			return config;
			// const { headers } = config;
			// if (headers && headers.latitude && headers.longitude) {
			// 	return config;
			// }
			// message.error('No location data retrieved. Please reload page.');
			// throw Error('no location data');
		}
		throw Error('not authenticated');
	},
	(error) => {
		return Promise.reject(error);
	}
);

baseuri.interceptors.response.use(undefined, (err) => {
	// message.error(err?.response?.data?.message);
	if (err.response.status === 401) {
		deleteToken();
		createBrowserHistory().push('/login');
		createBrowserHistory().go(0);
	} else if (err.response.status === 503) {
		createBrowserHistory().push('/503');
	}
	return err.response?.data;
});

export default baseuri;

export const noauthBaseuri = Axios.create({
	baseURL: url,
	headers: {
		longitude: coordstoken?.longitude,
		latitude: coordstoken?.latitude,
	},
});

noauthBaseuri.interceptors.request.use(
	(config) => {
		return config;
		// const { headers } = config;
		// if (headers && headers.latitude && headers.longitude) {
		// 	return config;
		// }
		// message.error('No location data retrieved. Please reload page.');
		// throw Error('no location data');
	},
	(error) => {
		console.error(error);
		return Promise.reject(error);
	}
);
