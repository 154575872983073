// import { Space } from 'antd';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import CustodialWallets from '../../Dashboard/CustodialWallets';
import GasFeesCollected from './GasFeesCollected';
import GasSpent from './GasSpent';

const index = () => {
	return (
		<FullLayout pageTitle="Account">
			{/* <Space direction="vertical"> */}
			<CustodialWallets />

			<GasFeesCollected />
			<GasSpent />
			{/* </Space> */}
		</FullLayout>
	);
};

export default index;
