import { noauthBaseuri } from '../baseuri';
import reduxTypes from '../redux-types';

const getSharePrices = () => {
	return async (dispatch: Function) => {
		try {
			const result: any = await noauthBaseuri.get(
				`/dashboard/share-prices?protocol=xAuto&protocol=venus`
			);
			if (result.status === 200) {
				dispatch({
					type: reduxTypes.SHARE_PRICES,
					payload: result.data.data,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};
};

export default getSharePrices;
