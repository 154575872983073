import { FC, useState } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import HeaderComponent from './HeaderComponent';
import SideBar from './SideBar';

interface IFullLayout {
	pageTitle: string;
}

const FullLayout: FC<IFullLayout> = ({ children, pageTitle }) => {
	const [collapsed, setCollapsed] = useState(true);

	const { user } = useSelector((store: any) => store.auth);

	return (
		<Layout>
			<Sider
				theme="light"
				breakpoint="lg"
				collapsedWidth="0"
				onCollapse={(_collapsed) => {
					setCollapsed(!_collapsed);
				}}
				style={{
					height: '100vh',
					position: 'fixed',
					width: '20%',
					left: 0,
					zIndex: 1000,
				}}
			>
				<SideBar />
			</Sider>

			{pageTitle && user && (
				<Helmet>
					<title>
						{pageTitle} | Admin | {user?.email}
					</title>
				</Helmet>
			)}

			<Layout
				className="site-layout"
				style={{
					// eslint-disable-next-line prettier/prettier
					marginLeft: `${collapsed && window.innerWidth > 800 ? '200px' : '0px'}`,
				}}
			>
				<HeaderComponent pageTitle={pageTitle} />

				<Content
					style={{
						padding: 20,
						height: '100%',
						minHeight: '100vh',
						overflowY: 'auto',
						paddingTop: 100,
					}}
				>
					{children}
				</Content>
			</Layout>
		</Layout>
	);
};

export default FullLayout;
