import reduxTypes from '../redux-types';

const initialState = {
	address: null,
	chainId: 56,
	amount: 0,
	bnb: 0,
	xBUSD: 0,
};

interface Action {
	type: string;
	payload: any;
}

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }: Action) => {
	switch (type) {
		case reduxTypes.ADDRESS:
			return { ...state, ...payload };

		case reduxTypes.AMOUNT:
			return { ...state, ...payload };

		default:
			return state;
	}
};
