import { Button, message, Modal, Input, Tag } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import styled from 'styled-components';

const AccessSection = styled.section`
	display: flex;
	flex-direction: row;
	> span {
		width: 25%;
		/* min-width: 150px; */
	}
	/* &.accessInput {
		width: 30px;
	} */
`;
interface ITerminateNewsLetter {
	newsletter: any;
}
const SendNewsLetter: FC<ITerminateNewsLetter> = ({ newsletter }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [senderPin, setSenderPin] = useState(' ' as any);

	const sendNewsletter = async () => {
		try {
			setloading(true);
			const res: any = await axios.post(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters/${newsletter.id}/send`,
				{
					sender_pin: senderPin,
				}
			);
			if (res.status === 200) {
				setloading(false);
				message.success('NewsLetters Sending Started');
				setVisible(false);
			}
		} catch (e: any) {
			if (e.response.data.status === 403) {
				setloading(false);
				console.error(e);
				message.error(e.response.data.data.message);
			} else {
				setloading(false);
				console.error(e);
				message.error('could not complete');
			}
		}
	};

	return (
		<div>
			<Tag
				style={{
					cursor: 'pointer',
				}}
				color="blue"
				onClick={() => setVisible(true)}
			>
				Send
			</Tag>
			<Modal
				width="50%"
				// footer={null}
				visible={visible}
				footer={[
					<Button
						loading={loading}
						type="primary"
						onClick={() => sendNewsletter()}
					>
						Send
					</Button>,
				]}
				onCancel={() => setVisible(false)}
			>
				<strong>
					{`Are you sure you want to Approve the sending of this NewsLetter ${
						newsletter?.subject ? `with Subject "${newsletter?.subject}"` : ''
					}`}
				</strong>
				<br />
				<br />
				<AccessSection>
					<span> Sender Pin : </span>
					<Input
						className="accessInput"
						placeholder="input access code"
						onChange={(e: any) => setSenderPin(e.target.value)}
					/>
				</AccessSection>
			</Modal>
		</div>
	);
};

export default SendNewsLetter;
