import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, Select, Upload } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

interface IAddAgentID {
	userId: string;
}
const ImageContainer = styled.div`
	display: inline-flex;
`;
const UploadButton = styled.div`
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ImageMain = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const UploadSection = styled.div``;
const AddAgentID: FC<IAddAgentID> = ({ userId }) => {
	const [uploadLoading, setUploadLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selectedIDType, setSelectedIDType] = useState('National_ID');
	const [agentID, setAgentID] = useState({
		loading: false,
		imageUrl: '',
		imageFile: '',
	});

	const IDTypes = ['National_ID', 'International_ID', 'Drivers_License'];

	function getBase64(img: any, callback: Function) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt5M = file.size / 1024 / 1024 < 5;
		if (!isLt5M) {
			message.error('Image must smaller than 5MB!');
		}
		return isJpgOrPng && isLt5M;
	};

	const handleChange = (info: any) => {
		setAgentID({ ...agentID, loading: true });

		// Get this url from response in real world.
		getBase64(info.file.originFileObj, (imageUrl: any) =>
			setAgentID({
				imageUrl,
				loading: false,
				imageFile: info.file.originFileObj,
			})
		);
	};

	const uploadButton = (
		<div>
			{agentID?.loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('cardUrl', agentID?.imageFile);
		formData.append('idcardType', selectedIDType);
		try {
			setUploadLoading(true);
			const res: any = await baseuri.put(
				`/admin/update-agent-idcard?userid=${userId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (res.status === 200) {
				setUploadLoading(false);
				setAgentID({
					imageUrl: '',
					loading: false,
					imageFile: '',
				});

				message.success(res?.data?.data);
			} else {
				setUploadLoading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setUploadLoading(false);
			message.error(e.response?.data?.data?.message);
		}
	};

	return (
		<>
			<Button type="primary" onClick={() => setShowModal(true)}>
				UPDATE
			</Button>
			<Modal
				title="Upload ID Card"
				visible={showModal}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpload}>
						<Button
							loading={uploadLoading}
							type="primary"
							disabled={!(agentID?.imageUrl && selectedIDType)}
						>
							Update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setShowModal(false)}
			>
				<UploadSection>
					Upload ID Image:
					<ImageContainer>
						<Upload
							name="avatar"
							listType="picture-card"
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleChange}
						>
							{agentID?.imageUrl ? (
								<ImageMain
									src={agentID?.imageUrl}
									alt="avatar"
									className="actualImage"
									style={{ width: '100%' }}
								/>
							) : (
								uploadButton
							)}
						</Upload>
						<UploadButton>
							<Select
								placeholder="Select ID type"
								style={{ width: 130 }}
								value={selectedIDType}
								onChange={(e: any) => setSelectedIDType(e)}
							>
								{IDTypes?.map((item: string) => (
									<Select.Option key={item} value={item}>
										{item}
									</Select.Option>
								))}
							</Select>
						</UploadButton>
					</ImageContainer>
				</UploadSection>
			</Modal>
		</>
	);
};

export default AddAgentID;
