import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import SelectPermissions from './SelectPermissions';

interface IModifyRole {
	details: any;
}

const ModifyRole: FC<IModifyRole> = ({ details }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [permissions, setPermissions] = useState(details?.permissions as any);
	const [title, setTitle] = useState(details?.title);
	const [description, setDescription] = useState(details?.description);

	const handleCreate = async () => {
		try {
			setloading(true);
			const res = await baseuri.patch(`/admin/roles/${details?.id}`, {
				title,
				description,
				permissions,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Role Updated Successfully');
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error(e.toString());
		}
	};

	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="small"
				onClick={() => setVisible(true)}
			>
				Modify Role
			</Button>
			<Modal
				title="Modify Role"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Title :{' '}
					<Input
						placeholder="input title"
						value={title}
						onChange={(e: any) => setTitle(e.target.value)}
					/>
				</p>
				<p>
					Description:{' '}
					<Input
						placeholder="input description"
						value={description}
						onChange={(e: any) => setDescription(e.target.value)}
					/>
				</p>

				<p>Select Permissions</p>
				<SelectPermissions
					setPermissions={setPermissions}
					permissions={permissions}
				/>
			</Modal>
		</>
	);
};

export default ModifyRole;
