import {
	Avatar,
	Card,
	Pagination,
	Table,
	Tabs,
	DatePicker,
	Space,
	// Radio,
	Button,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
	enum2Currency,
	enum2TxnType,
	getTransactions,
} from '../../../methods/transactions';
import { getRequests } from '../../../methods/users';
import { commas, shortAmount, truncateAddress } from '../../../methods/utils';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import CSVComponent from '../../_ReUsable/CSVComponent';

export const TableProfile = styled.div`
	cursor: pointer;
	transition: all 600ms ease;
	text-decoration: none;

	& .avatar {
		background-color: hsl(235.89, 57.48%, 55%);
	}

	&:hover {
		& .avatar {
			background-color: hsl(235.89, 57.48%, 40%);
		}
	}
`;

const p2pTnxLabels = () => [
	{
		title: 'User',
		dataIndex: 'user',
		render: (text: any) => (
			<>
				{text.profileUrl ? (
					<Avatar src={text.profileUrl} />
				) : (
					<Avatar style={{ backgroundColor: '#3741c9' }}>
						{' '}
						{text?.username?.charAt(0)}
					</Avatar>
				)}
				<p>
					<strong>@{text.username}</strong>
				</p>
				<p>{text.email}</p>
			</>
		),
	},
	{
		title: 'Updated Balance',
		render: (text: any) => (
			<span>
				<strong>{text.updatedUserBalance}</strong>{' '}
				{/* enum2Currency(text.currency) */}
			</span>
		),
	},
	{
		title: 'Network',
		dataIndex: 'network',
	},
	{
		title: 'Order Reference',
		dataIndex: 'orderReference',
		render: (text: any) => <span>{text}</span>,
	},
	{
		title: 'Transaction Type',
		dataIndex: 'typeOfTransaction',
	},
	{
		title: 'Amount Paid',
		render: (text: any) => (
			<span>
				<strong>{text.amountPaidByCustomer}</strong> {text.payInCurrency}
			</span>
		),
	},
	{
		title: 'Amount Received',
		render: (text: any) => (
			<span>
				<strong>{text.amountPaidToCustomer}</strong> {text.receiveInCurrency}
			</span>
		),
	},
	{
		title: 'Hash',
		dataIndex: 'transactionHash',
		render: (text: any) => (
			<a href={text} target="_blank" rel="noreferrer">
				{truncateAddress(text, 6)}
			</a>
		),
	},
	{
		title: 'Order Status',
		dataIndex: 'orderStatus',
	},
	{
		title: 'Receiver Address',
		dataIndex: 'receiverWalletAddress',
		render: (text: any) => (
			<a
				href={`https://bscscan.com/address/${text}`}
				target="_blank"
				rel="noreferrer"
			>
				{truncateAddress(text, 6)}
			</a>
		),
	},
	{
		title: 'Dispute Status',
		dataIndex: 'disputeStatus',
	},
	{
		title: 'Paid By',
		dataIndex: 'paidBy',
	},
	{
		title: 'Payment Method',
		dataIndex: 'paymentMethod',
	},
	{
		title: 'Payment Date',
		dataIndex: 'paymentDate',
		render: (text: any) => moment(text).format('lll'),
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('lll'),
	},
];
const tnxTableLabels2 = () => [
	{
		title: 'User',
		dataIndex: 'username',
		width: 150,
		render: (text: any) => <p>{text}</p>,
	},
	{
		title: 'No. of Txs',
		dataIndex: 'transactionCount',
		width: 100,
		render: (text: any) => <strong>{text}</strong>,
	},
	{
		title: 'Total Amount',
		dataIndex: 'totalTransactionAmount',
		width: 100,
		render: (text: any) => <strong>{commas(shortAmount(text))}</strong>,
	},
];
const tnxTableLabels = (history: any) => [
	{
		title: 'User',
		dataIndex: 'userId',
		width: 150,
		render: (text: any) => (
			// eslint-disable-next-line no-underscore-dangle
			<TableProfile onClick={() => history.push(`/user-profile/${text._id}`)}>
				{text.profileUrl ? (
					<Avatar src={text.profileUrl} />
				) : (
					<Avatar className="avatar"> {text?.username?.charAt(0)}</Avatar>
				)}
				<>
					<p>
						<strong>@{text.username}</strong>
					</p>
					<p>{text.email}</p>
				</>
			</TableProfile>
		),
	},
	{
		title: 'Amount',
		width: 100,
		render: (text: any) => (
			<span>
				<strong>{text.amount}</strong> {enum2Currency(text.currency)}
			</span>
		),
	},
	{
		title: 'Network',
		width: 100,
		dataIndex: 'network',
	},
	{
		title: 'Hash',
		width: 100,
		dataIndex: 'txnHash',
		render: (text: any) => (
			<a href={text} target="_blank" rel="noreferrer">
				link out
			</a>
		),
	},
	// {
	//   title: "Plan",
	//   width: 150,
	//   dataIndex: "planId",
	// },
	{
		title: 'Type',
		width: 100,
		dataIndex: 'transactionState',
		render: (text: any) => <p>{enum2TxnType(text)}</p>,
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('lll'),
	},
];

const walletTableLabels = (history: any) => [
	{
		title: 'User',
		dataIndex: 'userId',
		width: 150,
		render: (text: any) => (
			// eslint-disable-next-line no-underscore-dangle
			<TableProfile onClick={() => history.push(`/user-profile/${text._id}`)}>
				{text.profileUrl ? (
					<Avatar src={text.profileUrl} />
				) : (
					<Avatar style={{ backgroundColor: '#3741c9' }}>
						{' '}
						{text?.username?.charAt(0)}
					</Avatar>
				)}
				<p>
					<strong>@{text.username}</strong>
				</p>
				<p>{text.email}</p>
			</TableProfile>
		),
	},
	{
		title: 'Amount',
		render: (text: any) => (
			<span>
				<strong>{text.amount}</strong> {enum2Currency(text.currency)}
			</span>
		),
	},
	{
		title: 'Type',
		dataIndex: 'typeOfTransaction',
	},
	{
		title: 'Description',
		dataIndex: 'description',
	},
	{
		title: 'Destination',
		dataIndex: 'toAddress',
	},
	{
		title: 'Hash',
		dataIndex: 'txnHash',
		render: (text: any) => (
			<a href={text} target="_blank" rel="noreferrer">
				link out
			</a>
		),
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('lll'),
	},
];

const Transactions = () => {
	const history = useHistory();

	const [startDate, setStartDate] = useState(
		moment('2021-12-11').format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [savingstxs, setSavingstxs] = useState({} as any);
	const [fundingTxs, setFundingTxs] = useState({} as any);
	const [withdrawalTxs, setWithdrawalTxs] = useState({} as any);
	const [p2pTxs, setP2pTxs] = useState({} as any);
	const [aggregate, setAggregate] = useState({} as any);
	// const [aggregateType, setAggregateType] = useState('all');

	const [loading, setLoading] = useState(false);

	const [query, setQuery] = useState({
		page: 1,
		type: '1',
	});
	const [tnxState, setTnxState] = useState({
		records: [],
		currentPage: 1,
		totalPages: 0,
		pageSize: 10,
		totalRecords: 0,
		loading: false,
	});

	const [walletTxn, setWalletTxn] = useState({
		records: [],
		currentPage: 1,
		totalPages: 0,
		pageSize: 10,
		totalRecords: 0,
		loading: false,
	});

	const settnxLoading = (value: boolean) => {
		if (Number(query.type) === 1) {
			setTnxState({ ...tnxState, loading: value });
		} else {
			setWalletTxn({ ...walletTxn, loading: value });
		}
	};
	useEffect(() => {
		getTransactions(query, startDate, endDate, setTnxState, settnxLoading);
	}, []);

	const onPageChange = (page: any) => {
		setLoading(true);
		getRequests(
			// `admin/all-savings-transactions?start=${startDate}&end=${endDate}&page=${page}`
			`admin/transactions?type=1&start=${startDate}&end=${endDate}&page=${page}`
		).then((fundings: any) => {
			setLoading(false);
			setSavingstxs(fundings?.data);
		});
	};

	const onPageChange2 = (page: any) => {
		getTransactions(
			{ ...query, page },
			startDate,
			endDate,
			setWalletTxn,
			settnxLoading
		);
	};

	useEffect(() => {
		if (Number(query?.type) === 1) {
			getTransactions(
				{ ...query },
				startDate,
				endDate,
				setTnxState,
				settnxLoading
			);
		} else {
			getTransactions(
				{ ...query },
				startDate,
				endDate,
				setWalletTxn,
				settnxLoading
			);
		}
	}, [query?.type]);

	useEffect(() => {
		setLoading(true);
		getRequests(
			`admin/transactions?type=1&start=${startDate}&end=${endDate}`
			// `admin/all-savings-transactions?start=${startDate}&end=${endDate}`
		).then((savings: any) => {
			setLoading(false);
			setSavingstxs(savings?.data);
		});
		getRequests(
			`admin/transactions/group?start=${startDate}&end=${endDate}`
		).then((aggregate2: any) => {
			setLoading(false);

			setAggregate(aggregate2?.data);
		});
		getRequests(
			`admin/all-wallet-transactions?typeOfTransaction=funding&start=${startDate}&end=${endDate}`
		).then((fundings: any) => {
			setLoading(false);
			setFundingTxs(fundings?.data);
		});
		getRequests(
			`admin/all-wallet-transactions?typeOfTransaction=withdraw&start=${startDate}&end=${endDate}`
		).then((withdrawals: any) => {
			setLoading(false);
			setWithdrawalTxs(withdrawals?.data);
		});
		getRequests(
			`admin/p2p-transactions?start=${startDate}&end=${endDate}`
		).then((p2p: any) => {
			setLoading(false);
			setP2pTxs(p2p?.data);
		});
	}, [endDate]);

	const handlePageSelection = (url: any) => {
		setLoading(true);
		getRequests(url).then((fundings: any) => {
			setLoading(false);
			setFundingTxs(fundings?.data);
		});
	};
	const handlePageSelection2 = (url: any) => {
		setLoading(true);
		getRequests(url).then((withdrawal: any) => {
			setLoading(false);
			setWithdrawalTxs(withdrawal?.data);
		});
	};
	const handlePageSelection3 = (url: any) => {
		setLoading(true);
		getRequests(url).then((p2p: any) => {
			setLoading(false);
			setP2pTxs(p2p?.data);
		});
	};
	const getCsvData = () => {
		const StaticURL = process.env.REACT_APP_BASE_URI?.replace('/v1/', '');
		const win = window.open(
			`${StaticURL}/static/front/downloads/${savingstxs?.downloadlink}`,
			'_blank'
		);
		win?.focus();
	};

	return (
		<FullLayout pageTitle="Transactions">
			<div>
				<Card>
					<h1>{moment().format('ll')}</h1>
					<div>
						<DatePicker
							defaultValue={moment(`${startDate}`, 'YYYY-MM-DD')}
							bordered={false}
							allowClear={false}
							disabledDate={(current) => {
								const customDate = moment().format('YYYY-MM-DD');
								return current && current > moment(customDate, 'YYYY-MM-DD');
							}}
							onChange={(date: any) => {
								setStartDate(moment(date).format('YYYY-MM-DD'));
							}}
						/>{' '}
						- TO -
						<DatePicker
							defaultValue={moment(`${endDate}`, 'YYYY-MM-DD')}
							bordered={false}
							allowClear={false}
							disabledDate={(current) => {
								const customDate = moment().format('YYYY-MM-DD');
								const afterTaday =
									current && current > moment(customDate, 'YYYY-MM-DD');
								const start34 =
									current && current < moment(startDate, 'YYYY-MM-DD');
								return start34 || afterTaday;
							}}
							onChange={(e: any) => {
								setEndDate(moment(e).format('YYYY-MM-DD'));
							}}
						/>
					</div>
					<Tabs
						defaultActiveKey="1"
						onChange={(key) => setQuery({ ...query, type: key })}
					>
						<Tabs.TabPane tab="Savings" key="1">
							<Button type="primary" onClick={getCsvData}>
								Download CSV
							</Button>
							<Table
								loading={loading}
								pagination={false}
								scroll={{ x: 'max-content' }}
								columns={tnxTableLabels(history)}
								dataSource={savingstxs?.records}
							/>

							<br />

							<Pagination
								onChange={onPageChange}
								disabled={loading}
								// pageSize={+savingstxs.pageSize}
								current={savingstxs?.currentPage}
								total={savingstxs?.totalPages}
								showSizeChanger={false}
							/>
						</Tabs.TabPane>

						<Tabs.TabPane tab="Aggregate Savings per user" key="2">
							<Space size={300}>
								<CSVComponent
									url="/admin/transactions/group"
									startDate={startDate}
									endDate={endDate}
									otherParams=""
								/>
								{/* <Radio.Group
									onChange={({ target: { value } }) => {
										setAggregateType(value);
									}}
									value={aggregateType}
								>
									<Radio value="all">ALL</Radio>
									<Radio value="flexible">FLEXIBLE</Radio>
									<Radio value="fixed">FIXED</Radio>
								</Radio.Group> */}
							</Space>
							<strong>
								<p>Users (Total: {aggregate?.DocumentCount})</p>
								<p>
									Amount (Total:{' '}
									{commas(shortAmount(aggregate?.totalTransactionAmount))})
								</p>
							</strong>
							<Table
								loading={loading}
								scroll={{ x: 'max-content' }}
								columns={tnxTableLabels2()}
								dataSource={aggregate?.Query}
							/>

							<br />
						</Tabs.TabPane>
						<Tabs.TabPane tab="All Wallet Tx" key="3">
							<CSVComponent
								url="/admin/transactions"
								startDate={startDate}
								endDate={endDate}
								otherParams=""
							/>
							<div>
								<Table
									loading={walletTxn.loading}
									pagination={false}
									columns={walletTableLabels(history)}
									dataSource={walletTxn.records}
								/>
							</div>

							<br />

							<Pagination
								onChange={onPageChange2}
								disabled={walletTxn.loading}
								pageSize={+walletTxn.pageSize}
								current={walletTxn.currentPage}
								total={walletTxn.totalRecords}
								showSizeChanger={false}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab={
								<span>
									<ArrowDownOutlined style={{ color: '#2feb8d' }} /> Fundings{' '}
								</span>
							}
							key="4"
						>
							<p>{fundingTxs?.totalRecords} Funding transactions</p>
							<div className="table-responsive">
								<Table
									loading={loading}
									pagination={false}
									columns={walletTableLabels(history)}
									dataSource={fundingTxs.result}
								/>
							</div>

							<br />

							<Pagination
								onChange={(page: any) =>
									handlePageSelection(
										`admin/all-wallet-transactions?typeOfTransaction=funding&start=${startDate}&end=${endDate}&page=${page}`
									)
								}
								disabled={loading}
								pageSize={+fundingTxs.pageSize}
								current={fundingTxs.currentPage}
								total={fundingTxs.totalRecords}
								showSizeChanger={false}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab={
								<span>
									<ArrowUpOutlined style={{ color: '#da0c0c' }} /> Withdrawals{' '}
								</span>
							}
							key="5"
						>
							<p>{withdrawalTxs.totalRecords} Withdrawals</p>
							<div className="table-responsive">
								<Table
									loading={loading}
									pagination={false}
									columns={walletTableLabels(history)}
									dataSource={withdrawalTxs.result}
								/>
							</div>

							<br />

							<Pagination
								onChange={(page: any) =>
									handlePageSelection2(
										`admin/all-wallet-transactions?typeOfTransaction=withdraw&start=${startDate}&end=${endDate}&page=${page}`
									)
								}
								disabled={loading}
								pageSize={withdrawalTxs.pageSize}
								current={withdrawalTxs.currentPage}
								total={withdrawalTxs.totalRecords}
								showSizeChanger={false}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="P2P Txs" key="6">
							<p>{p2pTxs.totalRecords} P2P transactions</p>
							<div className="table-responsive">
								<Table
									loading={loading}
									pagination={false}
									columns={p2pTnxLabels()}
									dataSource={p2pTxs.result}
								/>
							</div>

							<br />

							<Pagination
								onChange={(page: any) =>
									handlePageSelection3(
										`admin/p2p-transactions?start=${startDate}&end=${endDate}&page=${page}`
									)
								}
								disabled={loading}
								pageSize={+p2pTxs.pageSize}
								current={p2pTxs.currentPage}
								total={p2pTxs.totalRecords}
								showSizeChanger={false}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</div>
		</FullLayout>
	);
};

export default Transactions;
