import {
	Button,
	DatePicker,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Space,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

const { TextArea } = Input;

const CreateGreeting = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [greeting, setGreeting] = useState({
		title: '',
		description: '',
		timeFrame: '',
		expiry_time: '' as any,
	});

	const timeFrameEnum = [
		{
			name: 'All Day',
			value: '',
		},
		{
			name: 'Morning',
			value: 'morning',
		},
		{
			name: 'Afternoon',
			value: 'afternoon',
		},
		{
			name: 'Evening',
			value: 'evening',
		},
	];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post('admin/create-alert', greeting);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Greeting created Successfully');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.toString());
		}
	};
	return (
		<div>
			<Button
				type="primary"
				shape="round"
				size="middle"
				ghost
				onClick={() => setVisible(true)}
			>
				Create New Greeting
			</Button>
			<Modal
				title="Create New Greeting"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<Space direction="vertical" size="large">
					<p>
						Title :{' '}
						<Input
							placeholder="input Title"
							value={greeting?.title}
							onChange={(e: any) =>
								setGreeting({ ...greeting, title: e.target.value })
							}
						/>
					</p>
					<p>
						{' '}
						<p>Description: </p>
						<TextArea
							showCount
							maxLength={100}
							onChange={(e: any) =>
								setGreeting({ ...greeting, description: e.target.value })
							}
						/>
					</p>
					<p>
						<p>Time Frame: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={greeting.timeFrame}
							onChange={(e) => {
								setGreeting({ ...greeting, timeFrame: e });
							}}
						>
							{timeFrameEnum?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p> Expiry time: </p>
						<DatePicker
							format="YYYY-MM-DD HH:mm:ss"
							disabledDate={(current) =>
								current && current < moment().endOf('day')
							}
							// disabledTime={disabledDateTime}
							showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							onChange={(date) =>
								setGreeting({
									...greeting,
									expiry_time: date,
								})
							}
						/>
					</p>
				</Space>
			</Modal>
		</div>
	);
};

export default CreateGreeting;
