import { Card, Col, DatePicker, Row } from 'antd';
import GrowthReportsTable from './GrowthReportsTable';

const GrowthReports = () => {
	return (
		<div>
			<h3 style={{ margin: '20px 0px' }}>Weeekly Growth Reports</h3>
			<Card
				style={{
					boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
					margin: '5px 0px',
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<h3>Start Interval</h3>
						<DatePicker />
					</div>
					<div>
						<h3>End Interval</h3>
						<DatePicker />
					</div>
					<div>
						<h3>Filter Category</h3>
						{/* <Dropdown>
							<Button>
								users <DownOutlined />
							</Button>
						</Dropdown> */}
					</div>
				</div>
			</Card>
			<Card style={{ margin: '15px 0px' }}>
				<Row gutter={[16, 24]} justify="space-between">
					<Col className="gutter-row" span={6}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									//   alignItems: "center",
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
									height: '115px',
								}}
							>
								<h3> Start Dte</h3>
								<p
									style={{
										color: 'black',
										fontSize: '20px',
										fontWeight: 'bolder',
									}}
								>
									Sun jun 01 2021
								</p>
							</div>
						</Card>
					</Col>
					<Col className="gutter-row" span={6}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									//   alignItems: "center",
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
									height: '115px',
								}}
							>
								<h3>End Date</h3>
								<p
									style={{
										color: 'black',
										fontSize: '20px',
										fontWeight: 'bolder',
									}}
								>
									Wed Dec 16 2021
								</p>
							</div>
						</Card>
					</Col>
					<Col className="gutter-row" span={6}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									//   alignItems: "center",
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
									height: '115px',
								}}
							>
								<h3>Growth Rate</h3>

								<p
									style={{
										color: 'black',
										fontSize: '20px',
										fontWeight: 'bolder',
									}}
								>
									23%
								</p>
							</div>
						</Card>
					</Col>
					<Col className="gutter-row" span={6}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									//   alignItems: "center",
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
									height: '115px',
								}}
							>
								<h3>Net Increase/Decrease</h3>

								<p
									style={{
										color: 'black',
										fontSize: '20px',
										fontWeight: 'bolder',
									}}
								>
									23%
								</p>
							</div>
						</Card>
					</Col>
				</Row>
				<GrowthReportsTable />
			</Card>
		</div>
	);
};

export default GrowthReports;
