import Web3 from 'web3';
import reduxTypes from '../redux-types';
import { assets } from './assets';
import createContract from './create-contract';
import createContract2 from './create-contract2';

const web3 = new Web3('https://bsc-dataseed.binance.org/');

export const getAmounts = (client: string) => {
	return async (dispatch: Function) => {
		try {
			const asset = assets[0];

			const busd = await createContract(asset.tokenAbi, asset.tokenAddress);
			let amount = await busd.methods.balanceOf(client).call();
			amount = Web3.utils.fromWei(amount, 'ether');

			const xbusd = await createContract(
				asset.protocolAbi,
				asset.protocolAddress
			);
			let xamount = await xbusd.methods.balanceOf(client).call();
			xamount = Web3.utils.fromWei(xamount, 'ether');

			let bnb = await window.APPWEB3.eth.getBalance(client);
			bnb = Web3.utils.fromWei(bnb, 'ether');

			dispatch({
				type: reduxTypes.AMOUNT,
				payload: { amount, xBUSD: xamount, bnb },
			});
		} catch (e) {
			console.error(e);
		}
	};
};

export const getBalances = async (client: string) => {
	try {
		const asset = assets[0];

		const busd = new web3.eth.Contract(
			asset.tokenAbi as any,
			asset.tokenAddress as any
		);

		// const busd = await createContract2(asset.tokenAbi, asset.tokenAddress);
		let amount = await busd.methods.balanceOf(client).call();
		amount = Web3.utils.fromWei(amount, 'ether');

		const xend = new web3.eth.Contract(
			asset.tokenAbi as any,
			'0x4a080377f83D669D7bB83B3184a8A5E61B500608'
		);

		let xendAmount = await xend.methods.balanceOf(client).call();
		xendAmount = Web3.utils.fromWei(xendAmount, 'ether');

		const xbusd = await createContract2(
			asset.protocolAbi,
			asset.protocolAddress
		);
		let xamount = await xbusd.methods.balanceOf(client).call();
		xamount = Web3.utils.fromWei(xamount, 'ether');

		let bnb = await web3.eth.getBalance(client);
		bnb = Web3.utils.fromWei(bnb, 'ether');

		return {
			BUSD: amount,
			xBUSD: xamount,
			BNB: bnb,
			XEND: xendAmount,
			address: client,
		};
	} catch (e) {
		console.error(e);
		return null;
	}
};
