import { Button, message, Modal } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IRemoveAsAgent {
	email: string;
	usertype: string;
	// removed: any;
}
const RemoveAsAgent: FC<IRemoveAsAgent> = ({ email, usertype }) => {
	const [loading, setloading] = useState(false);

	const removeRoleRecord = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(`/admin/remove-usertype`, {
				email,
				usertype,
			});

			if (res.status === 200) {
				setloading(false);
				message.success('Agent Status Updated Successfully');
			}
			setloading(false);
			message.info(res?.data?.message);
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Remove this User as an Agent?',
			onOk() {
				removeRoleRecord();
			},
		});
	};

	return (
		<Button danger size="small" loading={loading} onClick={confirm}>
			Remove As Agent
		</Button>
	);
};
export default RemoveAsAgent;
