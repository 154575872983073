import { Space } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { commas } from '../../../methods/utils';

const CardTableHeaderSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	/* margin: 10px 0px; */
	gap: 2em;
`;

interface ITableHeaderSection {
	tableName: string;
	currentPage: any;
	totalRecords: any;
}
const TableHeaderSection: FC<ITableHeaderSection> = ({
	tableName,
	currentPage,
	totalRecords,
}) => {
	return (
		<CardTableHeaderSection>
			<Space size="middle">
				<span className="tableTitle">
					Title: <strong>{tableName || 'N/A'}</strong>
				</span>
				<span className="pageSection">
					Page: <strong>{currentPage}</strong>
				</span>
				<span className="recordSection">
					<strong>{commas(totalRecords)}</strong> Records
				</span>
			</Space>
		</CardTableHeaderSection>
	);
};

export default TableHeaderSection;
