import {
	Button,
	Card,
	Checkbox,
	Divider,
	message,
	Modal,
	Pagination,
	Popconfirm,
	Progress,
	Select,
	Table,
	Tag,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { format } from 'timeago.js';
import baseuri from '../../../methods/baseuri';
import retrieveProcessingList from '../../../methods/processing';
import { commas } from '../../../methods/utils';
import { getAmounts } from '../../../methods/web3/amounts';
import { assets } from '../../../methods/web3/assets';
import { depositSavings } from '../../../methods/web3/deposit';
import ProcessingList from '../ProcessingList';
import AdvancedLayout from '../../Layouts/Advanced';
import QueueStatuses from './QueueStatuses';
import UpdateQueueItemStatus from '../WithdrawQueue/UpdateQueueItemStatus';
// import UpdateQueueStatus from '../UpdateQueueStatus';

export const ProcessDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	& .amount {
		font-size: 24px;
	}

	& > div {
		width: 30%;
		max-width: 600px;
	}
`;

export const QueueStatusStyle = styled.section`
	display: flex;
	align-items: flex-end;

	& > div {
		margin-right: 20px;
	}
`;

export const DateSection = styled.div`
	display: flex;
	gap: 1em;
`;
interface IProcessDeposits {
	selected: any[];
	amount: number;
	processListId: string;
}
export const ProcessDeposits: FC<IProcessDeposits> = ({
	processListId,
	selected,
	amount,
}) => {
	const dispatch = useDispatch();
	const { address } = useSelector((store: any) => store.web3conn);
	const [visible, setVisible] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [activity, setActivity] = useState({
		activity: '',
		status: undefined,
		percent: 0,
	});

	const [success, setSuccess] = useState<any>(null);

	const sendData = (data: any) => {
		setActivity({ ...activity, ...data });
	};

	const processSelectedRequests = async () => {
		try {
			const planIdAndType = selected.map((x) => {
				return `${x.planId} ${x.planType} ${x._id}`;
			});

			dispatch(
				depositSavings({
					amount,
					processListId,
					asset: assets[0],
					client: address,
					planIdAndType,
					sendData,
					successData: setSuccess,
				})
			);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<Button
				onClick={() => setVisible(true)}
				disabled={amount === 0}
				type="primary"
			>
				Process List
			</Button>

			<Modal
				footer={[
					<Button onClick={() => setVisible(false)}>Cancel</Button>,
					<Popconfirm
						title="Final Confirmation. Are you sure?"
						onConfirm={() => {
							processSelectedRequests();
							setVisible(false);
							setProcessing(true);
						}}
					>
						<Button type="primary">Continue</Button>
					</Popconfirm>,
				]}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Process Deposit Batch"
			>
				<p>
					You are attempting to deposit the sum of{' '}
					<strong>{commas(amount)} BUSD</strong> for{' '}
					<strong>{selected.length} deposit queue items</strong>. Confirm that
					you want to perform this transaction before continuing. Clicking{' '}
					<strong>Continue</strong> will process this transaction. Click Cancel
					to go back.
				</p>
			</Modal>

			<Modal
				footer={null}
				title="Processing..."
				onCancel={() => {
					setProcessing(false);
					dispatch(retrieveProcessingList());
				}}
				visible={processing}
				closable
			>
				<p>Please do not close this modal</p>

				<br />

				<p>Current activity: {activity.activity}</p>
				<Progress percent={activity.percent} status={activity.status} />

				<br />
				<br />

				{success && (
					<Button
						onClick={() => {
							setProcessing(false);
							dispatch(retrieveProcessingList());
							dispatch(getAmounts(address));
						}}
					>
						Close
					</Button>
				)}
			</Modal>
		</>
	);
};

interface ISelection {
	data: any;
	selectChange: Function;
}
export const Selection: FC<ISelection> = ({ data, selectChange }) => {
	// console.log(data)
	const { amount, status, planType, _id, planId } = data;

	const onChange = (e: any) => {
		const isChecked = e.target.checked;
		if (isChecked) {
			selectChange('add', { planId, _id, planType, amount });
		} else {
			selectChange('remove', { _id });
		}
	};

	return (
		<>
			{status === 'pending' && <Checkbox onChange={onChange} />}
			{!status && null}
		</>
	);
};

interface ICancelDepositRequest {
	data: any;
}
export const CancelDepositRequest: FC<ICancelDepositRequest> = ({ data }) => {
	const { status } = data;

	const cancelItem = async () => {
		Modal.confirm({
			title: 'Cancel deposit queue item',
			onOk: async () => {
				try {
					const res = await baseuri.put('/admin/cancel-deposit-queue-item', {
						queueItemId: data._id,
					});
					if (res.status === 200) {
						message.success('Item cancelled');
					}
				} catch (e) {
					console.error(e);
				}
			},
		});
	};

	return (
		<>
			{status === 'pending' && (
				<Button danger onClick={() => cancelItem()}>
					Cancel Item
				</Button>
			)}
			{!status && null}
		</>
	);
};

interface ICreateProcessingList {
	type: 'deposit' | 'withdraw';
}
export const CreateProcessingList: FC<ICreateProcessingList> = ({ type }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const createList = async () => {
		try {
			setLoading(true);
			const res = await baseuri.post('/advanced/create-process-list', {
				processType: type,
			});
			if (res.status === 200) {
				dispatch(retrieveProcessingList());
			}

			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	return (
		<Button disabled={loading} loading={loading} onClick={() => createList()}>
			Create Processing List
		</Button>
	);
};

interface IViewProcessItems {
	items: Array<any>;
	processListId: string;
	processStatus: any;
}

export const ViewProcessItems: FC<IViewProcessItems> = ({
	items,
	processListId,
	processStatus,
}) => {
	const [visible, setVisible] = useState(false);

	const removeQueueItem = async (id: string) => {
		try {
			const res = await baseuri.post(
				'/advanced/remove-queue-item-from-process-list',
				{ processListId, queueItemId: id }
			);
			if (res.status === 200) {
				console.info(res.data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<Button type="text" danger onClick={() => setVisible(true)}>
				view items
			</Button>

			<Modal
				footer={null}
				title="Process Items"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				{items.map((x: any) => (
					<>
						<Card>
							<p>
								{x.planType === 0 ? 'Flexible' : 'Fixed'} Savings: {x.amount}{' '}
								BUSD
							</p>

							<p>
								Created: {moment(x.createdAt).format('lll')} by @
								{x.userId?.username}
							</p>
							<br />
							{processStatus === 0 && (
								<Popconfirm
									title="Are you sure?"
									onConfirm={() => removeQueueItem(x._id)}
								>
									<Button size="small" danger>
										Remove Item
									</Button>
								</Popconfirm>
							)}
						</Card>
						<br />
					</>
				))}
			</Modal>
		</>
	);
};

const DepositQueue = () => {
	const dispatch = useDispatch();

	const { amount, xBUSD, bnb } = useSelector((store: any) => store.web3conn);
	const { list } = useSelector((store: any) => store.processList);

	const [records, setRecord] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const pageSize = 30;
	const [queueItemStatus, setQueueItemStatus] = useState('pending');
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);

	const [activeProcess, setActiveProcess] = useState<any>(null);
	const [processingAmount, setProcessingAmount] = useState(0);

	const [selectedPlans, setSelectedPlans] = useState<Array<any>>([]);

	const [updatedFired, setUpdateFired] = useState(false);

	const fetchList = async (fetchPage = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/deposit-queue', {
				params: { page: fetchPage, status: queueItemStatus, pageSize },
			});
			const { data } = res.data;

			setRecord(data?.records);
			setPage(data?.currentPage);
			setTotalRecords(data?.totalRecords);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchList();
		dispatch(retrieveProcessingList());
	}, []);

	useEffect(() => {
		fetchList(1);
	}, [queueItemStatus]);

	useEffect(() => {
		const pending = list.filter((x: any) => Number(x.status) !== 2);
		// console.log(list)
		if (pending.length > 0) {
			setActiveProcess(pending[0]);
		}
	}, [list]);

	useEffect(() => {
		if (activeProcess) {
			setSelectedPlans(activeProcess.items);
		}
	}, [activeProcess]);

	useEffect(() => {
		const amountToProcess = selectedPlans.reduce((a, b) => a + b.amount, 0);
		setProcessingAmount(amountToProcess);
	}, [selectedPlans, records]);

	const onChange = (fetchPage: any) => {
		fetchList(fetchPage);
	};

	const setColor = (status: string) => {
		switch (status) {
			case 'done':
				return 'green';
			case 'pending':
				return 'orange';
			case 'failed':
				return 'red';
			case 'processing':
				return 'blue';
			default:
				return 'gray';
		}
	};

	const addToProccessList = async (data: any) => {
		try {
			const res = await baseuri.post(
				'/advanced/add-queue-item-to-process-list',
				{ queueItemId: data._id, processListId: activeProcess?._id }
			);
			if (res.status === 200) {
				// set to processing
				const editRecords = records.map((x: any) => {
					let y = x;
					if (x._id === data._id) {
						y = { ...x, status: 'processing' };
					}
					return y;
				});
				setRecord(editRecords);

				// add to list
				const newList = selectedPlans.concat([data]);
				setSelectedPlans(newList);
			}
			console.error(res);
		} catch (e) {
			console.error(e);
		}
	};

	const rowSelectChange = (action: 'add' | 'remove', data: any) => {
		if (action === 'add') {
			addToProccessList(data);
			// const added = selectedPlans.concat([data])
			// setSelectedPlans(added);
		}
		if (action === 'remove') {
			// const filteredOut = selectedPlans.filter(x => x._id != data._id);
			// setSelectedPlans(filteredOut);
		}
	};

	const completeProcess = async () => {
		try {
			setUpdateFired(true);
			const res = await baseuri.post('/advanced/process-batch', {
				processListId: activeProcess._id,
			});
			if (res.status === 200) {
				dispatch(retrieveProcessingList());
			}
			setUpdateFired(false);
		} catch (e) {
			console.error(e);
			setUpdateFired(false);
		}
	};

	const updateStatus = (queueId: any, status: any) => {
		const editRecords = records.map((x: any) => {
			let y = x;
			if (x._id === queueId) {
				y = { ...x, status };
			}
			return y;
		});
		setRecord(editRecords);
	};

	const columns = [
		{
			key: 0,
			title: '',
			render: (x: any) => <Selection data={x} selectChange={rowSelectChange} />,
		},
		{
			key: 1,
			title: 'User',
			render: (data: any) => (
				<>
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${data?.userId?._id}`}
						>
							{data.userId?.fullName}
						</Link>
					</p>
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${data?.userId?._id}`}
						>
							@{data.userId?.username}
						</Link>
					</p>
				</>
			),
		},
		// {
		// 	key: 2,
		// 	title: "Email",
		// 	render: (data: any) => <>{data.userId?.email}</>
		// },
		{
			key: 6,
			title: 'Status',
			dataIndex: 'status',
			render: (x: any) => <Tag color={setColor(x)}>{x}</Tag>,
		},
		{
			key: 3,
			title: 'Amount',
			dataIndex: 'amount',
			render: (x: any) => `${x} BUSD`,
		},
		{
			key: 5,
			title: 'Plan Type',
			dataIndex: 'planType',
			render: (x: any) => (x === 0 ? 'Flexible Savings' : 'Fixed Savings'),
		},
		{
			key: 99,
			title: 'Processed By',
			dataIndex: 'processList',
			render: (data: any) => <p>{data?.creator?.email}</p>,
		},
		{
			key: 4,
			title: 'Date Updated',
			dataIndex: 'updatedAt',
			render: (x: any) => (
				<DateSection>
					{' '}
					{moment(x).format('MMM Do YY, h:mm:ss a')}{' '}
					<strong>{format(x)}</strong>
				</DateSection>
			),
		},
		{
			key: 49,
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('MMM Do YY, h:mm:ss a'),
		},
		{
			key: 10,
			title: '',
			render: (x: any) => <CancelDepositRequest data={x} />,
		},
		{
			key: 50,
			title: '',
			render: (x: any) => (
				<UpdateQueueItemStatus
					removeItem={(status: any) => updateStatus(x._id, status)}
					queueItemId={x._id}
					whichQueue="deposit"
				/>
			),
		},
		// {
		// 	key: 111,
		// 	title: '',
		// 	render: (x: any) => (
		// 		<UpdateQueueStatus queueId={x._id} queueType="deposit" />
		// 	),
		// },
	];

	return (
		<AdvancedLayout title="Deposit Queue">
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<ProcessingList name="Processing List" />
				</div>

				<br />

				<Card>
					<ProcessDetails>
						<div>
							<p>Balances</p>
							<p>{commas(amount)} BUSD</p>
							<p>{commas(xBUSD)} xBUSD</p>
							<p>{commas(bnb)} BNB</p>
						</div>
						<div>
							{!activeProcess && <CreateProcessingList type="deposit" />}
							{activeProcess && activeProcess.type === 'deposit' && (
								<div>
									<p>
										status: {activeProcess.status === 0 && 'Yet to be saved'}
										{activeProcess.status === 1 && 'To be propagated'}
									</p>
									<p>
										Active Process: Created{' '}
										{moment(activeProcess.createdAt).format('lll')}
									</p>
									<p>
										Total amount in list{' '}
										<strong>{commas(processingAmount)} BUSD</strong>
									</p>
									<p>
										{activeProcess.transactionHash && (
											<a
												target="_blank"
												href={`https://bscscan.com/tx/${activeProcess.transactionHash}`}
												rel="noreferrer"
											>
												hash
											</a>
										)}
									</p>

									<br />

									{activeProcess.transactionHash && activeProcess.status === 1 && (
										<Button
											loading={updatedFired}
											disabled={updatedFired}
											onClick={() => completeProcess()}
										>
											Update Queue and Plans
										</Button>
									)}

									<br />

									<ViewProcessItems
										processStatus={activeProcess.status}
										processListId={activeProcess._id}
										items={activeProcess.items}
									/>
								</div>
							)}
						</div>
						<div>
							{activeProcess?.status === 0 &&
								activeProcess.type === 'deposit' && (
									<ProcessDeposits
										processListId={activeProcess?._id}
										selected={selectedPlans}
										amount={processingAmount}
									/>
								)}
						</div>
					</ProcessDetails>
				</Card>

				<br />

				<QueueStatusStyle>
					<div>
						<p>Change Status</p>
						<Select
							style={{ minWidth: 100 }}
							defaultValue={queueItemStatus}
							onChange={(e) => setQueueItemStatus(e)}
						>
							<Select.Option value="pending">Pending Items</Select.Option>
							<Select.Option value="done">Completed Items</Select.Option>
							<Select.Option value="processing">Processing Items</Select.Option>
							<Select.Option value="cancelled">Canceled Items</Select.Option>
						</Select>
					</div>
					<QueueStatuses queue="deposit" />
				</QueueStatusStyle>

				<Divider />
				<p>
					<strong>
						{totalRecords} items {queueItemStatus}
					</strong>
				</p>
				<br />
				<Table
					loading={loading}
					dataSource={records}
					columns={columns}
					pagination={false}
				/>

				<br />

				<Pagination
					onChange={onChange}
					showSizeChanger={false}
					pageSize={pageSize}
					current={page}
					total={totalRecords}
				/>
			</div>
		</AdvancedLayout>
	);
};

export default DepositQueue;
