import { Button, Divider, Drawer, Image } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import AddAgentID from './AddAgentID';
import AddAgentImage from './AddAgentImage';
import AddShopImg from './AddShopImg';
import AddOtherInfo from './AddOtherInfo';

const ImageSecton = styled.div`
	display: flex;
	position: relative;
	align-items: flex-end;
	gap: 1em;
`;
const OtherInfoSection = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
`;
const ImageContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	border: 2px solid #aaa3a3;
	border-radius: 5px;
`;

interface IKYCData {
	userDetails: any;
}

// userId:
// KycId:
// createdAt: "2022-02-25T20:24:54.298Z"
// idCardEncryptKey: "sOjNF0Z"
// idCardImageIpfsHash: "QmdEox9GVNsKLEorKpJfMSzyztxowcm7Ke4r8rBvjERBFw"
// idCardImageUrl: "https://ipfs.io/ipfs/QmdEox9GVNsKLEorKpJfMSzyztxowcm7Ke4r8rBvjERBFw"
// idcardType: "National ID"
// shopImageIpfsHash: "QmXiXFC5t5FWBEE8HxNLYCo91nWe3yuQd9i1rPoe3Et6pK"
// shopImageUrl: "https://ipfs.io/ipfs/QmXiXFC5t5FWBEE8HxNLYCo91nWe3yuQd9i1rPoe3Et6pK"
// shopOwnerSelfieIpfsHash: null
// shopOwnerSelfieUrl: null
// storeCoordinates: null
// updatedAt: "2022-02-28T08:05:05.117Z"
// userId: "61b4494638754c6041a61049"
// __v: 0
// _id
const KYCData: FC<IKYCData> = ({ userDetails }) => {
	const [visible, setVisible] = useState(false);

	return (
		<div>
			<Button type="primary" onClick={() => setVisible(true)}>
				Agent Info
			</Button>
			<Drawer
				title="KYC DATA"
				width={640}
				placement="right"
				closable={false}
				onClose={() => setVisible(false)}
				visible={visible}
			>
				<div>
					<p>
						KycId
						<strong>Agent Live Picture</strong>
						<ImageSecton>
							<ImageContainer>
								{userDetails?.shopOwnerSelfieUrl ? (
									<Image
										width="100%"
										height="100%"
										src={userDetails?.KycId?.shopOwnerSelfieUrl}
										alt="Unable to load Image"
									/>
								) : (
									'NO IMAGE'
								)}
							</ImageContainer>
							<Button
								type="primary"
								disabled={!userDetails?.KycId?.shopOwnerSelfieUrl}
							>
								Download
							</Button>
							<AddAgentImage userId={userDetails?._id} />
						</ImageSecton>
					</p>
					<Divider />
					<p>
						<strong>Shop Image</strong>
						<ImageSecton>
							<ImageContainer>
								{userDetails?.KycId?.shopImageUrl ? (
									<Image
										width="100%"
										height="100%"
										src={userDetails?.KycId?.shopImageUrl}
										alt="Unable to load Image"
									/>
								) : (
									'NO IMAGE'
								)}
							</ImageContainer>
							<Button
								type="primary"
								disabled={!userDetails?.KycId?.shopImageUrl}
							>
								Download
							</Button>
							<AddShopImg userId={userDetails?._id} />
						</ImageSecton>
					</p>
					<Divider />
					<p>
						<strong>Agent ID Card Image</strong>
						<ImageSecton>
							<ImageContainer>
								{userDetails?.KycId?.idCardImageUrl ? (
									<Image
										width="100%"
										height="100%"
										src={userDetails?.KycId?.idCardImageUrl}
										alt="Unable to load Image"
									/>
								) : (
									'NO IMAGE'
								)}
							</ImageContainer>
							<Button
								type="primary"
								disabled={!userDetails?.KycId?.idCardImageUrl}
							>
								Download
							</Button>
							<AddAgentID userId={userDetails?._id} />
						</ImageSecton>
					</p>
					<Divider />
					<p>
						<strong>Other Agents Info</strong>
						<OtherInfoSection>
							<p>Agent Shop LOcation</p>
							<div>
								<span>
									{userDetails?.KycId?.storeState
										? userDetails?.KycId?.storeState
										: 'Not Available'}
								</span>
								<AddOtherInfo userId={userDetails?._id} />
							</div>
						</OtherInfoSection>
					</p>
					<Divider />
				</div>
			</Drawer>
		</div>
	);
};

export default KYCData;
