import baseuri from './baseuri';
import reduxTypes from './redux-types';

const retrieveProcessingList = () => {
	return async (dispatch: Function) => {
		try {
			const res = await baseuri.get('/advanced/process-lists');
			const resData: any = res.data;

			dispatch({
				type: reduxTypes.PROCESSING_LIST,
				payload: { list: resData.data },
			});
		} catch (e) {
			console.error(e);
		}
	};
};

export default retrieveProcessingList;
