/**
 * This component handles the removal of wallets created in error
 */

import { Modal, Button, message, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface IResolveWalletIssue {
	walletPosition: number;
	wallets: any;
}

const ResolveWalletIssues: FC<IResolveWalletIssue> = ({
	walletPosition,
	wallets,
}) => {
	const [visible, setVisible] = useState(false);
	const [allWallets, setAllWallets] = useState<Array<any>>([]);

	useEffect(() => {
		if (Array.isArray(wallets)) {
			setAllWallets(wallets);
		}
	}, [wallets]);

	const deleteWallet = async () => {
		try {
			const walletId = allWallets[walletPosition]?._id;

			const res = await baseuri.post('/resolve-multiple-wallet-issue', {
				walletId,
			});

			if (res.status === 200) {
				message.success('resolve action triggered');
			}
		} catch (e) {
			console.error(e);
		}
	};

	const modalConfirm = () => {
		Modal.confirm({
			title:
				'Confirm that your know what you are doing before proceeding with this action.',
			onOk: () => {
				deleteWallet();
				setVisible(false);
			},
		});
	};

	if (allWallets.length > 1) {
		return (
			<>
				<Popconfirm title="Are you sure?" onConfirm={() => setVisible(true)}>
					<Button type="link" onClick={() => deleteWallet()}>
						Resolve
					</Button>
				</Popconfirm>

				<Modal
					footer={null}
					visible={visible}
					onCancel={() => setVisible(false)}
					title="Delete Wallet"
				>
					<p>
						This action deletes the zero (0) balances from this selected wallet
						and also deletes the wallet if there are no balances in any of the
						currencies, and if the this isn&apos;t the last wallet
					</p>
					<br />
					<br />
					<Button danger type="primary" onClick={() => modalConfirm()}>
						Delete
					</Button>
				</Modal>
			</>
		);
	}
	return null;
};

export default ResolveWalletIssues;
