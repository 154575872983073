import { Badge, Button, Card, Divider, message, Modal, Space, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Web3 from 'web3';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import FullLayout from '../../layouts/layout-components/FullLayout';
import { getRequests } from '../../methods/users';
import { Country, shortAmount, trustScoreColor } from '../../methods/utils';
import { toFixed } from '../../methods/web3/withdraw';
import ReferralDetailTable from '../views/userProfile/ReferralDetailTable';
import UserPlans from './UserPlans';
import UserRecentTable from '../views/userProfile/UserRecentTable';
import BusdIcon from '../../binance-usd-busd-logo.svg';
import XendIcon from '../../xend.svg';
import CallRemittance from './CallRemittance';
import ResolveWalletIssues from './ResolveWalletIssue';
import baseuri from '../../methods/baseuri';
import UpdateTrustScore from './UpdateTrustScore';

const ProfileStyle = styled.section`
	display: grid;
	width: 100%;

	& .name {
		display: grid;
		grid-template-columns: 100px 1fr;
	}

	& .balances p {
		font-weight: bold;
	}

	& .balances > div {
		/* border: 1px solid gray; */
		padding: 5px;
		margin-bottom: 10px;

		& > div {
			display: flex;
			align-items: center;

			& > * {
				display: flex;
				margin-right: 20px;

				& > * {
					margin-right: 10px;
				}
			}
		}
	}

	& .last-login {
		display: flex;
		align-items: center;
		& > * {
			margin: 0 10px 0 0;
		}
		& .phone > div:first-child {
			display: flex;
			align-items: center;
		}
	}

	& .phone-details {
		display: flex;
		margin-top: 10px;
		gap: 1em;
		& > * {
		}
	}
`;
const KycSection = styled.p`
	display: flex;
	gap: 2em;
`;

const UserProfile = () => {
	const { authRole } = useSelector((store: any) => store.auth);
	const { id } = useParams<{ id: string }>();

	const [details, setDetails] = useState<any>({});
	const [userTransactions, setUserTransactions] = useState<Array<any>>([]);
	const [agentType, setAgentType] = useState([] as any);

	useEffect(() => {
		getRequests(`admin/search-details?id=${id}`).then((user: any) => {
			setDetails(user?.data);
		});
		getRequests(`admin/get-user-usertypes?userId=${id}`).then((res: any) => {
			const k: any = [];
			res?.data?.map((n: any) => {
				k.push(n.usertype?.name);
				return n;
			});
			setAgentType(k);
		});
		getRequests(`admin/user-wallet-transactions?id=${id}&pageSize=30`).then(
			(user: any) => {
				setUserTransactions(user?.data?.records);
			}
		);
	}, []);

	const onthefly = async (
		userId: string,
		cryptoBalance: number,
		currency: number
	) => {
		try {
			const deposits = await userTransactions.filter(
				(x: any) =>
					x.typeOfTransaction === 'funding' &&
					x.userId === userId &&
					x.currency === currency
			);
			const withdrawals = await userTransactions.filter(
				(x: any) =>
					x.typeOfTransaction === 'withdraw' &&
					x.userId === userId &&
					Number(x.currency) === currency
			);

			// const deposits = tnx.filter(x => x.typeOfTransaction === "funding" && x.userId == userId && x.currency == currency);
			// const withdrawals = tnx.filter(x => x.typeOfTransaction === "withdraw" && x.userId == userId && x.currency == currency);
			// console.log(deposits)
			// console.log(withdrawals)

			const fundings = deposits.filter((x: any) => x.isActive !== false);

			const fundingAmounts: number[] = [];
			for (let i = 0; i < fundings.length; i++) {
				const current = fundings[i];

				if (
					Web3.utils.isAddress(current.fromAddress) &&
					Web3.utils.isAddress(current.toAddress)
				) {
					fundingAmounts.push(Number(current.amount));
				} else {
					fundingAmounts.push(Number(current.finalAmount));
				}
			}

			const depositSum = fundingAmounts.reduce((a, b) => a + b, 0);
			const withdrawSum = withdrawals
				.filter((x: any) => x.isActive !== false)
				.reduce((acc: any, y: any) => acc + Number(y.amount), 0);

			// console.log(depositSum, withdrawSum, ' the onthefly')

			const difference = depositSum - withdrawSum;

			let tolerance = Math.abs(difference - Number(cryptoBalance));
			tolerance = shortAmount(toFixed(tolerance));

			// console.log(tolerance, " the tolerance");
			return tolerance > 5;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	useEffect(() => {
		onthefly(id, details?.balance, 1);
	}, [userTransactions, details]);

	const badgeColor = (profileComplete: boolean, deactivated: boolean) => {
		if (profileComplete && !deactivated) {
			return '#87d068';
		}
		return 'red';
	};

	const deactivate2fa = async () => {
		try {
			const res: any = await baseuri.put(`admin/deactivate-user-two-factor`, {
				userId: id,
			});

			if (res.status === 200) {
				message.success('2fa deactivated successfully');
			} else {
				message.info(res.data.message);
			}
		} catch (e: any) {
			message.error(e.response.data.data.message);
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: `Are you sure you want to REMOVE ${
				details?.profile?.username || 'this user'
			}'s 2fa ?`,
			onOk() {
				deactivate2fa();
			},
		});
	};

	return (
		<FullLayout pageTitle="Profile">
			<div>
				<Card>
					<ProfileStyle>
						<div className="name">
							<div>
								<Badge
									dot
									color={badgeColor(
										details?.profile?.hasCompletedProfile,
										details?.profile?.deactivated
									)}
								>
									{details?.profile?.profileUrl ? (
										<Avatar src={details?.profile?.profileUrl} size={60} />
									) : (
										<Avatar icon={<UserOutlined />} size={60} />
									)}
								</Badge>
							</div>
							<div>
								<p>{details?.profile?.fullName}</p>
								<p>
									<strong>{details?.profile?.email}</strong>
								</p>
								<p>
									<strong>@{details?.profile?.username}</strong>
								</p>
								<p>{details?.profile?.phoneNo}</p>
								<p>
									Created: {moment(details?.profile?.createdAt).format('lll')}
								</p>
							</div>
						</div>
						<div>
							<p style={{ display: 'flex', alignItems: 'center' }}>
								<span>2FA</span>
								{details?.profile?.enabledTwoFactor ? (
									<Space>
										<i
											className="material-icons"
											style={{ color: 'green', fontSize: 18 }}
										>
											privacy_tip
										</i>

										<Button size="small" onClick={() => confirm()}>
											Remove 2fa
										</Button>
									</Space>
								) : (
									<i
										className="material-icons"
										style={{ color: 'gray', fontSize: 18 }}
									>
										privacy_tip
									</i>
								)}
							</p>
							{details?.profile && (
								<>
									<p>
										<strong>User Role: </strong>
										<span>{details?.profile?.isStaff ? 'Staff' : 'User'}</span>
									</p>
									<p>
										<strong>User Types: </strong>
										<span>{agentType?.join(', ') || 'NOT AN AGENT'}</span>
									</p>
									<KycSection>
										<span>
											<strong>KYC Level2 Status: </strong>
											{/* <span>
												{details?.profile?.kycLevelTwoStatus.toUpperCase() ||
													'N/A'}
											</span> */}
											<span>
												{details?.profile?.kycLevelTwoStatus === 'submitted' ||
												details?.profile?.kycLevelTwoStatus === 'approved' ||
												details?.profile?.kycLevelTwoStatus === 'rejected' ? (
													<Link to={`/manage-kyc/${details?.profile?._id}`}>
														{' '}
														KYC Data(
														{details?.profile?.kycLevelTwoStatus.toUpperCase()})
													</Link>
												) : (
													<span>
														{details?.profile?.kycLevelTwoStatus.toUpperCase()}
													</span>
												)}
											</span>
										</span>
									</KycSection>
									<p>
										<strong>KYC Level3 Status: </strong>
										<span>
											{details?.profile?.kycLevelThreeStatus.toUpperCase() ||
												'N/A'}
										</span>
									</p>
								</>
							)}
							{details?.profile?.trustScore && (
								<KycSection
									style={{
										color: `${trustScoreColor(
											Number(details?.profile?.trustScore)
										)}`,
									}}
								>
									<strong>
										Trust Score : {details?.profile?.trustScore || 0}/100
									</strong>
									<UpdateTrustScore details={details} setDetails={setDetails} />
								</KycSection>
							)}
						</div>

						<Divider />

						<div className="wallet">
							<p>Wallet Balances</p>
							<div className="balances">
								{details?.balances?.map((balance: any, position: number) => (
									<div key={Math.random() * 23}>
										<div>
											<div>{balance?.name}</div>
											<div>
												<img src={BusdIcon} alt="" width={20} />
												<p>{balance?.busd} BUSD</p>
											</div>
											<div>
												<img src={XendIcon} alt="" width={20} />
												<p>{Number(balance?.xend)} XEND</p>
											</div>
										</div>
										<div>
											<ResolveWalletIssues
												walletPosition={position}
												wallets={details.wallets}
											/>
										</div>
									</div>
								))}
							</div>
							<Divider />
							<p>Wallet Addresses</p>
							{details?.addresses?.map((item: any) => (
								<p>
									<a
										target="_blank"
										href={`https://bscscan.com/address/${item.address}`}
										rel="noreferrer"
									>
										{item.address}
									</a>
								</p>
							))}
							<br />
							<CallRemittance
								username={details?.profile?.username}
								walletAddresses={details?.addresses}
								userId={id}
							/>

							<Divider />
						</div>
						<div className="login-history">
							<div className="last-login">
								<span>Registration:</span>
								<div className="phone">
									<div>
										<i className="material-icons" style={{ fontSize: 16 }}>
											phone_iphone
										</i>
										<span>
											{details?.profile?.registrationDeviceDetails?.type}
										</span>
									</div>
								</div>
								<a
									target="_blank"
									// href={`https://www.latlong.net/c/?lat=${details?.profile?.registrationCoordinates?.lat}&long=${details?.profile?.registrationCoordinates?.long}`}
									href={`https://maps.google.com/maps?f=q&q=${details?.profile?.registrationCoordinates?.lat},${details?.profile?.registrationCoordinates?.long}`}
									rel="noreferrer"
								>
									View Location
								</a>
							</div>
							{authRole?.name === 'Super Admin' && (
								<div className="phone-details">
									<p>
										Build:{' '}
										{details?.profile?.registrationDeviceDetails?.build ||
											'N/A'}
										,
									</p>
									<p>
										Description:{' '}
										{details?.profile?.registrationDeviceDetails?.description ||
											'N/A'}
										,
									</p>
									<p>
										Serial:{' '}
										{details?.profile?.registrationDeviceDetails?.serial ||
											'N/A'}
										,
									</p>
									<p>
										Country:{' '}
										{Country(
											details?.profile?.registrationDeviceDetails?.country
										) || 'N/A'}
										,
									</p>
									{/* <p>
										IP:
										<Tag>
											{details?.userActivities?.lastLoginLocation?.xRealIp}
										</Tag>
									</p> */}
								</div>
							)}
						</div>
						<Divider />
						<div className="login-history">
							<div className="last-login">
								<span>
									Last Login:{' '}
									{moment(details?.userActivities?.lastLogin).format('lll')}
								</span>
								<div className="phone">
									<div>
										<i className="material-icons" style={{ fontSize: 16 }}>
											phone_iphone
										</i>
										<span>
											{details?.userActivities?.lastLoginLocation?.deviceType}
										</span>
									</div>
								</div>
								<a
									target="_blank"
									// href={`https://www.latlong.net/c/?lat=${details?.userActivities?.lastLoginLocation?.locationCoordinates?.lat}&long=${details?.userActivities?.lastLoginLocation?.locationCoordinates?.long}`}
									href={`https://maps.google.com/maps?f=q&q=${details?.userActivities?.lastLoginLocation?.locationCoordinates?.lat},${details?.userActivities?.lastLoginLocation?.locationCoordinates?.long}`}
									rel="noreferrer"
								>
									View Location
								</a>
							</div>
							{authRole?.name === 'Super Admin' && (
								<div className="phone-details">
									<p>
										Build:{' '}
										{details?.userActivities?.lastLoginLocation?.userDevice
											?.build || 'N/A'}
										,
									</p>
									<p>
										Description:{' '}
										{details?.userActivities?.lastLoginLocation?.userDevice
											?.description || 'N/A'}
										,
									</p>
									<p>
										Serial:{' '}
										{details?.userActivities?.lastLoginLocation?.userDevice
											?.serial || 'N/A'}
										,
									</p>
									<p>
										TimeZone Country:{' '}
										{Country(
											details?.userActivities?.lastLoginLocation?.userDevice
												?.country
										) || 'N/A'}
										,
									</p>
									<p>
										IP Country:{' '}
										{Country(
											details?.userActivities?.lastLoginLocation?.cfIpCountry
										) || 'N/A'}
										,
									</p>
									<p>
										IP:{' '}
										<Tag>
											{details?.userActivities?.lastLoginLocation?.xRealIp ||
												'N/A'}
										</Tag>
									</p>
								</div>
							)}
						</div>
					</ProfileStyle>

					<Divider />
					<ReferralDetailTable
						referralCount={details?.profile?.referralCount}
						signupDate={details?.profile?.createdAt}
						refereeName={details?.profile?.refereeName}
						username={details?.profile?.username}
					/>

					<Divider />
					<div>
						<p>Transactions</p>
						<UserRecentTable userId={id} />
					</div>

					<Divider />
					<div>
						<p>Savings Plans</p>
						<UserPlans userId={id} />
					</div>
				</Card>
			</div>
		</FullLayout>
	);
};

export default UserProfile;
