import { Button, Divider, Input, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { logout } from '../../methods/authenticate';
import baseuri from '../../methods/baseuri';

const StepsToEnable = styled.div`
	display: inline-block;
	padding: 20px;
	width: 100%;
	max-width: 500px;
	margin: auto;
	border: 1px solid #ccc;

	& .qrcode {
		display: block;
		margin: auto;
		border: 1px solid red;
		padding: 10px;
	}

	& p {
		font-size: 16px;
		margin-bottom: 3px;
		line-height: 1.2;
	}
`;

const Enable2FA = () => {
	const [startLoading, setStartLoading] = useState(false);
	const [views, setViews] = useState<'enable' | 'setenable'>('enable');

	const [enableData, setEnableData] = useState({
		qr: '',
		secret: '',
		code: '',
		password: '',
	});

	const start2FAEnabling = async () => {
		try {
			setStartLoading(true);

			const res: any = await baseuri.post('/auth/2fa/start/enable');

			if (res.status === 200) {
				//
				setEnableData({ ...enableData, ...res.data.data.data });
				setViews('setenable');
			}

			setStartLoading(false);
		} catch (e) {
			setStartLoading(false);
		}
	};

	const sendEnable = async () => {
		try {
			setStartLoading(true);
			const res = await baseuri.post('/auth/2fa/create/enable', {
				secret: enableData.secret,
				code: enableData.code,
				password: enableData.password,
			});

			if (res.status === 200) {
				//

				message.success('Your 2FA has been activated. Login to continue.');

				setTimeout(() => {
					logout();
				}, 2000);
			}
			setStartLoading(false);
		} catch (e) {
			setStartLoading(false);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
				padding: 20,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<StepsToEnable>
				{views === 'enable' && (
					<div>
						<h2>Enable two-step verification</h2>
						<p>For added security</p>
						<br />
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>
								<Button
									type="primary"
									loading={startLoading}
									disabled={startLoading}
									onClick={() => start2FAEnabling()}
								>
									Enable 2FA
								</Button>
							</div>
							<div>
								<Button onClick={() => logout()} type="primary" danger>
									Logout
								</Button>
							</div>
						</div>
					</div>
				)}

				{views === 'setenable' && (
					<>
						<h2>Enable two-step verification</h2>
						<p>
							An authenticator app lets you generate security codes on your
							phone without needing to receive text messages. If you don&apost
							already have one, you can download Google Authenticator on your
							phone.
						</p>

						<br />

						<div>
							<img src={enableData.qr} alt="qrcode" className="qrcode" />
							<p style={{ textAlign: 'center' }}>Scan QR Code</p>
						</div>

						<Divider />

						<div>
							<p>Auth code</p>
							<Input
								placeholder="Enter the code for Authenticator app"
								value={enableData.code}
								onChange={(e) =>
									setEnableData({ ...enableData, code: e.target.value })
								}
							/>
						</div>
						<br />
						<div>
							<p>Password</p>
							<Input.Password
								placeholder="Enter your password"
								value={enableData.password}
								onChange={(e) =>
									setEnableData({ ...enableData, password: e.target.value })
								}
							/>
						</div>

						<br />

						<div>
							<div>
								<Button
									loading={startLoading}
									disabled={startLoading}
									onClick={() => sendEnable()}
									type="primary"
								>
									Enable
								</Button>
							</div>
						</div>
					</>
				)}
			</StepsToEnable>
		</div>
	);
};

export default Enable2FA;
