import { Col, Card, Table } from 'antd';

const GrowthReportsTable = () => {
	const columns = [
		{
			title: 'Week No.',
			dataIndex: 'week_number',
		},
		{
			title: 'Week Duration',
			dataIndex: 'week_duration',
		},
		{
			title: 'No. Of Transactions',
			dataIndex: 'transactions_number',
		},
		{
			title: 'Transaction Volume',
			dataIndex: 'transactions_volume',
		},
		{
			title: 'Net Increase/Decrease',
			dataIndex: 'net',
		},
		{
			title: '% Increase/Decrease',
			dataIndex: 'percentage',
		},
	];
	const data = [
		{
			key: '1',
			week_number: 'week 1',
			week_duration: 'sun Dec 04 2021  - sun Dec 11 2021',
			transactions_number: '96',
			transactions_volume: '$  475648576',
			net: '7576987',
			percentage: '56',
		},
	];

	return (
		<Col span={24}>
			<Card
				bordered={false}
				style={{
					boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
					borderRadius: '10px',
				}}
			>
				<Table columns={columns} dataSource={data} size="small" />
			</Card>
		</Col>
	);
};

export default GrowthReportsTable;
