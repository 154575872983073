import FullLayout from '../../../layouts/layout-components/FullLayout';
import AppGreetings from './AppGreetings';

const Greetings = () => {
	return (
		<FullLayout pageTitle="Greetings">
			<AppGreetings />
		</FullLayout>
	);
};

export default Greetings;
