import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FullLayout from '../../layouts/layout-components/FullLayout';
import { getRequests } from '../../methods/users';
// import { getRole } from "../../../methods/utils";
// import BalanceCard from "./BalanceCard";
import CustodialWallets from './CustodialWallets';
// import GrowthStatistics from "./GrowthStatistics";
// import NewUsersStatistics from "./NewUsersStatistics";
// import TransactionStatistics from "./TransactionStatistics";
import UsersStatCard from '../views/Dashboard/UsersStatCard';
import XendUsersBalance from './XendUsersBalance';
import PlansBalance from './PlansBalance';
import TotalWalletBalance from './TotalWalletBalance';

const AdminInfo = styled.section`
	margin-top: 20px;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(2, auto);

	& .custodial {
		grid-column: 1 / -1;
	}
	& .plans {
		grid-column: 2 / span 1;
	}
	& .xend-user {
		grid-column: 3 / span 1;
	}
	& .wallet-balances {
		grid-column: 1 / span 1;
	}
`;

const Dashboard = () => {
	const { authRole } = useSelector((store: any) => store.auth);
	const [data1, setData1] = useState({} as any);

	useEffect(() => {
		getRequests('admin/dashboard').then((res: any) => {
			setData1(res?.data);
		});
	}, []);

	return (
		<FullLayout pageTitle="Home">
			<div>
				<UsersStatCard data={data1} />
			</div>

			{(authRole?.name === 'Super Admin' ||
				authRole?.name === 'Compliance') && (
				<AdminInfo>
					<div className="custodial">
						<CustodialWallets />
					</div>
					{/* <OriginWallet /> */}
					<Card className="wallet-balances">
						<TotalWalletBalance />
					</Card>
					<Card className="plans">
						<PlansBalance />
					</Card>
					<Card className="xend-user">
						<XendUsersBalance />
					</Card>
				</AdminInfo>
			)}
		</FullLayout>
	);
};

export default Dashboard;
