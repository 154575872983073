import { Button, message, Modal, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import { getRequests } from '../../../methods/users';
import EditRoles from './EditRoles';
import ModifyRole from './ModifyRole';

interface IDeleteRole {
	roleId: string;
	// removed: any;
}
const DeleteRole: FC<IDeleteRole> = ({ roleId }) => {
	const [loading, setloading] = useState(false);

	const removeRoleRecord = async () => {
		try {
			setloading(true);
			const res = await baseuri.delete(`/admin/roles/${roleId}`);
			if (res.status === 200) {
				setloading(false);
				message.success('Role deleted successfully');
			}
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete role?',
			onOk() {
				removeRoleRecord();
			},
		});
	};

	return (
		<Button
			danger
			shape="round"
			size="small"
			loading={loading}
			onClick={confirm}
		>
			Delete Role
		</Button>
	);
};

const tableColumns = [
	{
		title: 'Roles',
		//   dataIndex: "role",
		render: (text: any) => (
			<>
				{/* <EditRoles roles={text.title} /> */}
				<p
					style={{
						fontSize: '0.8rem',
						color: '#969696',
						marginTop: 5,
					}}
				>
					{text?.title}
				</p>
			</>
		),
	},
	{
		title: '',
		dataIndex: 'permissions',
		render: (text: any) => (
			<>
				<EditRoles permissions={text} />
				<p
					style={{
						fontSize: '0.8rem',
						color: '#969696',
						marginTop: 5,
					}}
				>
					Click view permissions
				</p>
			</>
		),
	},
	{
		title: '',
		render: (text: any) => <ModifyRole details={text} />,
	},
	//   {
	//     title: "",
	//     render: () => <Button size="small">Deactive</Button>,
	//   },
	{
		title: '',
		dataIndex: '_id',
		render: (text: any) => <DeleteRole roleId={text} />,
	},
];

const RolesTable = () => {
	const [roleList, setRoleList] = useState([] as any);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		getRequests('admin/roles?page=1&pageSize=10').then((res: any) => {
			setRoleList(res?.data);
			setLoading(false);
		});
	}, []);
	return (
		<div>
			<Table
				loading={loading}
				columns={tableColumns}
				dataSource={roleList?.records}
			/>
		</div>
	);
};

export default RolesTable;
