import baseuri from './baseuri';

export const getTransactions = async (
	query: any,
	startDate: any,
	endDate: any,
	updateState: Function,
	setLoading: Function
) => {
	try {
		setLoading(true);
		const res: any = await baseuri.get('/admin/transactions', {
			params: { ...query, start: startDate, end: endDate },
		});
		const { data } = res.data;

		updateState({ ...data, loading: false });
	} catch (e) {
		console.error(e);
		setLoading(false);
	}
};

export const enum2Currency = (value: any) => {
	switch (Number(value)) {
		case 1:
			return 'BUSD';
		case 2:
			return 'XEND';
		default:
			return '';
	}
};

export const enum2PlanSavingStatus = (value: any) => {
	switch (Number(value)) {
		case 0:
			return 'Pending';
		case 1:
			return 'Done';
		case 2:
			return 'Cancelled';
		default:
			return '';
	}
};
export const enum2PlanWithdrawalStatus = (value: any) => {
	switch (Number(value)) {
		case 0:
			return 'NOT_WITHDRAWN';
		case 1:
			return 'PROCESSING';
		case 2:
			return 'DONE';
		case 3:
			return 'FAILED';
		default:
			return '';
	}
};
// UNKNOWN = 1,
// 	INCOMPLETE,
// 	COMPLETE
export const enum2ProcessStatus = (value: any) => {
	switch (Number(value)) {
		case 0:
			return 'Unknown';
		case 1:
			return 'INCOMPLETE';
		case 2:
			return 'COMPLETED';
		default:
			return '';
	}
};
// Claim,
// 	Unverified,
// 	Completed
export const referralStatus = (value: any) => {
	switch (Number(value)) {
		case 0:
			return {
				text: 'To be claimed',
				enumValue: Number(value),
				color: 'orange',
			};
		case 1:
			return { text: 'Unverified', enumValue: Number(value), color: 'gray' };
		case 2:
			return { text: 'Completed', enumValue: Number(value), color: 'green' };
		default:
			return { text: '', enumValue: Number(value), color: 'gray' };
	}
};

export const enum2TxnType = (value: any) => {
	switch (Number(value)) {
		case 0:
			return 'Funding';
		case 1:
			return 'Withdraw';
		case 2:
			return 'Send Funds';
		case 3:
			return 'Receive Funds';
		case 4:
			return 'Swap';
		case 5:
			return 'Save';
		case 6:
			return 'Fixed Savings';
		case 7:
			return 'Fixed Savings Withdrawal';
		case 8:
			return 'Flexible Savings';
		case 9:
			return 'Flexible Savings Withdrawal';

		default:
			return '';
	}
};
