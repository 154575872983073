/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Input, message, Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import baseuri from '../methods/baseuri';

const FormStyle = styled.section`
	padding: 20px;
	margin: 20px auto;
	width: 100%;
	max-width: 500px;
`;

const UpdatePassword = () => {
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		currentPassword: '',
		password: '',
		confirmPassword: '',
	});

	const handleInput = (e: any) => {
		e.preventDefault();
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const submitForm = async (e: any) => {
		e.preventDefault();
		try {
			setLoading(true);
			const res: any = await baseuri.put('admin/password/update', state);

			if (res.status === 200) {
				// save token
				//   saveToken(res.data.data);
				//   createBrowserHistory().push("/");
				//   createBrowserHistory().go(0);
			}

			setLoading(false);
		} catch (err: any) {
			setLoading(false);
			console.error(err);
			message.error(`Update Password. ${err.response?.data.data.message}`);
		}
		// e.preventDefault();

		// dispatch(adminLogin({
		// 	email: state.email,
		// 	password: state.password
		// }, setLoading))
		// setState({ email: '', password: '' })
	};

	return (
		<FormStyle>
			<div>
				<h1>UPDATE YOUR PASSWORD </h1>
			</div>

			<br />
			<br />

			<form onSubmit={submitForm}>
				<Space direction="vertical" size={20} style={{ width: '100%' }}>
					<div>
						<label htmlFor="currentPassword">Current Password</label>
						<Input
							name="currentPassword"
							type="currentPassword"
							required
							size="large"
							value={state.currentPassword}
							onChange={handleInput}
						/>
					</div>
					<div>
						<label htmlFor="password">New Password</label>
						<Input.Password
							name="password"
							required
							type="password"
							size="large"
							value={state.password}
							onChange={handleInput}
						/>
					</div>
					<div>
						<label htmlFor="">Confirm Password</label>
						<Input.Password
							name="confirmPassword"
							required
							type="confirmPassword"
							size="large"
							value={state.confirmPassword}
							onChange={handleInput}
						/>
					</div>
					<div>
						<Button
							htmlType="submit"
							loading={loading}
							block
							type="primary"
							size="large"
						>
							Submit
						</Button>
					</div>
				</Space>
			</form>
		</FormStyle>
	);
};

export default UpdatePassword;
