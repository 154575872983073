/* eslint-disable eqeqeq */
import Web3 from 'web3';
import Notify from 'bnc-notify';
import createContract from './create-contract';
import baseuri from '../baseuri';

export const waitTime = async (minutes: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, minutes * 60 * 1000);
	});
};

export const formatAmount = (
	amount: any,
	network: number,
	assetName: string
) => {
	if (network == 56) {
		return Web3.utils.toWei(amount, 'ether');
	}
	if (network == 137) {
		if (assetName === 'WBTC') {
			return parseFloat(amount) * 10 ** 8;
		}
		if (assetName === 'AAVE') {
			return Web3.utils.toWei(amount, 'ether');
		}
		return Web3.utils.toWei(amount, 'mwei');
	}
	return amount;
};

export const getDappId = (network: number) => {
	switch (network) {
		case 56:
			return 'a7f90c48-943a-4d3a-a8df-6ca5d0f7522a';
		case 137:
			return 'a7f90c48-943a-4d3a-a8df-6ca5d0f7522a';
		default:
			return '';
	}
};

interface IDeposit {
	amount: number;
	processListId: string;
	asset: any;
	client: string;
	planIdAndType: string[];
	sendData: Function;
	successData: Function;
}
export const depositSavings = (data: IDeposit) => {
	return async () => {
		const {
			sendData,
			planIdAndType,
			asset,
			client,
			amount,
			successData,
			processListId,
		} = data;

		try {
			const notifyBNC = Notify({
				dappId: getDappId(asset.network),
				networkId: asset.network,
				mobilePosition: 'bottom',
				desktopPosition: 'bottomRight',
			});

			sendData({
				activity: 'Process started. Plans accounted for.',
				percent: 10,
				status: 'normal',
			});

			const token = await createContract(asset.tokenAbi, asset.tokenAddress);
			const contract = await createContract(
				asset.protocolAbi,
				asset.protocolAddress
			);

			let allowance = await token.methods
				.allowance(client, asset.protocolAddress)
				.call();

			allowance = Web3.utils.fromWei(allowance, 'ether');

			sendData({ activity: 'Check allowance', percent: 20, status: 'normal' });

			if (Number(allowance) <= Number(amount)) {
				const approveAmount = Web3.utils.toWei('100000', 'ether');

				await token.methods
					.approve(asset.protocolAddress, approveAmount)
					.send({ from: client })
					.on('transactionHash', (hash: any) => {
						notifyBNC.hash(hash);
					});
			}

			sendData({
				activity: 'Deposit allowance done',
				percent: 30,
				status: 'normal',
			});

			sendData({
				activity: 'Getting initial share balance',
				percent: 40,
				status: 'normal',
			});

			const ppfs = await contract.methods[asset.ppfsMethod]().call();

			const initShares = await contract.methods.balanceOf(client).call();

			// deposit
			sendData({
				activity: 'Starting deposit...',
				percent: 50,
				status: 'normal',
			});
			const receipt = await contract.methods
				.deposit(formatAmount(String(amount), asset.network, asset.name))
				.send({ from: client })
				.on('transactionHash', async (hash: any) => {
					notifyBNC.hash(hash);
				});

			sendData({
				activity: 'Deposit Done. Transaction hash received',
				percent: 60,
				status: 'normal',
			});

			await waitTime(1);

			sendData({
				activity: 'Getting shares balance after deposit',
				percent: 70,
				status: 'normal',
			});
			const finalShares = await contract.methods.balanceOf(client).call();

			const sending = {
				transactionHash: receipt.transactionHash,
				action: 'deposit',
				planIdAndType,
				pricePerFullShare: ppfs,
				initShares,
				finalShares,
				protocolName: 'xAuto',
				network: 56,
				amount,
				gasUsed: receipt.gasUsed,
				block: receipt.blockNumber,
				processListId,
			};

			baseuri
				.post('/advanced/save-batch', sending)
				.then((res: any) => {
					if (res.status == 200) {
						sendData({
							activity: 'Saved batch details',
							percent: 100,
							status: 'success',
						});
						// then trigger click button to process the rest
						successData(res.data.data);
					} else {
						sendData({
							activity: res.data.message,
							percent: 90,
							status: 'exception',
						});
					}
				})
				.catch((e) => {
					console.error(e);

					// save these details to local storage. so we don't loose it. and can try again
					sendData({
						activity: 'Could not send batch details to server',
						percent: 90,
						status: 'exception',
					});
				});
		} catch (e) {
			console.error(e);
			sendData({ status: 'exception' });
		}
	};
};
