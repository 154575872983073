import { Input, Table } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';

const SearchInputWrapper = styled.section`
	max-width: 700px;
	text-align: center;
	padding-top: 50px;
	margin: auto;
`;

const UserAdvancedSearch = () => {
	const history = useHistory();
	const params: any = useParams();
	const { search } = params;
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState<string>();

	const [result, setResult] = useState<Array<any>>([]);

	const globalSearch = async () => {
		if (!search) return;

		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/user-global-Search', {
				params: { search_text: search },
			});
			if (res.status === 200) {
				setResult(res.data.data);
			}
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		globalSearch();
	}, [search]);

	const columns = [
		{
			title: '',
			dataIndex: 'profileUrl',
			render: (link: any) => <Avatar src={link} />,
		},
		{
			title: 'Name / Email',
			dataIndex: 'email',
			render: (text: any, other: any) => (
				<>
					<p>{other.fullName}</p>
					<p>{text}</p>
				</>
			),
		},
		{
			title: 'Username',
			dataIndex: 'username',
			render: (text: any) => text && `@${text}`,
		},
		{
			title: 'Referred By',
			dataIndex: 'refereeName',
			render: (text: any) => text && `@${text}`,
		},
		{
			title: 'Registration',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: '',
			dataIndex: '_id',
			render: (text: any, other: any) => (
				<Link title="View Profile" to={`/user-profile/${other._id}`}>
					View Profile
				</Link>
			),
		},
	];

	return (
		<FullLayout pageTitle="User Advanced Search">
			<SearchInputWrapper>
				<p>Search User Collection</p>
				<Input.Search
					loading={loading}
					disabled={loading}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					onSearch={() => history.push(`/user-search/${searchText}`)}
					enterButton
					size="large"
					placeholder="Search by text"
				/>
			</SearchInputWrapper>

			<br />

			<div>
				<p style={{ marginBottom: 15 }}>Search Result</p>
				<Table dataSource={result} columns={columns} pagination={false} />
			</div>
		</FullLayout>
	);
};

export default UserAdvancedSearch;
