import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { getRequests } from '../../../methods/users';

interface ISelectPermission {
	permissions: any;
	setPermissions: Function;
}

const SelectPermissions: FC<ISelectPermission> = ({
	permissions,
	setPermissions,
}) => {
	//   const [selectedItems, setSelectedItems] = useState([] as any);
	const [permissionList, setPermissionList] = useState([] as any);

	const filteredOptions = Object.values(permissionList)?.filter(
		(o: any) => !permissions?.includes(o)
	);

	useEffect(() => {
		getRequests('admin/permissions').then((res: any) => {
			setPermissionList(res?.data?.permissions);
		});
	}, []);

	return (
		<Select
			mode="multiple"
			placeholder="Inserted are removed"
			value={permissions}
			onChange={(e: any) => setPermissions(e)}
			style={{ width: '100%' }}
		>
			{filteredOptions.map((item: any) => (
				<Select.Option key={item} value={item}>
					{item}
				</Select.Option>
			))}
		</Select>
	);
};

export default SelectPermissions;
