/* eslint-disable react/jsx-props-no-spreading */
import { Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const AdminUpdateForm = () => {
	const [form] = Form.useForm();

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 100 }}>
				<Option value="234">+234</Option>
			</Select>
		</Form.Item>
	);

	return (
		<Form
			{...formItemLayout}
			form={form}
			name="register"
			initialValues={{
				prefix: '234',
			}}
			scrollToFirstError
		>
			<Form.Item
				name="userName"
				label="UserName"
				// tooltip="What do you want others to call you?"
				rules={[
					{
						// required: true,
						message: 'Please input your username!',
						whitespace: true,
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				name="email"
				label="E-mail"
				rules={[
					{
						type: 'email',
						message: 'The input is not valid E-mail!',
					},
					{
						required: true,
						message: 'Please input your E-mail!',
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				name="currentPassword"
				label="Current Password"
				rules={[
					{
						required: true,
						message: 'Please input your current password!',
					},
				]}
				hasFeedback
			>
				<Input.Password />
			</Form.Item>

			<Form.Item
				name="password"
				label="Password"
				rules={[
					{
						// required: true,
						message: 'Please input your password!',
					},
				]}
				hasFeedback
			>
				<Input.Password />
			</Form.Item>

			<Form.Item
				name="confirm"
				label="Confirm Password"
				dependencies={['password']}
				hasFeedback
				rules={[
					{
						// required: true,
						message: 'Please confirm your password!',
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(
								new Error('The two passwords that you entered do not match!')
							);
						},
					}),
				]}
			>
				<Input.Password />
			</Form.Item>

			<Form.Item
				name="Address"
				label="Address"
				rules={[
					{
						// type: "array",
						// required: true,
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				name="phone"
				label="Phone Number"
				rules={[{ required: true, message: 'Please input your phone number!' }]}
			>
				<Input addonBefore={prefixSelector} style={{ width: '100%' }} />
			</Form.Item>

			<Form.Item
				name="gender"
				label="Gender"
				rules={[{ required: true, message: 'Please select gender!' }]}
			>
				<Select placeholder="select your gender">
					<Option value="male">Male</Option>
					<Option value="female">Female</Option>
					<Option value="other">Other</Option>
				</Select>
			</Form.Item>

			<Form.Item {...tailFormItemLayout}>
				<Button type="primary" htmlType="submit">
					Register
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AdminUpdateForm;
