import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { getUserTypes } from '../../../methods/users';

interface ISelectUserType {
	userType: any;
	setUserType: Function;
}

const SelectUserType: FC<ISelectUserType> = ({ userType, setUserType }) => {
	const [userTypeList, setUserTypeList] = useState([] as any);

	// const filteredOptions = Object.values(userTypeList)?.filter(
	// 	(o: any) => !userType?.includes(o)
	// );

	useEffect(() => {
		getUserTypes().then((res: any) => {
			setUserTypeList(res.data);
		});
	}, []);

	const selectedType = (e: any) => {
		const selectedUserType = userTypeList.find((a: any) => a?.name === e);

		setUserType(selectedUserType);
	};

	return (
		<Select
			// mode="multiple"
			placeholder="Select user type"
			value={userType?.name}
			onChange={(e: any) => selectedType(e)}
			style={{ width: '100%', minWidth: '200px' }}
		>
			{userTypeList.map((item: any) => (
				<Select.Option key={item._id} value={item.name}>
					{item.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default SelectUserType;
