import {
	Button,
	Card,
	DatePicker,
	message,
	Pagination,
	Space,
	Table,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
// import CSVComponent from '../../_ReUsable/CSVComponent';

const HeaderExtraStyle = styled.section`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;

interface IHeaderExtra {
	setLoading: Function;
	startDate: any;
	setStartDate: Function;
	endDate: any;
	setEndDate: Function;
	handleSearchByDate: Function;
}
const HeaderExtra: FC<IHeaderExtra> = ({
	setLoading,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleSearchByDate,
}) => {
	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, []);

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date) =>
							date?.isSameOrBefore(endDate) && setStartDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={startDate}
						defaultValue={startDate}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={endDate}
						onChange={(date) =>
							date?.isSameOrAfter(startDate) && setEndDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={endDate}
					/>
				</div>
				<div>
					<Button type="primary" onClick={() => handleSearchByDate()}>
						Search
					</Button>
				</div>
			</div>
		</HeaderExtraStyle>
	);
};

const GasFeesCollected = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(moment('2021-10-01'));
	const [endDate, setEndDate] = useState(moment());
	const [GasCollected, setGasCollected] = useState({
		downloadLink: '',
		record: [],
		currentPage: 1,
		totalRecords: 0,
		totalFeesCollected: 0,
		totalPages: 0,
		pageSize: 0,
	});

	const handleGetFees = async (page = 1) => {
		setLoading(true);
		let res: any;
		try {
			res = await baseuri.get(`admin/fees-collectedby-xend`, {
				params: {
					start: startDate.format('YYYY-MM-DD'),
					end: endDate.format('YYYY-MM-DD'),
					page,
				},
			});

			if (res.status === 200) {
				setGasCollected({ ...GasCollected, ...res.data.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};

	useEffect(() => {
		setLoading(true);
		handleGetFees();
	}, []);

	// createdAt: '2022-02-21T17:14:39.673Z';
	// 	currency: 'xend';
	// 	description: 'percent strategy';
	// 	discount: '50';
	// 	discountApplied: false;
	// 	fee: '0.025';
	// 	finalAmount: '4.975';
	// 	transactionAmount: '5';
	// 	updatedAt: '2022-02-21T17:14:39.673Z';
	// 	user: '61aaa62727b4654cb96abb1e';
	// 	__v: 0;
	// 	_id: '6213c87f2a42f6f03bcb9d56';

	const columns = [
		{
			title: 'Transaction Amount',
			key: 'transactionAmount',
			render: (text: any) => (
				<strong>
					{text?.transactionAmount} {text?.currency}
				</strong>
			),
		},
		// {
		// 	title: 'Currency',
		// 	key: 'currency',
		// 	render: (text: any) => <strong>{text?.currency}</strong>,
		// },
		{
			title: 'Final amount',
			key: 'finalAmount',
			render: (text: any) => (
				<strong>
					{text?.finalAmount} {text?.currency}
				</strong>
			),
		},
		{
			title: 'Fee',
			key: 'fee',
			render: (text: any) => (
				<strong>
					{text?.fee} {text?.currency}
				</strong>
			),
		},
		// {
		// 	title: 'Discount Applied',
		// 	key: 'discountApplied',
		// 	render: (text: any) => <strong>{text?.unverified}</strong>,
		// },
		{
			title: 'Discount',
			key: 'discount',
			render: (text: any) => <strong>{text?.discount}%</strong>,
		},

		{
			title: 'Description',
			key: 'description',
			render: (text: any) => <strong>{text?.description}</strong>,
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: '',
			key: 'user',
			render: (text: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${text?.user}`}>
						View User
					</Link>
				</Space>
			),
		},
	];

	return (
		<Card>
			<Space direction="vertical">
				<strong>Gas Fees Collected</strong>
				<HeaderExtra
					setLoading={setLoading}
					setEndDate={setEndDate}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					handleSearchByDate={handleGetFees}
				/>
				{/* <CSVComponent
					url="admin/fees-collectedby-xend"
					startDate={startDate.format('YYYY-MM-DD')}
					endDate={endDate.format('YYYY-MM-DD')}
					otherParams={{}}
				/> */}
			</Space>

			<Card>
				<div>
					<p>
						<strong>
							Total: $
							{GasCollected?.totalFeesCollected
								? GasCollected.totalFeesCollected
								: '0'}{' '}
						</strong>
					</p>

					<Table
						loading={loading}
						columns={columns}
						dataSource={GasCollected?.record}
						pagination={false}
					/>

					<br />
					<Pagination
						onChange={handleGetFees}
						disabled={loading}
						pageSize={GasCollected.pageSize}
						current={GasCollected.currentPage}
						total={GasCollected.totalRecords}
						showSizeChanger={false}
					/>
				</div>
			</Card>
		</Card>
	);
};

export default GasFeesCollected;
