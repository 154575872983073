const reduxTypes = {
	AUTH_DETAILS: 'AUTH_DETAILS',
	DEPOSIT_QUEUES_ITEMS: 'DEPOSIT_QUEUES_ITEMS',

	FEES_RECORD: 'FEES_RECORD',
	BATCH_RECORD: 'BATCH_RECORD',

	ADDRESS: 'ADDRESS',
	AMOUNT: 'AMOUNT',
	PRISTINE: 'PRISTINE',
	NETWORK_CONNECT: 'NETWORK_CONNECT',

	PROCESSING_LIST: 'PROCESSING_LIST',
	APPEND_ITEM_PROCESSING_LIST: 'APPEND_ITEM_PROCESSING_LIST',

	USER_ROLE: 'USER_ROLE',
	SHARE_PRICES: 'SHARE_PRICES',

	LIQUIDITY_PROVIDER: 'LIQUIDITY_PROVIDER',
};

export default reduxTypes;
