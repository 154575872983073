import { Button, message, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import baseuri from '../../methods/baseuri';
import { shortAmount } from '../../methods/utils';
import { getBalances } from '../../methods/web3/amounts';
import BankDetails from './BankDetails';

interface ICallRemittance {
	username: any;
	walletAddresses: any[];
	userId: any;
}
const CallRemittance: FC<ICallRemittance> = ({
	userId,
	walletAddresses,
	username,
}) => {
	const { authRole } = useSelector((store: any) => store.auth);

	const [loading, setLoading] = useState(false);
	const [balances, setBalances] = useState({ busd: 0, xend: 0 });

	const triggerRemittance = async () => {
		setLoading(true);
		try {
			if (balances.busd > 0) {
				const res = await baseuri.post('/advanced/trigger-remittance', {
					userId,
					currency: 1,
				});
				if (res.status === 200) {
					message.success('BUSD remittance trigger done');
				}
			}

			if (balances.xend > 0) {
				const res2 = await baseuri.post('/advanced/trigger-remittance', {
					userId,
					currency: 2,
				});
				if (res2.status === 200) {
					message.success('XEND remittance trigger done');
				}
			}

			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	const getTokenBalancesInWallet = async () => {
		setLoading(true);
		try {
			const tokenBalances = [];
			for (let i = 0; i < walletAddresses.length; i++) {
				tokenBalances.push(getBalances(walletAddresses[i].address));
			}
			const tokenBalanceResult = await Promise.all(tokenBalances);

			const xend = tokenBalanceResult.reduce((a, b) => a + Number(b?.XEND), 0);
			const busd = tokenBalanceResult.reduce((a, b) => a + Number(b?.BUSD), 0);

			setBalances({ ...balances, xend, busd });
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		getTokenBalancesInWallet();
	}, [walletAddresses]);

	const disableRemitButton = () => {
		if (loading) return true;
		if (Number(balances.busd) === 0 && Number(balances.xend) === 0) return true;
		return false;
	};

	return (
		<div>
			{authRole?.name === 'Super Admin' && (
				<>
					<p style={{ marginBottom: 10 }}>
						@{username}&apos;s wallet currently has {shortAmount(balances.busd)}{' '}
						BUSD and {shortAmount(balances.xend)} XEND.
					</p>
					<Space>
						<Button
							disabled={disableRemitButton()}
							onClick={() => triggerRemittance()}
							loading={loading}
						>
							Remit Funds
						</Button>
						<BankDetails userId={userId} />
					</Space>
				</>
			)}
		</div>
	);
};

export default CallRemittance;
