import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { noauthBaseuri } from '../methods/baseuri';

const ConfirmSuperAdminAccount = () => {
	const params: any = useParams();

	const [state, setState] = useState<any>({
		loading: false,
	});

	const confirmToken = async () => {
		try {
			setState({ ...state, loading: true });
			if (params.token) {
				const res = await noauthBaseuri.post('/admin/confirm-super', null, {
					params: {
						token: params.token,
					},
				});

				if (res.status === 200) {
					setState({
						...state,
						confirmed: true,
						loading: false,
					});
				} else {
					setState({
						...state,
						confirmed: false,
						loading: false,
					});
				}
			}
		} catch (e) {
			setState({
				...state,
				confirmed: false,
				loading: false,
			});
			console.error(e);
		}
	};

	useEffect(() => {
		confirmToken();
	}, []);

	return (
		<>
			{state.loading && <>loading...</>}
			{state.confirmed && (
				<div>
					<p>Setup confirmed</p>
					<Link to="/login">Login</Link>
				</div>
			)}

			{state.confirmed === false && <div>Setup failed</div>}
		</>
	);
};

export default ConfirmSuperAdminAccount;
