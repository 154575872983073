import { Button, message } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';

interface ISendTestNewsletter {
	newsletter: any;
}
const SendTestNewsLetter: FC<ISendTestNewsletter> = ({ newsletter }) => {
	const [loading, setloading] = useState(false);

	const handleTest = async () => {
		try {
			setloading(true);
			const res: any = await axios.post(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters/draft`,
				{
					...newsletter,
					maillist: 'listTwo',
				}
			);

			if (res.status === 200) {
				setloading(false);
				message.success('Test NewsLetter sent');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};
	return (
		<div>
			<Button
				type="primary"
				shape="round"
				size="small"
				onClick={() => handleTest()}
				loading={loading}
			>
				SEND TEST
			</Button>
		</div>
	);
};

export default SendTestNewsLetter;
