import {
	Button,
	Divider,
	Input,
	notification,
	Pagination,
	Popconfirm,
	Select,
	Table,
	Tag,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
// import { format } from 'timeago.js';
import getLiquidityProviders from '../../../methods/actions/liquidity-providers';
import baseuri from '../../../methods/baseuri';
import {
	shortAmount,
	supportCurrencyEnumInfo,
	truncateAddress,
} from '../../../methods/utils';
import { transferFunds } from '../../../methods/web3/transfer-funds';
import AdvancedSectionLayout from '../../Layouts/Advanced';
import { QueueStatusStyle } from '../DepositQueue/DepositQueue';
import QueueStatuses from '../DepositQueue/QueueStatuses';
import ChangeRequestStatus from './ChangeRequestStatus';

interface IProcessMetaMask {
	amount: any;
	currency: any;
	destination: string;
	setTxnHash: Function;
}
// const DateSection = styled.div`
// 	display: flex;
// 	gap: 1em;
// `;
const ProcessMetaMaskTransfer: FC<IProcessMetaMask> = ({
	amount,
	currency,
	destination,
	setTxnHash,
}) => {
	const { address } = useSelector((store: any) => store.web3conn);
	const [loading, setLoading] = useState(false);

	const handleMetaMaskSendFunds = async () => {
		await transferFunds({
			amount,
			currency,
			retrieveHash: setTxnHash,
			destination,
			client: address,
			setLoading,
		});
	};

	return (
		<Popconfirm
			title={`You are about to send ${amount} ${currency} to an external address?`}
			onConfirm={() => handleMetaMaskSendFunds()}
		>
			<Button type="primary" loading={loading}>
				Process With MetaMask
			</Button>
		</Popconfirm>
	);
};
interface IProcessWithdrawRequest {
	recordId: string;
	inAppTransaction: boolean;
	amount: any;
	currency: any;
	destination: string;
	status: any;
	username: string;
	refresh: Function;
	receiver?: string;
}
const ProcessWithdrawRequest: FC<IProcessWithdrawRequest> = ({
	recordId,
	amount,
	inAppTransaction,
	currency,
	destination,
	status,
	username,
	refresh,
	receiver,
}) => {
	const [visible1, setVisible1] = useState(false);
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hash, setHash] = useState('');

	const processWithdrawRequest = async () => {
		try {
			setVisible(false);
			setLoading(true);

			const nameOfEndpoint = inAppTransaction
				? 'process-inApp-transaction'
				: 'process-withdraw-request';

			const res = await baseuri.post(`/admin/${nameOfEndpoint}`, {
				requestId: recordId,
				hash,
				channel: 'human',
			});
			if (res.status) {
				notification.success({
					description: 'Action',
					message: 'Process triggered',
					duration: 0,
				});
				setHash('');
				refresh();
			}

			setLoading(false);
		} catch (error) {
			notification.error({
				description: 'Action',
				message: 'Failed to process transaction',
				duration: 0,
			});
			setLoading(false);
			setHash('');
			refresh();
		}
	};

	return (
		<>
			{Number(status) === 0 && (
				<Button
					loading={loading}
					onClick={() => setVisible1(true)}
					disabled={loading}
				>
					Process Request
				</Button>
			)}
			{Number(status) === 5 && (
				<Button
					loading={loading}
					onClick={() => setVisible1(true)}
					disabled={loading}
				>
					Process Request
				</Button>
			)}
			{Number(status) === 6 && (
				<Button
					loading={loading}
					onClick={() => setVisible1(true)}
					disabled={loading}
				>
					Process Request
				</Button>
			)}

			<Modal
				visible={visible1}
				onCancel={() => setVisible1(false)}
				footer={[
					<Button
						onClick={() => {
							setHash('');
							setVisible1(false);
						}}
					>
						Cancel
					</Button>,
					<Popconfirm
						title="Are you sure?"
						onConfirm={() => {
							setVisible1(false);
							setVisible(true);
						}}
					>
						<Button type="primary">Continue</Button>
					</Popconfirm>,
				]}
				title="Process Withdrawal Transaction"
			>
				{inAppTransaction && (
					<div>
						<p>
							This is an internal / in app transaction.{' '}
							<strong>
								@{username} is sending {amount} {currency} to {receiver}
							</strong>
							.
						</p>
						<p>
							Click continue to process this transaction internally. There is no
							need for sending this fund through the blockchain.
						</p>
					</div>
				)}

				{!inAppTransaction && (
					<>
						<div>
							<p>Use MetaMask</p>
							<p>
								Use MetaMask to process this withdrawal and send funds ({amount}{' '}
								{currency}) to the destination address{' '}
								<strong>{truncateAddress(destination)}</strong>.
							</p>
							<br />
							<ProcessMetaMaskTransfer
								amount={amount}
								currency={currency}
								setTxnHash={setHash}
								destination={destination}
							/>
						</div>

						<Divider>OR</Divider>

						<p>Provide Transaction Hash</p>
						<p>
							Please provide the transaction hash for this transaction you have
							processed.
						</p>
						<br />
						<Input.TextArea
							rows={3}
							placeholder="Transaction hash"
							value={hash}
							onChange={(e) => setHash(e.target.value)}
						/>
					</>
				)}
			</Modal>

			<Modal
				title="Confirm Information"
				footer={[
					<Popconfirm
						onConfirm={() => processWithdrawRequest()}
						title="Final confirmation. Are you sure?"
					>
						<Button type="primary">Proceed</Button>
					</Popconfirm>,
					<Button onClick={() => setVisible(false)}>Cancel</Button>,
				]}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>
					You are about to process this withdrawal request of{' '}
					<strong>
						{amount} {currency}
					</strong>{' '}
					for <strong>@{username}</strong> to {destination} as completed.
					Proceed if you are sure.
				</p>
			</Modal>
		</>
	);
};

ProcessWithdrawRequest.defaultProps = {
	receiver: '',
};
interface ICancelWithdrawRequest {
	recordId: string;
	amount: any;
	currency: any;
	// destination: string;
	status: any;
}
const CancelWithdrawRequest: FC<ICancelWithdrawRequest> = ({
	recordId,
	amount,
	currency,
	status,
}) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const cancelWithdrawRequest = async () => {
		try {
			setVisible(false);
			setLoading(false);
			const res = await baseuri.put('/admin/withdrawal-request-update', {
				requestId: recordId,
				status: '3',
			});
			if (res.status) {
				notification.success({
					description: 'Action',
					message: 'Process triggered',
					duration: 0,
				});
			}

			setLoading(false);
		} catch (error) {
			console.error(error);
			notification.error({
				description: 'Action',
				message: 'Failed',
				duration: 0,
			});
			setLoading(false);
		}
	};

	return (
		<>
			{Number(status) === 0 && (
				<Popconfirm onConfirm={() => setVisible(true)} title="Are you sure?">
					<Button loading={loading} danger>
						Cancel Request
					</Button>
				</Popconfirm>
			)}

			<Modal
				title="Confirm your account"
				footer={[
					<Popconfirm
						onConfirm={() => cancelWithdrawRequest()}
						title="Final confirmation. Are you sure?"
					>
						<Button type="primary">Proceed</Button>
					</Popconfirm>,
					<Button onClick={() => setVisible(false)}>Cancel</Button>,
				]}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>
					You are about to cancel this withdrawal request and doing so will
					revert the withdrawal of {amount} {currency} back to the users
					balance.
				</p>
			</Modal>
		</>
	);
};

interface IDestination {
	address: string;
	data: any;
	setReceiver: Function;
}
const Destination: FC<IDestination> = ({ address, data, setReceiver }) => {
	const { liquidityProviders } = useSelector((store: any) => store.general);

	const [inAppUser, setInAppUser] = useState({ username: '', email: '' });

	const getUserByAddress = async () => {
		try {
			const res: any = await baseuri.get('/admin/search-user', {
				params: { search: address },
			});
			if (Number(res.status) === 200) {
				const result = res.data.data;
				if (Array.isArray(result) && result.length > 0) {
					setInAppUser({ ...inAppUser, ...result[0] });
					setReceiver(result[0].email);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const checkLiquidityProvider = () => {
		const provider = liquidityProviders.filter(
			(x: any) => x.walletAddress === address && x.isActive
		);
		return provider;
	};

	useEffect(() => {
		if (data.inAppTransaction) {
			getUserByAddress();
		}
	}, [data]);

	return (
		<div>
			<p>
				<a
					href={`https://bscscan.com/address/${address}#tokentxns`}
					target="_blank"
					rel="noreferrer"
				>
					{address}
				</a>
			</p>
			{data.inAppTransaction && (
				<p>
					<span style={{ color: '#bb3e03' }}>In App</span>: @
					{inAppUser.username} | {inAppUser.email}
				</p>
			)}

			{checkLiquidityProvider().map((provider: any) => (
				<>
					<hr style={{ opacity: 0.3 }} />
					<div style={{ fontSize: 13, color: '#343a40' }}>
						<p>Liquidity Provider: {provider.name}</p>
						<p>Updated: {provider.editedBy?.email}</p>
					</div>
				</>
			))}
		</div>
	);
};

const WithdrawOut = () => {
	const dispatch = useDispatch();

	const [records, setRecord] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [queueItemStatus, setQueueItemStatus] = useState(8);
	const [loading, setLoading] = useState(false);
	const [inAppReceiver, setInAppReceiver] = useState('');

	const fetchList = async (fetchPage = 1) => {
		try {
			setLoading(true);
			const res: any = await baseuri.get('/admin/withdrawal-requests', {
				params: { page: fetchPage, status: queueItemStatus },
			});
			const { data } = res.data;
			setRecord(data?.records);
			setPage(data?.currentPage);
			setTotalRecords(data?.totalRecords);
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchList();
		dispatch(getLiquidityProviders());
	}, []);

	useEffect(() => {
		fetchList(1);
	}, [queueItemStatus]);

	const onChange = (fetchPage: any) => {
		fetchList(fetchPage);
	};

	const columns = [
		{
			key: 0,
			title: 'Name',
			render: (x: any) => (
				<div>
					{/* <p>{x.userId?.fullName}</p>
					<p>@{x.userId?.username}</p>
					<p>{x.userId?.email}</p> */}
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${x.userId?._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{x.userId?.fullName}
						</Link>
					</p>
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${x.userId?._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{x.userId?.email}
						</Link>
					</p>
				</div>
			),
		},
		{
			key: 1,
			title: 'Balance',
			render: (x: any) => (
				<>
					<p>
						Former: {x.formerBalance}{' '}
						{supportCurrencyEnumInfo(x.currency).currency?.toUpperCase()}
					</p>
					<hr />
					<p>
						Current: {shortAmount(x.balance)}{' '}
						{supportCurrencyEnumInfo(x.currency).currency?.toUpperCase()}
					</p>
				</>
			),
		},
		{
			key: 5,
			title: 'Status',
			dataIndex: 'status',
			render: (x: any) => (
				<>
					{x === 0 && <Tag color="orange">Pending</Tag>}
					{x === 1 && <Tag color="green">Done</Tag>}
					{x === 2 && <Tag color="red">Failed</Tag>}
					{x === 3 && <Tag color="gray">Cancelled</Tag>}
				</>
			),
		},
		{
			key: 2,
			title: 'Final Amount',
			render: (x: any) => (
				<p>
					{x.finalAmount} {Number(x.currency) === 1 ? 'BUSD' : 'XEND'}
				</p>
			),
		},
		{
			key: 12,
			title: 'Fee',
			render: (x: any) => (
				<p>
					{x.fee} {Number(x.currency) === 1 ? 'BUSD' : 'XEND'}
				</p>
			),
		},
		{
			key: 13,
			title: 'Amount',
			render: (x: any) => (
				<p>
					{x.amount} {Number(x.currency) === 1 ? 'BUSD' : 'XEND'}
				</p>
			),
		},
		{
			key: 3,
			title: 'Initiator',
			render: (x: any) => (
				<>
					<p>{Number(x.initiator) === 1 ? 'User' : 'Xend Bridge'}</p>
					{x.initiator !== 1 && <p>{x.p2pRef}</p>}
				</>
			),
		},
		{
			key: 7,
			title: 'Destination',
			dataIndex: 'destinationAddress',
			render: (address: string, row: any) => (
				<Destination
					setReceiver={setInAppReceiver}
					address={address}
					data={row}
				/>
			),
		},
		{
			key: 6,
			title: 'Transaction',
			render: (x: any) => (
				<>
					{x.transactionHash && (
						<a
							href={`https://bscscan.com/tx/${x.transactionHash}`}
							target="_blank"
							rel="noreferrer"
						>
							hash
						</a>
					)}
					{!x.transactionHash && null}
				</>
			),
		},
		{
			key: 99,
			title: 'Processed By',
			dataIndex: 'processedBy',
			render: (data: any, row: any) => (
				<div>
					<p>{data?.email}</p>
					<hr />
					<p>{moment(row?.processedAt).format('MMM Do YY, h:mm:ss a')}</p>
				</div>
			),
		},
		{
			key: 994,
			title: 'Status Last Changed By',
			dataIndex: 'lastStatusChangedByUserId',
			render: (data: any) => (
				<div>
					<p>{data?.email}</p>
				</div>
			),
		},
		{
			key: 4,
			title: 'Updated',
			dataIndex: 'updatedAt',
			render: (x: any) => (
				<div>
					<p>{moment(x).format('MMM Do YY, h:mm:ss a')}</p>
					<hr />
					<p>{moment(x).fromNow()}</p>
				</div>
			),
		},
		{
			key: 49,
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => (
				<div>
					<p>{moment(x).format('MMM Do YY, h:mm:ss a')}</p>
					<hr />
					<p>{moment(x).fromNow()}</p>
				</div>
			),
		},
		{
			key: 10,
			title: '',
			render: (x: any) => (
				<ProcessWithdrawRequest
					inAppTransaction={x.inAppTransaction}
					receiver={inAppReceiver}
					refresh={fetchList}
					status={x.status}
					recordId={x._id}
					amount={x.finalAmount}
					currency={supportCurrencyEnumInfo(x.currency).currency?.toUpperCase()}
					destination={x.destinationAddress}
					username={x.userId?.username}
				/>
			),
		},
		{
			key: 11,
			title: '',
			render: (x: any) => (
				<CancelWithdrawRequest
					status={x.status}
					recordId={x._id}
					amount={x.finalAmount}
					currency={supportCurrencyEnumInfo(x.currency).currency?.toUpperCase()}
				/>
			),
		},
		{
			key: 33,
			title: '',
			render: (x: any) => <ChangeRequestStatus item={x} />,
		},
	];

	return (
		<AdvancedSectionLayout title="Withdrawal Out of Xend Finance">
			<div>
				<br />
				<QueueStatusStyle>
					<div>
						<p>Change Status</p>
						<Select
							style={{ minWidth: 100 }}
							defaultValue={queueItemStatus}
							onChange={(e) => setQueueItemStatus(e)}
						>
							<Select.Option value={8}>Review</Select.Option>
							<Select.Option value={0}>Pending Items</Select.Option>
							<Select.Option value={5}>Processing Items</Select.Option>
							<Select.Option value={7}>On-Hold Items</Select.Option>
							<Select.Option value={6}>Retry</Select.Option>
							<Select.Option value={1}>Completed Items</Select.Option>
							<Select.Option value={3}>Canceled Items</Select.Option>
						</Select>
					</div>
					<QueueStatuses queue="withdrawout" />
				</QueueStatusStyle>

				<Divider />
				<p>
					<strong>{totalRecords} items</strong>
				</p>
				<br />

				<Table
					loading={loading}
					dataSource={records}
					columns={columns}
					pagination={false}
					scroll={{ x: 'max-content' }}
				/>
				<br />
				<Pagination
					onChange={onChange}
					showSizeChanger={false}
					current={page}
					pageSize={30}
					total={totalRecords}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default WithdrawOut;
