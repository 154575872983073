import baseuri from './baseuri';

export const getRequests = (url: any) => {
	return baseuri
		.get(url)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
		});
};
export const getUserTypes = () => {
	return baseuri
		.get(`/admin/user-types/get-user-types`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
		});
};

export const usersSearch = (searchItem: any) => {
	return baseuri
		.get(`admin/search-user?search=${searchItem}`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
		});
};

export const usersSearchByDate = (startDate: any, endDate: any) => {
	return baseuri
		.get(`admin/search-user-date?start=${startDate}&end=${endDate}`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
		});
};
