import { Select, Divider, Modal, Button, message, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import { getRequests } from '../../../methods/users';

interface IAddRole {
	email: string;
	userId: string;
}
const AddRole: FC<IAddRole> = ({ email, userId }) => {
	const [visible, setVisible] = useState(false);
	const [roleId, setRoleId] = useState('');
	const [loading, setloading] = useState(false);
	const [roleList, setRoleList] = useState([] as any);

	useEffect(() => {
		getRequests('admin/roles?page=1&pageSize=10').then((res: any) => {
			setRoleList(res?.data?.records);
		});
	}, []);

	const submitAddRoles = async () => {
		try {
			setloading(true);
			const res = await baseuri.post('admin/roles/change-role', {
				roleId,
				userId,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Operation complete');
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error(e.toString());
		}
	};

	return (
		<>
			<Button type="primary" size="small" onClick={() => setVisible(true)}>
				Change Role
			</Button>

			<Modal
				title="Add Role to User"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={submitAddRoles}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>Email: {email}</p>
				<Divider />

				<p>Choose Roles to add</p>
				<Select
					size="large"
					style={{ width: '100%', marginTop: 5 }}
					placeholder="Please Select"
					onChange={(e: any) => setRoleId(e)}
				>
					{roleList?.map((role: any) => (
						<Select.Option value={role?.id} key={role?.id}>
							{role?.title}
						</Select.Option>
					))}

					{/* <Select.Option value="super">Super Admin</Select.Option> */}
				</Select>
			</Modal>
		</>
	);
};

export default AddRole;
