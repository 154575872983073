import {
	Button,
	InputNumber,
	Select,
	Table,
	Form,
	Divider,
	Pagination,
} from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import getUsersByWalletBalance from '../../../methods/users-by-wallet-balance';
import { commas } from '../../../methods/utils';
import AdvancedSectionLayout from '../../Layouts/Advanced';

const WalletBalanceSearch = () => {
	const [state, setState] = useState({
		currency: 'busd',
		amount: 100,
		loading: false,
	});
	const [result, setResult] = useState<Array<any>>([]);
	const [resultMeta, setResultMeta] = useState({ page: 1, totalRecords: 0 });

	const [form] = Form.useForm();

	const getResult = async (currency: any, amount: any, page: any) => {
		try {
			setState({ ...state, loading: true });
			const x = await getUsersByWalletBalance(currency, amount, page);
			if (x) {
				const { records, currentPage, totalRecords } = x;
				setResult(records);
				setResultMeta({ ...resultMeta, page: currentPage, totalRecords });
				setState({ ...state, loading: false });
			}
			setState({ ...state, loading: false });
		} catch (error) {
			console.error(error);
			setState({ ...state, loading: false });
		}
	};
	const onFinish = (data: any) => {
		getResult(data.currency, data.amount, resultMeta.page);
		setState({ ...state, currency: data.currency, amount: data.amount });
	};

	const changePage = (page: any) => {
		const amount = form.getFieldValue('amount');
		getResult(state.currency, amount, page);
	};

	const columns = [
		{
			key: 0,
			title: 'User',
			dataIndex: 'walletId',
			render: (data: any) => (
				<Link to={`/user-profile/${data?.userId?._id}`}>
					<p>@{data?.userId?.username}</p>
					<p>{data?.userId?.email}</p>
				</Link>
			),
		},
		{
			key: 3,
			title: 'Phone',
			dataIndex: 'walletId',
			render: (data: any) => <p>{data?.userId?.phoneNo}</p>,
		},
		{
			key: 1,
			title: 'Amount',
			dataIndex: 'balance',
			render: (amount: any) => commas(amount),
		},
	];

	return (
		<AdvancedSectionLayout title="Wallet Balance Search">
			<div>
				<p>Find users with over a certain amount of money in their wallet.</p>
				<p>
					Perform search using any of the supported currencies on Xend Finance.
				</p>
			</div>

			<br />

			<Form
				form={form}
				onFinish={onFinish}
				layout="inline"
				initialValues={{
					currency: 'busd',
					amount: state.amount,
				}}
			>
				<Form.Item name="amount">
					<InputNumber placeholder="Amount" />
				</Form.Item>
				<Form.Item name="currency">
					<Select>
						<Select.Option value="busd">BUSD</Select.Option>
						<Select.Option value="xend">XEND</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={state.loading}
						disabled={state.loading}
					>
						Search
					</Button>
				</Form.Item>
			</Form>

			<Divider />

			<Table
				dataSource={result}
				columns={columns}
				loading={state.loading}
				pagination={false}
			/>
			<br />
			<Pagination
				total={resultMeta.totalRecords}
				current={resultMeta.page}
				onChange={changePage}
				pageSize={10}
				showSizeChanger={false}
			/>
		</AdvancedSectionLayout>
	);
};

export default WalletBalanceSearch;
