import { Image } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RetrieveImage } from '../../../methods/utils';

const ImageSection = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border: 2px solid #aaa3a3;
	border-radius: 5px;
`;
interface IImageComponent {
	secret: string;
	url: string;
}
const ImageComponent: FC<IImageComponent> = ({ secret, url }) => {
	const [base64, setBase64] = useState('');

	async function fetchBase64() {
		const data = await RetrieveImage(secret, url);
		setBase64(data);
	}
	useEffect(() => {
		setBase64('');
		if (url) {
			fetchBase64();
		}
	}, [secret, url]);

	return (
		<ImageSection>
			{url ? (
				<span>
					{base64 ? (
						<Image
							width="100%"
							height="100%"
							src={base64}
							alt="Unable to load Image"
						/>
					) : (
						'LOADING...'
					)}
				</span>
			) : (
				'NO IMAGE'
			)}
		</ImageSection>
	);
};

export default ImageComponent;
