import { message, Pagination, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';

const PlanAboutToMature = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		records: [],
		currentPage: 1,
		pageSize: 10,
		totalRecords: 0,
		totalPage: 0,
	});

	const columns = [
		{
			key: 0,
			title: 'Name',
			dataIndex: 'planId',
			render: (plan: any) => (
				<div>
					<p>
						<strong>{plan?.planName}</strong>
					</p>
					<br />
					<p>{plan?.userId?.email}</p>
				</div>
			),
		},
		{
			key: 3,
			title: 'Deposited',
			dataIndex: 'planId',
			render: (plan: any) => plan?.depositAmount,
		},
		{
			key: 1,
			title: 'Value Locked',
			dataIndex: 'planId',
			render: (plan: any) => plan?.shareBalance,
		},
		{
			key: 2,
			title: 'Reward',
			dataIndex: 'rewardInUSD',
		},
		{
			key: 4,
			title: 'Expired',
			dataIndex: 'planId',
			render: (plan: any) => moment(plan?.lockedPeriod).format('ll'),
		},
		{
			key: 5,
			title: 'Created',
			dataIndex: 'createdAt',
			render: (createdAt: any) => moment(createdAt).format('lll'),
		},
	];

	const fetchData = async (page = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/advanced/plans-about-to-mature', {
				params: { page, pageSize: 10 },
			});
			if (res.status === 200) {
				setData({ ...res.data.data });
			}
		} catch (e) {
			message.error('failed to retrieve info');
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);
	return (
		<AdvancedSectionLayout
			title="Plans About to Mature / Yet to Withdraw"
			noMetamask
		>
			<div>
				<Table
					loading={loading}
					columns={columns}
					dataSource={data.records}
					pagination={false}
				/>
				<br />
				<br />
				<Pagination
					onChange={(page) => fetchData(page)}
					current={data.currentPage}
					pageSize={data.pageSize}
					total={data.totalRecords}
					showSizeChanger={false}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default PlanAboutToMature;
