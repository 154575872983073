import { DeleteOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

const DeleteSection = styled.div`
	display: inline-flex;
	margin-left: 10px;

	& .deleteIcon {
		font-size: 20px;
		color: red;
		cursor: pointer;
	}
`;
interface IDeleteGreeting {
	greetingID: string;
}
const DeleteGreeting: FC<IDeleteGreeting> = ({ greetingID }) => {
	const removeGreeting = async () => {
		try {
			// setloading(true);
			const res = await baseuri.delete(`admin/delete-alert?id=${greetingID}`);
			if (res.status === 200) {
				// setloading(false);
				message.success('Greeting removed Successfully');
			}
		} catch (e) {
			// setloading(false);
			message.error('Could not complete operation');
		}
	};
	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to Remove this Greeting?',
			onOk() {
				removeGreeting();
			},
		});
	};
	return (
		<DeleteSection>
			<DeleteOutlined className="deleteIcon" onClick={confirm} />
		</DeleteSection>
	);
};

export default DeleteGreeting;
