import { Button, Card, Layout, Menu, Modal } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { truncateAddress } from '../../methods/utils';
import { getAmounts } from '../../methods/web3/amounts';
import { ConnectorNames } from '../../methods/web3/types';
import {
	DisconnectFromWallet,
	Login,
	recreateWeb3,
} from '../../methods/web3/useAuth';
import { ReactComponent as XendLogo } from '../../logo.svg';
// import SwitchEnv from '../SwitchEnv';

const { Header, Footer, Sider, Content } = Layout;

const AdvancedTitle = styled.div`
	padding: 10px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
interface IAdvancedLayout {
	title?: string;
	noMetamask?: boolean;
}
const AdvancedSectionLayout: FC<IAdvancedLayout> = ({
	children,
	title,
	noMetamask,
}) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const history = useHistory();
	const [selectedMenu, setSelectedMenu] = useState<any>('');

	const linksAndKeys: any = {
		'/advanced/deposit-queue': 'deposit',
		'/advanced/withdraw-queue': 'withdraw',
		'/advanced/withdraw-out': 'withdraw-out',
		'/advanced/wallet-balance-search': 'balance-search',
		'/advanced/view-reward-record': 'reward-record',
		'/advanced/xend-bridge': 'xend-bridge',
		'/advanced/fraud-list': 'suspect',
		'/advanced/user-search': 'user-search',
		'/advanced/liquidity-providers': 'liquidity-providers',
		'/advanced/funding-requests': 'funding-requests',
		'/advanced/plans-about-mature': 'plans-about-mature',
		'/advanced/blacklisted-devices': 'blacklisted-devices',
	};

	useEffect(() => {
		setSelectedMenu(linksAndKeys[pathname]);
	}, [pathname]);

	const { user } = useSelector((store: any) => store.auth);
	const { address } = useSelector((store: any) => store.web3conn);

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		dispatch(recreateWeb3());
	}, []);

	const login = () => {
		dispatch(Login(ConnectorNames.Injected, 56, 'metamask'));
	};

	useEffect(() => {
		dispatch(getAmounts(address));
	}, [address]);

	return (
		<Layout>
			<Sider theme="light" style={{ height: '100%', position: 'fixed' }}>
				{user && title && (
					<Helmet>
						<title>
							{title} | {user?.email}
						</title>
					</Helmet>
				)}

				<AdvancedTitle>
					{/* <p>Xend Finance Advanced</p> */}
					<XendLogo width={100} />
				</AdvancedTitle>

				<Menu
					mode="inline"
					selectedKeys={[selectedMenu]}
					openKeys={['queues']}
					theme="light"
				>
					<Menu.Item
						icon={<ArrowLeftOutlined />}
						onClick={() => history.push('/')}
					>
						Back
					</Menu.Item>

					<SubMenu key="queues" title="Queues">
						<Menu.Item
							icon={<span className="material-icons">savings</span>}
							key="deposit"
							onClick={() => history.push('/advanced/deposit-queue')}
						>
							Deposit
						</Menu.Item>

						<Menu.Item
							icon={<span className="material-icons">south_west</span>}
							key="withdraw"
							onClick={() => history.push('/advanced/withdraw-queue')}
						>
							Withdraw
						</Menu.Item>

						<Menu.Item
							icon={<span className="material-icons">outbox</span>}
							key="withdraw-out"
							onClick={() => history.push('/advanced/withdraw-out')}
						>
							Withdraw Out
						</Menu.Item>
					</SubMenu>

					<Menu.Item
						icon={<span className="material-icons">account_balance</span>}
						key="balance-search"
						onClick={() => history.push('/advanced/wallet-balance-search')}
					>
						Wallet Balance Search
					</Menu.Item>

					<Menu.Item
						icon={<span className="material-icons">sell</span>}
						key="xend-bridge"
						onClick={() => history.push('/advanced/xend-bridge')}
					>
						XendBridge Sell Request
					</Menu.Item>

					<Menu.Item
						icon={<span className="material-icons">business_center</span>}
						key="liquidity-providers"
						onClick={() => history.push('/advanced/liquidity-providers')}
					>
						Liquidity Providers
					</Menu.Item>

					<Menu.Item
						icon={<span className="material-icons">local_police</span>}
						key="suspect"
						onClick={() => history.push('/advanced/fraud-list')}
					>
						Fraud List
					</Menu.Item>

					<Menu.Item
						icon={<span className="material-icons">request_quote</span>}
						key="funding-requests"
						onClick={() => history.push('/advanced/funding-requests')}
					>
						Funding Requests
					</Menu.Item>
					<Menu.Item
						icon={<span className="material-icons">gpp_bad</span>}
						key="blacklisted-devices"
						onClick={() => history.push('/advanced/blacklisted-devices')}
					>
						Blacklisted Devices
					</Menu.Item>
					<Menu.Item
						icon={<span className="material-icons">request_quote</span>}
						key="plans-about-mature"
						onClick={() => history.push('/advanced/plans-about-mature')}
					>
						Plans About to Mature
					</Menu.Item>
					<Menu.Item
						icon={<span className="material-icons">settings</span>}
						key="internal-settings"
						onClick={() => history.push('/advanced/settings')}
					>
						Internal Settings
					</Menu.Item>

					{/* <Menu.Item 
						key="reward-record"
						onClick={() => history.push("/advanced/view-reward-record")}>View Reward Record</Menu.Item> */}
				</Menu>
			</Sider>
			<Layout style={{ marginLeft: 200 }}>
				<Header
					style={{
						backgroundColor: 'white',
						position: 'fixed',
						width: '100vw',
						zIndex: 2,
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<p>{title}</p>
					{/* <SwitchEnv /> */}
				</Header>
				<Content
					style={{
						padding: 20,
						height: '100%',
						minHeight: '90vh',
						overflowY: 'auto',
						paddingTop: 100,
					}}
				>
					<Card style={{ height: '100%' }}>
						{!noMetamask && (
							<div>
								{address && (
									<Button onClick={() => setVisible(true)}>
										{truncateAddress(address)}
									</Button>
								)}
								{!address && (
									<Button onClick={() => login()}>Connect Metamask</Button>
								)}

								<Modal visible={visible} onCancel={() => setVisible(false)}>
									<Button onClick={() => DisconnectFromWallet()}>Logout</Button>
								</Modal>
							</div>
						)}
						<br />
						{children}
					</Card>
				</Content>
				<Footer>&copy; Xend Finance</Footer>
			</Layout>
		</Layout>
	);
};

export default AdvancedSectionLayout;

AdvancedSectionLayout.defaultProps = {
	title: '',
	noMetamask: false,
};
