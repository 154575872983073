import { Card, message, Pagination, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import CreateNewsLetter from './CreateNewsLetter';
import DelNewsComp from './DelNewsComp';
import EditNewsletter from './EditNewsletter';
import NewsLetterConfigs from './NewsLetterConfigs';
import SendNewsLetter from './SendNewsLetter';
import SendTestNewsLetter from './SendTestNewsLetter';
import TerminateNewsLetter from './TerminateNewsLetter';
import UpdateNewsLetterStatus from './UpdateNewsLetterStatus';

const LastSection = styled.div`
	display: inline-flex;
	gap: 0.5em;
	min-width: 150px;
`;
const ResponsiveSection = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;
`;
function index() {
	const [loading, setLoading] = useState(true);
	const [allNewsLetters, setAllNewsLetters] = useState({
		totalRecords: 0,
		pageSize: 0,
		currentPage: 0,
		totalPage: 0,
		records: [],
	});
	const [allUsers, setAllUsers] = useState({
		totalSubscribers: 0,
		totalUnSubscribers: 0,
	});
	const handleGetNewsLetter = async (page = 1) => {
		setLoading(true);
		let res: any;
		try {
			res = await axios.get(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters`,
				{
					params: {
						page,
						pageSize: 10,
					},
				}
			);
			if (res.status === 200) {
				setAllNewsLetters(res?.data?.data);
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error?.response?.data?.data?.message);
		}
	};
	const getUsersNumber = async () => {
		let res: any;
		try {
			res = await axios.get(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/subscribers`
			);

			if (res.status === 200) {
				setAllUsers(res?.data?.data);
			}
		} catch (error: any) {
			setLoading(false);
			message.error(error?.response?.data?.data?.message);
		}
	};
	useEffect(() => {
		handleGetNewsLetter();
		getUsersNumber();
	}, []);

	const columns = [
		{
			title: 'Subject',
			key: 'subject',
			render: (text: any) => <strong>{text?.subject}</strong>,
		},
		{
			title: 'Mail List',
			key: 'maillist',
			render: (text: any) => <strong>{text?.maillist}</strong>,
		},
		{
			title: 'Delivery Type',
			key: 'deliveryType',
			render: (text: any) => <strong>{text?.deliveryType}</strong>,
		},
		{
			title: 'Status',
			key: 'deliveryStatus',
			render: (text: any) => <strong>{text?.deliveryStatus}</strong>,
		},
		{
			title: 'Total Sent',
			key: 'totalSent',
			render: (text: any) => <strong>{text?.totalSent}</strong>,
		},
		{
			title: 'Total Failed',
			key: 'totalFailed',
			render: (text: any) => <strong>{text?.totalFailed}</strong>,
		},
		{
			title: 'Delivery Date',
			dataIndex: 'deliveryDate',
			key: 'deliveryDate',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Date Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Sent By',
			key: 'creatorEmail',
			render: (text: any) => <strong>{text?.creatorEmail}</strong>,
		},
		{
			title: '',
			key: 'subject',
			render: (text: any) => (
				<LastSection style={{ width: '100px' }}>
					{text?.deliveryStatus === 'terminated' ||
					text?.deliveryStatus === 'paused' ? (
						<DelNewsComp newsletter={text} />
					) : (
						''
					)}
					{text?.deliveryStatus === 'pending' ||
					text?.deliveryStatus === 'paused' ? (
						<TerminateNewsLetter newsletter={text} />
					) : (
						''
					)}
					{text?.deliveryStatus === 'sent' && <DelNewsComp newsletter={text} />}
					{text?.deliveryStatus === 'saved' && (
						<EditNewsletter newsLetter={text} />
					)}
					<LastSection style={{ width: '100px' }}>
						{text?.deliveryStatus === 'pending' ||
						text?.deliveryStatus === 'paused'
							? text?.isPublished === true && (
									<UpdateNewsLetterStatus
										newsletter={text}
										text={
											text?.deliveryStatus === 'paused' ? 'Resume' : 'Pause'
										}
									/>
							  )
							: ''}
						{text?.deliveryStatus === 'pending' &&
							text?.isPublished === false && (
								<SendNewsLetter newsletter={text} />
							)}
						{text?.deliveryStatus === 'saved' && (
							<>
								{' '}
								<SendTestNewsLetter newsletter={text} />
								<EditNewsletter newsLetter={text} />{' '}
							</>
						)}
					</LastSection>
				</LastSection>
			),
		},
		// {
		// 	title: '',
		// 	key: '345334',
		// 	render: (text: any) => (
		// 		<LastSection style={{ width: '100px' }}>
		// 			{text?.deliveryStatus === 'pending' ||
		// 			text?.deliveryStatus === 'paused'
		// 				? text?.isPublished === true && (
		// 						<UpdateNewsLetterStatus
		// 							newsletter={text}
		// 							text={text?.deliveryStatus === 'paused' ? 'Resume' : 'Pause'}
		// 						/>
		// 				  )
		// 				: ''}
		// 			{text?.deliveryStatus === 'pending' &&
		// 				text?.isPublished === false && <SendNewsLetter newsletter={text} />}
		// 			{text?.deliveryStatus === 'saved' && (
		// 				<>
		// 					{' '}
		// 					<SendTestNewsLetter newsletter={text} />
		// 					<EditNewsletter newsLetter={text} />{' '}
		// 				</>
		// 			)}
		// 		</LastSection>
		// 	),
		// },
	];
	return (
		<FullLayout pageTitle="NewsLetter">
			<CreateNewsLetter /> <br />
			<Card>
				<p>
					<span>
						Total Subscribers: <strong>{allUsers?.totalSubscribers}</strong>
					</span>
				</p>
				<p>
					<span>
						UnSubscribed Users: <strong>{allUsers?.totalUnSubscribers}</strong>
					</span>
				</p>
				<NewsLetterConfigs allUsers={allUsers} />
				<ResponsiveSection>
					<Table
						loading={loading}
						columns={columns}
						dataSource={allNewsLetters?.records}
						pagination={false}
					/>

					<br />
					<Pagination
						onChange={(page) => handleGetNewsLetter(page)}
						disabled={loading}
						pageSize={allNewsLetters?.pageSize}
						current={allNewsLetters?.currentPage}
						total={allNewsLetters?.totalRecords}
						showSizeChanger={false}
					/>
				</ResponsiveSection>
			</Card>
		</FullLayout>
	);
}

export default index;
