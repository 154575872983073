import reduxTypes from '../redux-types';

const initialState = {
	sharePrices: [],
	liquidityProviders: [],
};

interface Action {
	type: string;
	payload: any;
}

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }: Action) => {
	switch (type) {
		case reduxTypes.SHARE_PRICES:
			return { ...state, sharePrices: payload };

		case reduxTypes.LIQUIDITY_PROVIDER:
			return { ...state, liquidityProviders: payload };

		default:
			return state;
	}
};
