import { message, Modal, Space } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import CreateGreeting from './CreateGreeting';
import DeleteGreeting from './DeleteGreeting';
import EditGreeting from './EditGreeting';

const AppGreetingsSection = styled.section`
	height: 100vh;
	overflow-y: auto;
`;
const GreetingItemSection = styled.section`
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: #bedaeb;
	padding: 10px;
	margin: 5px 0px;
	border-radius: 5px;

	& .upperSection {
		display: flex;
		position: relative;
		justify-content: space-between;
	}
	& .upperSection > strong {
		max-width: 50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	& .lowerSection {
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 10px;
	}
`;
const LowerLeft = styled.div`
	cursor: pointer;
	flex: 1;
	& > p {
		max-width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	& .spanSection {
		color: #777171;
		cursor: pointer;
		font-size: 14px;
	}
`;

const MainTitle = styled.div`
	display: flex;
	position: relative;
	align-items: flex-start;
	flex-direction: row;
	justify-content: space-between;
	& .titleMain {
		flex: 1;
		font-weight: 20px;
		max-width: 75%;
		overflow: auto;
		overflow-wrap: break-word;
	}
`;
const MainContent = styled.div`
	display: flex;
	flex-direction: column;

	color: #030000;
	& .expireSection {
		color: #7e7b7b;
	}
`;
const handleShowInfo = (greeting: any) => {
	Modal.info({
		title: (
			<MainTitle>
				<div className="titleMain">
					<strong>{greeting?.title}</strong>
				</div>
				<Space>
					<EditGreeting greetingDetails={greeting} />
					<DeleteGreeting greetingID={greeting?._id} />
				</Space>
			</MainTitle>
		),
		content: (
			<MainContent>
				<h4>{greeting?.description}</h4>
				<span className="expireSection">{greeting?.expiry_time}</span>
			</MainContent>
		),
	});
};

const AppGreetings = () => {
	const [loading, setLoading] = useState(false);
	const [allGreetings, setAllGreetings] = useState({} as any);

	const getGreetings = async () => {
		try {
			setLoading(true);
			const res: any = await baseuri.get(`user/get-all-alert`);

			if (res.status === 200) {
				setLoading(false);
				setAllGreetings(res.data.data);
			} else {
				setLoading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setLoading(false);
			message.error(e.response?.data?.data?.message);
		}
	};

	useEffect(() => {
		getGreetings();
	}, []);

	return (
		<AppGreetingsSection>
			<CreateGreeting />
			<br />
			{loading ? (
				'Loading'
			) : (
				<p>
					{allGreetings?.newslist?.map((greeting: any) => (
						<GreetingItemSection key={greeting._id}>
							<div className="upperSection">
								<strong>{greeting?.title}</strong>
								<p>{greeting?.timeFrame}</p>
							</div>
							<div className="lowerSection">
								<LowerLeft onClick={() => handleShowInfo(greeting)}>
									<p>{greeting.description}</p>
									<span className="spanSection">
										expires on{' '}
										{moment(greeting?.expiry_time).format('DD-MM-YYYY HH:MM')}
									</span>
								</LowerLeft>
								<div className="lowerRight">
									<EditGreeting greetingDetails={greeting} />
									<DeleteGreeting greetingID={greeting?._id} />
								</div>
							</div>
						</GreetingItemSection>
					))}
				</p>
			)}
		</AppGreetingsSection>
	);
};

export default AppGreetings;
