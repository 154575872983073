import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import { commas, getCoinGeckoPrice, shortAmount } from '../../methods/utils';
import BusdIcon from '../../binance-usd-busd-logo.svg';
import XendIcon from '../../xend.svg';
import baseuri from '../../methods/baseuri';

const Balances = styled.section`
	display: flex;
	flex-direction: column;

	& > * {
		margin-bottom: 10px;
		display: flex;
		margin-right: 10px;
		font-weight: 600;

		& > *:first-child {
			margin-right: 5px;
		}
	}
`;

const TotalWalletBalance = () => {
	const [balances, setBalances] = useState({
		unsettled: {
			bulkSender: { xend: 0, busd: 0 },
			withdrawalOut: { xend: 0, busd: 0 },
		},
		available: { busd: 0, xend: 0 },
	});

	const [totalDollar, setTotalDollar] = useState(0);

	const getTotalBalances = async () => {
		try {
			const res: any = await baseuri.get('/admin/overall-wallet-balances');
			if (res.status === 200) {
				const { data } = res.data;

				const final = { xend: 0, busd: 0 };
				if (Array.isArray(data.walletBalances)) {
					for (let i = 0; i < data.walletBalances.length; i++) {
						const current = data.walletBalances[i];
						const balance = current.totalBalance;

						if (current.currency === 'xend') {
							final.xend = balance;
						}
						if (current.currency === 'busd') {
							final.busd = balance;
						}
					}
				}
				setBalances({
					...balances,
					unsettled: { ...balances.unsettled, ...data.unsettledBalance },
					available: { ...balances.available, ...final },
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		getTotalBalances();
	}, []);

	const totalBalance = () => {
		const xend =
			Number(balances.unsettled.bulkSender.xend) +
			Number(balances.unsettled.withdrawalOut.xend) +
			Number(balances.available.xend);

		const busd =
			Number(balances.unsettled.bulkSender.busd) +
			Number(balances.unsettled.withdrawalOut.busd) +
			Number(balances.available.busd);

		return { xend, busd };
	};

	const totalBalanceDollarValue = async () => {
		try {
			const busdPrice = await getCoinGeckoPrice('binance-usd');
			const xendPrice = await getCoinGeckoPrice('xend-finance');

			const bal = totalBalance();

			const total =
				Number(bal.busd) * Number(busdPrice) +
				Number(bal.xend) * Number(xendPrice);
			setTotalDollar(total);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		totalBalanceDollarValue();
	}, [balances.unsettled]);

	return (
		<Balances>
			<p>Total Available Wallet Balances</p>
			<Balances>
				<div>
					<img src={BusdIcon} alt="" width={20} />
					<p>{commas(shortAmount(balances.available.busd))} BUSD</p>
				</div>
				<div>
					<img src={XendIcon} alt="" width={20} />
					<p> {commas(shortAmount(balances.available.xend))} XEND</p>
				</div>
			</Balances>
			<Divider />

			<p>Unsettled (Ledger) Wallet Balances</p>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<p>
						<span>Withdrawal Out</span>
					</p>
					<Balances>
						<div>
							<img src={BusdIcon} alt="" width={20} />
							<p>
								{commas(shortAmount(balances.unsettled.withdrawalOut.busd))}{' '}
								BUSD
							</p>
						</div>
						<div>
							<img src={XendIcon} alt="" width={20} />
							<p>
								{commas(shortAmount(balances.unsettled.withdrawalOut.xend))}{' '}
								XEND
							</p>
						</div>
					</Balances>
				</div>
				<div>
					<p>
						<em>Bulk Job</em>
					</p>
					<Balances>
						<div>
							<img src={BusdIcon} alt="" width={20} />
							<p>
								{commas(shortAmount(balances.unsettled.bulkSender.busd))} BUSD
							</p>
						</div>
						<div>
							<img src={XendIcon} alt="" width={20} />
							<p>
								{commas(shortAmount(balances.unsettled.bulkSender.xend))} XEND
							</p>
						</div>
					</Balances>
				</div>
			</div>
			<Divider />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<p>
					Total Balance (Available + Withdraw out ledger + Bulk job ledger):
				</p>
				<p>{commas(shortAmount(totalBalance().xend))} XEND</p>
				<p>{commas(shortAmount(totalBalance().busd))} BUSD</p>
				<p>Total USD Value: {commas(shortAmount(totalDollar))} USD</p>
			</div>
		</Balances>
	);
};

export default TotalWalletBalance;
