import { Button, message, Modal, Input } from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import styled from 'styled-components';

const AccessSection = styled.section`
	display: flex;
	flex-direction: row;
	> span {
		width: 25%;
		/* min-width: 150px; */
	}
	/* &.accessInput {
		width: 30px;
	} */
`;
interface IDelNewsComp {
	newsletter: any;
}
const DelNewsComp: FC<IDelNewsComp> = ({ newsletter }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [accessCode, setAccessCode] = useState('3345constable');

	const deleteNewsletter = async () => {
		try {
			setloading(true);
			const res: any = await axios.delete(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters/${newsletter.id}`,
				{
					params: {
						access_code: accessCode,
					},
				}
			);
			if (res.status === 200) {
				setloading(false);
				message.success(res?.data?.data?.message);
				setVisible(false);
			}
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	return (
		<div>
			<Button
				block
				danger
				// shape="round"
				type="primary"
				size="small"
				loading={loading}
				onClick={() => setVisible(true)}
			>
				Delete
			</Button>
			<Modal
				width="50%"
				// footer={null}
				visible={visible}
				footer={[
					<Button
						danger
						loading={loading}
						type="primary"
						onClick={() => deleteNewsletter()}
					>
						Delete
					</Button>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Are you sure you want to <strong> Delete </strong>this NewsLetter
					{newsletter?.subject ? `with Subject "${newsletter?.subject}"` : ''}`
				</p>
				<br />
				<br />
				<AccessSection>
					<span> Access Code : </span>
					<Input
						value={accessCode}
						className="accessInput"
						placeholder="input access code"
						onChange={(e: any) => setAccessCode(e.target.value)}
					/>
				</AccessSection>
			</Modal>
		</div>
	);
};

export default DelNewsComp;
