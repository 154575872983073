import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IUpdateTransactionRecordStatus {
	transactionType: 'wallet' | 'savings';
	transactionId: string;
}
const UpdateTransactionRecordStatus = ({
	transactionId,
	transactionType,
}: IUpdateTransactionRecordStatus) => {
	const [visible, setVisible] = useState(false);

	const updateStatus = async () => {
		try {
			if (transactionType === 'savings') {
				await baseuri.put('/admin/switch-savings-record', {
					recordId: transactionId,
				});
				message.success('status updated');
			}

			if (transactionType === 'wallet') {
				await baseuri.put('/admin/switch-wallet-record', {
					recordId: transactionId,
				});
				message.success('status updated');
			}
		} catch (e: any) {
			message.error('update failed');
		}
		setVisible(false);
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Change Record Status</Button>

			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Update Record Status"
				onOk={updateStatus}
			>
				<p>
					You&apos;s about the change the status and visible of this transaction
					record. Click <strong>OK</strong> to continue
				</p>
			</Modal>
		</>
	);
};

export default UpdateTransactionRecordStatus;
