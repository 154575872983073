import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC, useState } from 'react';

interface INewsLetterConfig {
	allUsers: any;
}
const NewsLetterConfigs: FC<INewsLetterConfig> = ({ allUsers }) => {
	const [visible, setVisible] = useState(false);

	return (
		<div>
			<Button
				type="primary"
				// shape="round"
				size="small"
				// ghost
				onClick={() => setVisible(true)}
			>
				NewsLetter Configs
			</Button>
			<Modal
				// width="50%"
				footer={null}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<p>
					<span>
						Mail Sender Name:{' '}
						<strong>{allUsers?.systemConfig?.MAIL_FROM_NAME}</strong>
					</span>
				</p>
				<p>
					<span>
						Mail Sender Email:{' '}
						<strong>{allUsers?.systemConfig?.MAIL_FROM_EMAIL}</strong>
					</span>
				</p>
				<p>
					<span>
						Maximum sent mails per batch:{' '}
						<strong>
							{allUsers?.systemConfig?.MAXIMUM_SENT_EMAIL_PER_BATCH}
						</strong>
					</span>
				</p>
				<p>
					<span>
						Wait time per batch:{' '}
						<strong>{allUsers?.systemConfig?.WAIT_TIME_PER_BATCH}</strong> mins
					</span>
				</p>
			</Modal>
		</div>
	);
};

export default NewsLetterConfigs;
