/* eslint-disable no-console */
import Notify from 'bnc-notify';
import createContract from './create-contract';
import baseuri from '../baseuri';
import { getDappId, waitTime } from './deposit';

export const BUSDToShare = (
	busd: number,
	pricePerShare: any,
	protocolName: string
) => {
	const shareDecimal =
		protocolName === 'venus' ? BigInt(10 ** 8) : BigInt(10 ** 18);
	const share =
		(BigInt(busd * 10 ** 18) * shareDecimal) / BigInt(pricePerShare);
	return String(share);
};

interface IWithdrawFromProtocol {
	amount: number;
	protocol: 'xAuto' | 'venus';
	processListId: string;
	asset: any;
	client: string;
	planIdAndType: string[];
	sendData: Function;
	successData: Function;
}
export const withdrawFromProtocol = ({
	protocol,
	successData,
	processListId,
	amount,
	planIdAndType,
	asset,
	client,
	sendData,
}: IWithdrawFromProtocol) => {
	return async () => {
		//
		try {
			const notifyBNC = Notify({
				dappId: getDappId(asset.network),
				networkId: asset.network,
				mobilePosition: 'bottom',
				desktopPosition: 'bottomRight',
			});

			sendData({
				activity: 'Process started. Plans accounted for.',
				percent: 10,
				status: 'normal',
			});

			const contract = await createContract(
				asset.protocolAbi,
				asset.protocolAddress
			);

			sendData({
				activity: 'Getting Share balance and Price per share',
				percent: 20,
				status: 'normal',
			});
			const initShares = await contract.methods.balanceOf(client).call();
			const ppfs = await contract.methods[asset.ppfsMethod]().call();

			sendData({
				activity: 'Calculating Share amount to withdraw',
				percent: 35,
				status: 'normal',
			});

			const amountToUse = BUSDToShare(Number(amount), ppfs, protocol);

			if (asset.protocolName === 'xVault') {
				// withdrawAmount = removeDecimals(withdrawAmount);
				// const result = await contract.methods['withdraw'](String(withdrawAmount), client, 0)
				// 	.send({ from: client })
				// 	.on('transactionHash', hash => {
				// 		notifyBNC.hash(hash)
				// 	})
			}

			if (protocol === 'xAuto') {
				sendData({
					activity: 'Withdrawal Started',
					percent: 50,
					status: 'normal',
				});

				const receipt = await contract.methods
					.withdraw(amountToUse)
					.send({ from: client })
					.on('transactionHash', (hash: any) => {
						notifyBNC.hash(hash);
						console.log(hash);
					});

				sendData({
					activity: 'Withdrawal Done. Transaction hash received',
					percent: 60,
					status: 'normal',
				});

				waitTime(1);

				sendData({
					activity: 'Getting shares balance after deposit',
					percent: 70,
					status: 'normal',
				});

				const finalShares = await contract.methods.balanceOf(client).call();

				const sending = {
					transactionHash: receipt.transactionHash,
					action: 'withdraw',
					planIdAndType,
					pricePerFullShare: ppfs,
					initShares,
					finalShares,
					protocolName: protocol,
					network: 56,
					amount,
					gasUsed: receipt.gasUsed,
					block: receipt.blockNumber,
					processListId,
				};

				console.log(sending);

				baseuri
					.post('/advanced/save-batch', sending)
					.then((res: any) => {
						if (res.status === 200) {
							sendData({
								activity: 'Saved batch details',
								percent: 100,
								status: 'success',
							});

							// then trigger click button to process the rest
							successData(res.data.data);
						} else {
							console.log(res);
							sendData({
								activity: res.data.message,
								percent: 90,
								status: 'exception',
							});
						}
					})
					.catch((e) => {
						// save these details to local storage. so we don't loose it. and can try again
						console.log(e);
						sendData({
							activity: 'Could not send batch details to server',
							percent: 90,
							status: 'exception',
						});
					});
			}
		} catch (e) {
			console.log(e);
			sendData({ status: 'exception' });
		}
	};
};

export const toFixed = (param: any) => {
	let x = param;
	if (Math.abs(x) < 1.0) {
		const e = parseInt(x.toString().split('e-')[1], 10);
		if (e) {
			x *= 10 ** (e - 1);
			x = `0.${new Array(e).join('0')}${x.toString().substring(2)}`;
		}
	} else {
		let e = parseInt(x.toString().split('+')[1], 10);
		if (e > 20) {
			e -= 20;
			x /= 10 ** e;
			x += new Array(e + 1).join('0');
		}
	}
	return x;
};

export const removeDecimals = (x: any) => {
	return String(x).split('.')[0];
};
