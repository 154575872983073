import baseuri from '../baseuri';
import reduxTypes from '../redux-types';

const getLiquidityProviders = () => {
	return async (dispatch: Function) => {
		try {
			const res: any = await baseuri.get('/advanced/liquidity-providers', {
				params: { pageSize: 30 },
			});
			if (res.status === 200) {
				dispatch({
					type: reduxTypes.LIQUIDITY_PROVIDER,
					payload: res.data.data?.records,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};
};

export default getLiquidityProviders;
