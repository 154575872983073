import {
	Button,
	Card,
	DatePicker,
	message,
	Pagination,
	Space,
	Table,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import { enum2ProcessStatus } from '../../../methods/transactions';
// import CSVComponent from '../../_ReUsable/CSVComponent';

const HeaderExtraStyle = styled.section`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;
const Responsive = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;
`;

interface IHeaderExtra {
	setLoading: Function;
	startDate: any;
	setStartDate: Function;
	endDate: any;
	setEndDate: Function;
	handleSearchByDate: Function;
}
const HeaderExtra: FC<IHeaderExtra> = ({
	setLoading,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleSearchByDate,
}) => {
	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, []);

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date) =>
							date?.isSameOrBefore(endDate) && setStartDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={startDate}
						defaultValue={startDate}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={endDate}
						onChange={(date) =>
							date?.isSameOrAfter(startDate) && setEndDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={endDate}
					/>
				</div>
				<div>
					<Button type="primary" onClick={() => handleSearchByDate()}>
						Search
					</Button>
				</div>
			</div>
		</HeaderExtraStyle>
	);
};

const GasSpent = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(moment('2021-10-01'));
	const [endDate, setEndDate] = useState(moment());
	const [gasSpent, setGasSpent] = useState({
		downloadLink: '',
		record: [],
		currentPage: 1,
		totalRecords: 0,
		totalGasUsed: 0,
		totalGasCostUSD: 0,
		totalPages: 0,
		pageSize: 0,
	});

	const handleGetFees = async (page = 1) => {
		setLoading(true);
		let res: any;
		try {
			res = await baseuri.get(`admin/gas-fees-spent`, {
				params: {
					start: startDate.format('YYYY-MM-DD'),
					end: endDate.format('YYYY-MM-DD'),
					page,
				},
			});

			if (res.status === 200) {
				setGasSpent({ ...gasSpent, ...res.data.data });
				// message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};

	useEffect(() => {
		setLoading(true);
		handleGetFees();
	}, []);

	// action: 'withdraw';
	// amount: '5'
	// block: 15450173;
	// bnbPrice: '369.71';
	// createdAt: '2022-02-21T12:51:05.378Z';
	// finalShares: '15620870567330483914';
	// gasCostUSD: '0.9899484358499999';
	// gasPrice: '5000000000';
	// gasUsed: 535527;
	// initShares: '20523403348397034103';
	// network: '56';
	// planIdAndType: ['61a92d09e8343516b7f76518 0 6211170286612eca0b0cf49b'];
	// pricePerFullShare: '1019880992802305288';
	// processStatus: 2;
	// protocolName: 'xAuto';
	// shareBalance: '-4902532781066550189';
	// transactionHash: '0xaa6d9b7720de2f0e5328b4df17d6a2f303649445e22babe23e22c618890aab22';
	// updatedAt: '2022-02-21T12:51:06.770Z';
	// __v: 0;
	// _id: '62138ab939197777fde98aae';

	const columns = [
		{
			title: 'Amount',
			key: 'amount',
			render: (text: any) => <strong>{text?.amount}</strong>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (text: any) => <strong>{text?.action}</strong>,
		},
		{
			title: 'BNB Price',
			key: 'bnbPrice',
			render: (text: any) => <strong>${text?.bnbPrice}</strong>,
		},
		{
			title: 'Final Shares',
			key: 'finalShares',
			render: (text: any) => <strong>{text?.finalShares}</strong>,
		},
		{
			title: 'Gas Cost (USD)',
			key: 'gasCostUSD',
			render: (text: any) => <strong> ${text?.gasCostUSD}</strong>,
		},
		{
			title: 'Gas Price',
			key: 'gasPrice',
			render: (text: any) => <strong>{text?.gasPrice}</strong>,
		},
		{
			title: 'Gas Used',
			key: 'gasUsed',
			render: (text: any) => <strong>{text?.gasUsed}</strong>,
		},
		{
			title: 'Initial Shares ',
			key: 'initShares',
			render: (text: any) => <strong>{text?.initShares}</strong>,
		},
		{
			title: 'Price Per Full Share',
			key: 'pricePerFullShare',
			render: (text: any) => <strong>{text?.pricePerFullShare}</strong>,
		},
		{
			title: 'Initial Shares ',
			key: 'initShares',
			render: (text: any) => <strong>{text?.initShares}</strong>,
		},
		{
			title: 'Share Balance ',
			key: 'shareBalance',
			render: (text: any) => <strong>{text?.shareBalance}</strong>,
		},
		{
			title: 'protocolName',
			key: 'shareBalance',
			render: (text: any) => <strong>{text?.protocolName}</strong>,
		},
		{
			title: 'Status',
			key: 'processStatus',
			render: (text: any) => (
				<strong>{enum2ProcessStatus(text?.processStatus)}</strong>
			),
		},
		{
			title: 'Hash',
			dataIndex: 'transactionHash',
			render: (text: any) => (
				<a href={text} target="_blank" rel="noreferrer">
					link out
				</a>
			),
		},
	];

	return (
		<Card>
			<Space direction="vertical">
				<strong>Gas Fees Spent</strong>
				<HeaderExtra
					setLoading={setLoading}
					setEndDate={setEndDate}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					handleSearchByDate={handleGetFees}
				/>
				{/* <CSVComponent
					url="admin/fees-collectedby-xend"
					startDate={startDate.format('YYYY-MM-DD')}
					endDate={endDate.format('YYYY-MM-DD')}
					otherParams={{}}
				/> */}
			</Space>

			<Card>
				<Responsive>
					<p>
						<strong>
							Total Gas Cost: ${' '}
							{gasSpent.totalGasCostUSD ? gasSpent.totalGasCostUSD : '0'}{' '}
						</strong>
					</p>
					<p>
						<strong>Total Gas Used: {gasSpent.totalGasUsed} </strong>{' '}
					</p>
					<Table
						loading={loading}
						columns={columns}
						dataSource={gasSpent?.record}
						pagination={false}
					/>
					<br />
					<Pagination
						onChange={handleGetFees}
						disabled={loading}
						pageSize={gasSpent.pageSize}
						current={gasSpent.currentPage}
						total={gasSpent.totalRecords}
						showSizeChanger={false}
					/>
				</Responsive>
			</Card>
		</Card>
	);
};

export default GasSpent;
