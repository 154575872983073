import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, InputNumber } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

interface IUpdateTrustScore {
	details: any;
	setDetails: Function;
}

const ScoreSection = styled.div`
	display: flex;
	gap: 2em;
`;
const UpdateTrustScore: FC<IUpdateTrustScore> = ({ details, setDetails }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [score, setScore] = useState(details?.profile?.trustScore);
	const updateTrustScore = async () => {
		setloading(true);
		try {
			const res: any = await baseuri.post(`admin/update-user-trustscore`, {
				userId: details?.profile?._id,
				trustScore: score,
			});

			if (res.status === 200) {
				message.success('trust score updated successfully');
				setDetails({ ...details, profile: res?.data?.data });
				setloading(false);
				setVisible(false);
			} else {
				message.info(res.data.message);
				setloading(false);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	// const confirm = () => {
	// 	Modal.confirm({
	// 		title: `Are you sure you want to update this user's kyc`,
	// 		onOk() {
	// 			updateTrustScore();
	// 		},
	// 	});
	// };
	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Update Trust Score
			</Button>
			<Modal
				width="350px"
				// className="modalPage"
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={updateTrustScore}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<p>Update Trust Score</p>
				<br />
				<br />
				<ScoreSection>
					<strong>Input Score(In %):</strong>
					<InputNumber
						min={1}
						max={100}
						defaultValue={details?.profile?.trustScore}
						onChange={(e) => setScore(e)}
					/>
				</ScoreSection>
			</Modal>
		</>
	);
};

export default UpdateTrustScore;
