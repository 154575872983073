import { Button, DatePicker, Pagination, Table, Tooltip } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';
import { referralStatus } from '../../../methods/transactions';
import warningGIF2 from '../../../assets/images/warningGIF2.gif';
import CSVComponent from '../../_ReUsable/CSVComponent';

interface IReferralDetials {
	username: string;
	refereeName: any;
	signupDate: any;
	referralCount: any;
}
const RefCountSection = styled.div`
	display: flex;
	flex-direction: column;
`;
export const FakeRef = styled.span`
	color: red;
	font-weight: 20px;
`;
const WarningIcon = styled.div`
	display: inline-flex;
	width: 15px;
	height: 15px;

	& .image3 {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
const ReferralDetailTable: FC<IReferralDetials> = ({
	username,
	refereeName,
	signupDate,
	referralCount,
}) => {
	const [start, setStartDate] = useState(moment('2021/10/01'));
	const [end, setEndDate] = useState(moment());
	const [loading, setLoading] = useState(false);
	const [allRefCount, setAllRefCount] = useState({
		data: {} as any,
		loading: false,
	});
	const [referral, setReferral] = useState({
		records: [],
		currentPage: 1,
		totalRecords: 0,
		totalReferralsByUser: 0,
	});

	const columns = [
		{
			title: '',
			dataIndex: 'refferalState',
			render: (text: any) => (
				<span
					className="material-icons"
					style={{ color: referralStatus(text).color, opacity: 0.5 }}
				>
					how_to_reg
				</span>
			),
		},
		{
			title: 'Username',
			dataIndex: 'userIdUsedRef',
			render: (user: any) => (
				<>
					<p>
						<Link to={`/user-profile/${user?._id}`}>@{user?.username}</Link>
					</p>
					<p>{user?.email}</p>
				</>
			),
		},
		{
			title: 'Device S/N',
			dataIndex: 'userIdUsedRef',
			render: (text: any) => <strong>{text?.deviceSerialNumber}</strong>,
		},
		{
			title: 'Status',
			dataIndex: 'refferalState',
			render: (text: any) => (
				<span style={{ color: referralStatus(text)?.color }}>
					<strong>{referralStatus(text)?.text}</strong>
				</span>
			),
		},
		{
			title: 'Joined',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
	];

	const handleSearchByDate = async (page = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/filter-referal-users', {
				params: {
					username,
					start: start.format('YYYY/MM/DD'),
					end: end.format('YYYY/MM/DD'),
					page,
				},
			});
			if (res.status === 200) {
				if (res.data.data?.records) {
					setReferral({ ...referral, ...res.data.data });
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (username) {
			handleSearchByDate(1);
			setReferral({ ...referral, totalReferralsByUser: referralCount });
		}
	}, [username]);
	useEffect(() => {
		setAllRefCount({
			...allRefCount,
			loading: true,
		});
		baseuri
			.get(`/admin/user-referral-info`, {
				params: {
					username,
					start: moment(start)?.format('YYYY/MM/DD'),
					end: moment(end)?.format('YYYY/MM/DD'),
				},
			})
			.then((res: any) => {
				setAllRefCount({ data: res?.data?.data, loading: false });
			});
	}, [referral]);

	return (
		<>
			<p>
				This user was referred by {refereeName ? `@${refereeName}` : 'No one'}
			</p>
			{allRefCount?.loading === true ? (
				<>Loading . . .</>
			) : (
				<RefCountSection>
					<strong>
						Referrals (Total: {allRefCount?.data?.totalReferrals}, Unverified:{' '}
						{allRefCount?.data?.unverified})
					</strong>
					<strong>
						Unique Referrals (Total: {allRefCount?.data?.numOfUniqueDeviceIds})
					</strong>
					{parseInt(allRefCount?.data?.totalReferrals, 10) -
						parseInt(allRefCount?.data?.numOfUniqueDeviceIds, 10) >
						0 && (
						<FakeRef>
							Fake Referrals (Total:{' '}
							{parseInt(allRefCount?.data?.totalReferrals, 10) -
								parseInt(allRefCount?.data?.numOfUniqueDeviceIds, 10)}
							)
							<sup>
								<WarningIcon>
									<Tooltip title="potential fraud" color="#a10909">
										<img className="image3" src={warningGIF2} alt="" />
									</Tooltip>
								</WarningIcon>
							</sup>
						</FakeRef>
					)}
				</RefCountSection>
			)}

			<br />
			<div>
				<DatePicker
					allowClear={false}
					format="ll"
					value={start}
					onChange={(date) =>
						date?.isSameOrAfter(signupDate) && setStartDate(date)
					}
					defaultValue={start}
				/>
				<DatePicker
					placeholder="End"
					allowClear={false}
					format="ll"
					value={end}
					onChange={(date) =>
						date?.isSameOrBefore(moment()) && setEndDate(date)
					}
					disabledDate={(current) => current && current > moment().endOf('day')}
					defaultValue={end}
				/>
				<Button onClick={() => handleSearchByDate()} loading={loading}>
					Search
				</Button>
			</div>
			<br />
			<CSVComponent
				url="admin/filter-referal-users"
				startDate={start.format('YYYY-MM-DD')}
				endDate={end.format('YYYY-MM-DD')}
				otherParams={{ username: username || '' }}
			/>
			<p>
				Search @{username}&apos;s referrals by date range. (
				{referral.totalRecords} referrals)
			</p>
			<br />
			<Table
				loading={loading}
				size="small"
				columns={columns}
				dataSource={referral.records}
				pagination={false}
			/>
			<br />
			<Pagination
				onChange={handleSearchByDate}
				current={referral.currentPage}
				total={referral.totalRecords}
				showSizeChanger={false}
			/>
		</>
	);
};

export default ReferralDetailTable;
