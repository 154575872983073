import {
	Button,
	Card,
	DatePicker,
	Divider,
	message,
	Pagination,
	Space,
	Table,
	Tabs,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import CSVComponent from '../../_ReUsable/CSVComponent';
import SelectUserType from '../XforceAgents/SelectUserType';

const HeaderExtraStyle = styled.section`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;

interface IHeaderExtra {
	setLoading: Function;
	startDate: any;
	setStartDate: Function;
	endDate: any;
	setEndDate: Function;
	handleSearchByDate: Function;
}
const HeaderExtra: FC<IHeaderExtra> = ({
	setLoading,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleSearchByDate,
}) => {
	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, []);

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date) =>
							date?.isSameOrBefore(endDate) && setStartDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={startDate}
						defaultValue={startDate}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={endDate}
						onChange={(date) =>
							date?.isSameOrAfter(startDate) && setEndDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={endDate}
					/>
				</div>
				<div>
					<Button type="primary" onClick={() => handleSearchByDate()}>
						Search
					</Button>
				</div>
			</div>
		</HeaderExtraStyle>
	);
};

const RefReport = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(moment('2021-10-01'));
	const [endDate, setEndDate] = useState(moment());
	const [userType, setUserType] = useState({} as any);
	const [query, setQuery] = useState({
		page: 1,
		userType: '',
	});
	const [users, setUsers] = useState({
		downloadLink: '',
		result: [],
		currentPage: 1,
		totalRecords: 0,
		totalPages: 0,
		pageSize: 0,
	});

	const handleGetReport = async (page = 1) => {
		setUsers({
			downloadLink: '',
			result: [],
			currentPage: 1,
			totalRecords: 0,
			totalPages: 0,
			pageSize: 0,
		});
		setLoading(true);
		let res: any;
		try {
			res = await baseuri.get(`admin/get-usertype-referals`, {
				params: {
					userType: userType?._id,
					start: startDate.format('YYYY-MM-DD'),
					end: endDate.format('YYYY-MM-DD'),
					page,
				},
			});
			const result: any = await res?.data?.data?.result
				?.filter((a: any) => a !== null)
				?.map((n: any) => {
					return {
						...n,
						fakeRef:
							parseInt(n?.totalReferred, 10) - parseInt(n?.uniqueDevice, 10),
					};
				});

			if (res.status === 200) {
				setUsers({ ...users, ...res.data.data, result });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};

	useEffect(() => {
		if (userType._id) {
			setLoading(true);
			handleGetReport();
		}
	}, [query, userType]);

	const columns = [
		{
			title: 'Username',
			key: 'username',
			render: (text: any) => <strong>{text?.username}</strong>,
		},
		{
			title: 'Email',
			key: 'email',
			render: (text: any) => <strong>{text?.email}</strong>,
		},
		{
			title: 'Total Referrals',
			key: 'totalReferred',
			render: (text: any) => <strong>{text?.totalReferred}</strong>,
		},
		{
			title: 'Unique Referrals',
			key: 'uniqueDevice',
			render: (text: any) => <strong>{text?.uniqueDevice}</strong>,
		},
		{
			title: 'UnVerified Referrals',
			key: 'unverified',
			render: (text: any) => <strong>{text?.unverified}</strong>,
		},
		{
			title: 'Fake Referrals',
			key: 'fakeRef',
			render: (text: any) => (
				<strong>
					{text?.fakeRef === 0 ? (
						<p style={{ color: 'green' }}>{text?.fakeRef}</p>
					) : (
						<p style={{ color: 'red' }}>{text?.fakeRef}</p>
					)}
				</strong>
			),
		},
	];

	// const getCsvData = () => {
	// 	const StaticURL = process.env.REACT_APP_BASE_URI?.replace('/v1/', '');
	// 	const win = window.open(
	// 		`${StaticURL}/static/front/downloads/${users?.downloadLink}`,
	// 		'_blank'
	// 	);
	// 	win?.focus();
	// };
	return (
		<FullLayout pageTitle="Referral Report">
			<Space>
				<HeaderExtra
					setLoading={setLoading}
					setEndDate={setEndDate}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					handleSearchByDate={handleGetReport}
				/>
				<SelectUserType userType={userType} setUserType={setUserType} />
			</Space>
			<Divider />
			{/* <Button
				type="primary"
				disabled={!users?.downloadLink}
				onClick={getCsvData}
			>
				Download CSV
			</Button> */}
			<CSVComponent
				url="admin/get-usertype-referals"
				startDate={startDate.format('YYYY-MM-DD')}
				endDate={endDate.format('YYYY-MM-DD')}
				otherParams={{ userType: userType?._id }}
			/>
			<Card>
				<Tabs
					defaultActiveKey=""
					onChange={(key) => setQuery({ ...query, userType: key })}
				>
					<Tabs.TabPane tab="Referral_Report" key="1">
						<div>
							{/* <p>
								<strong>{users.totalRecords} records</strong>
							</p> */}

							<Table
								loading={loading}
								columns={columns}
								dataSource={users?.result}
								pagination={false}
							/>

							<br />
							<Pagination
								onChange={handleGetReport}
								disabled={loading}
								pageSize={users.pageSize}
								current={users.currentPage}
								total={users.totalRecords}
								showSizeChanger={false}
							/>
						</div>
					</Tabs.TabPane>
				</Tabs>{' '}
			</Card>
		</FullLayout>
	);
};

export default RefReport;
