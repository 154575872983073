import FeesRecords from './Settings/Fees/FeesRecords';

const Financials = () => {
	return (
		<>
			<h1>Financials</h1>

			<FeesRecords />
		</>
	);
};

export default Financials;
