import { Button, message, Modal, Table } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import UpdateUserType from './UpdateUserType';

interface IDeleteUserType {
	id: any;
}
const DeleteUserType: FC<IDeleteUserType> = ({ id }) => {
	const [loading, setloading] = useState(false);

	const deleteUserType = async () => {
		try {
			setloading(true);
			const res = await baseuri.delete(`/admin/user-types/delete-user-type`, {
				params: {
					id,
				},
			});
			if (res.status === 200) {
				setloading(false);
				message.success('UserType deleted successfully');
			}
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete this UserType?',
			onOk() {
				deleteUserType();
			},
		});
	};

	return (
		<Button
			danger
			shape="round"
			size="small"
			loading={loading}
			onClick={confirm}
		>
			Delete UserType
		</Button>
	);
};

interface IAllUserTypes {
	userTypeList: any;
}
const AllUserTypes: FC<IAllUserTypes> = ({ userTypeList }) => {
	const [visible, setVisible] = useState(false);

	const tableColumns = [
		{
			title: 'UserType',
			key: 'name',
			render: (text: any) => (
				<p
					style={{
						fontSize: '0.8rem',
						color: '#969696',
						marginTop: 5,
					}}
				>
					{text?.name}
				</p>
			),
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => <UpdateUserType details={text} />,
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => <DeleteUserType id={text._id} />,
		},
	];

	return (
		<>
			<Button
				type="primary"
				shape="round"
				ghost
				onClick={() => setVisible(true)}
			>
				All UserTypes
			</Button>
			<Modal
				title="All User Types"
				visible={visible}
				onCancel={() => setVisible(false)}
				width={800}
			>
				<Table columns={tableColumns} dataSource={userTypeList} />
			</Modal>
		</>
	);
};

export default AllUserTypes;
