import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IUpdateUserType {
	details: any;
}

const UpdateLevel: FC<IUpdateUserType> = ({ details }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [levelDetails, setInputDetails] = useState({
		levelName: details?.levelName,
		monthlyWithdrawal: Number(details?.monthlyWithdrawal),
		dailyWithdrawal: Number(details?.dailyWithdrawal),
		id: details?._id,
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(
				`admin/kycLevel/update-kycLevel?id=${details?._id}`,
				levelDetails
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('level updated Successfully');
			} else {
				setloading(false);
				message.error(res?.message);
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error('something went wrong');
		}
	};

	return (
		<>
			<Button shape="round" size="small" onClick={() => setVisible(true)}>
				Update
			</Button>
			<Modal
				title="Update Level"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Level Name :{' '}
					<Input
						placeholder="input level name"
						value={levelDetails?.levelName}
						onChange={(e: any) =>
							setInputDetails({ ...levelDetails, levelName: e.target.value })
						}
					/>
				</p>
				<p>
					Maximum Daily Withdrawal (in dollars) :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.dailyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								dailyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
				<p>
					Maximum Monthly Withdrawal (in dollars):{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.monthlyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								monthlyWithdrawal: Number(e.target.value),
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default UpdateLevel;
