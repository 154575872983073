import { Badge, Button, Card, Drawer, message, Modal } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import baseuri from '../../methods/baseuri';
import retrieveProcessingList from '../../methods/processing';

interface IUpdatePlanShares {
	hash: string;
	planId: string;
	batchId: string;
}
const UpdatePlanShares: FC<IUpdatePlanShares> = ({ hash, planId, batchId }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const updatePlan = async () => {
		Modal.confirm({
			title: 'You are about to update this plan',
			onOk: async () => {
				try {
					setLoading(true);
					const res = await baseuri.post('/advanced/resolve-plan', {
						hash,
						planId,
						batchId,
					});
					if (res.status === 200) {
						message.success('Plan updated');
						dispatch(retrieveProcessingList());
					}

					setLoading(false);
				} catch (e) {
					setLoading(false);
				}
			},
		});
	};

	return (
		<Button
			size="small"
			loading={loading}
			disabled={loading}
			onClick={() => updatePlan()}
		>
			Update Plan
		</Button>
	);
};

interface IViewItems {
	items: Array<any>;
	itemHash: string;
	batch: string;
	type: any;
}
const ViewItems: FC<IViewItems> = ({ items, itemHash, batch, type }) => {
	const [visible, setVisible] = useState(false);

	const checkIfUpdated = (single: any) => {
		const deposits = single?.depositTransactionHash;

		const filter = deposits?.filter(
			(x: any) => x === `https://bscscan.com/tx/${itemHash}`
		);
		if (filter?.length === 0) {
			return true;
		}
		return false;
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>View Items</Button>

			<Modal
				footer={null}
				title="View Details"
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				{items.map((item) => (
					<>
						<Card>
							<p>Queue Amount: {item?.amount} BUSD </p>
							<hr />
							<p>Plan: {Number(item.planType) === 0 ? 'Flexible' : 'Fixed'}</p>
							<p>
								{item?.plan?.planName} by @{item?.userId?.username}
							</p>
							<br />
							{type === 'deposit' && checkIfUpdated(item?.plan) && (
								<UpdatePlanShares
									hash={itemHash}
									batchId={batch}
									planId={item?.planId}
								/>
							)}
						</Card>
						<br />
					</>
				))}
			</Modal>
		</>
	);
};

interface IProcessingList {
	name: string;
}
const ProcessingList: FC<IProcessingList> = ({ name }) => {
	const { list } = useSelector((store: any) => store.processList);

	const [visible, setVisible] = useState(false);
	const [active, setActive] = useState<Array<any>>([]);

	const grown = (items: any) => {
		for (let i = 0; i < items.length; i++) {
			const cc = items[i];
			console.info(cc.walletId);
		}
	};

	const identifyIssues = () => {
		const allWithdraws = list.filter((x: any) => x.type === 'withdraw');
		for (let i = 0; i < allWithdraws.length; i++) {
			const current = allWithdraws[i];
			if (current.items.length > 1) {
				grown(current.items);
				console.info(' for => ', current.batch);
			}
		}
	};

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		const pending = list.filter((x: any) => x.status == 0);
		if (pending.length > 0) {
			setActive(pending);
		}

		identifyIssues();
	}, [list]);

	return (
		<>
			<Badge count={active.length}>
				<Button onClick={() => setVisible(true)} type="text">
					{name}
				</Button>
			</Badge>

			<Drawer
				title="Processing List by Admin"
				visible={visible}
				onClose={() => setVisible(false)}
			>
				{list.map((item: any) => (
					<>
						<Card>
							<div>
								{item.type}: status: {item.status}
							</div>
							<div>created {moment(item.createdAt).format('lll')}</div>
							<br />
							<ViewItems
								items={item.items}
								itemHash={item.transactionHash}
								type={item.type}
								batch={item.batch}
							/>
						</Card>
						<br />
					</>
				))}
			</Drawer>
		</>
	);
};

export default ProcessingList;
