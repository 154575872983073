import { Button, Divider, message } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import { getCurrentValue } from '../../methods/utils';
import AdvancedSectionLayout from '../Layouts/Advanced';

const InlineInfo = styled.div`
	display: inline-block;
	margin-right: 20px;
`;
interface IFixShareBalanceIssue {
	batch: any;
	planId: string;
}
const FixShareBalanceIssue: FC<IFixShareBalanceIssue> = ({ batch, planId }) => {
	const [loading, setLoading] = useState(false);

	const resolvePlan = async () => {
		try {
			setLoading(true);
			const res = await baseuri.post('/advanced/resolve-plan', {
				batchId: batch._id,
				hash: batch.transactionHash,
				planId,
			});
			if (res.status === 200) {
				message.success('resolving of plan done');
			}
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	return (
		<>
			<div>
				<p>action: {batch.action}</p>
				<p>batch id: {batch._id}</p>
				<p>hash: {batch.transactionHash}</p>
				<p>created: {moment(batch.createdAt).format('lll')}</p>
				<br />
				<Button
					loading={loading}
					disabled={loading}
					onClick={() => resolvePlan()}
				>
					Use Batch to Fix
				</Button>
			</div>
			<Divider />
		</>
	);
};

const SavingsPlan = () => {
	const params: any = useParams();

	const { sharePrices } = useSelector((store: any) => store.general);
	const [batches, setBatches] = useState<Array<any>>([]);
	const [plan, setPlan] = useState<any>({});
	const [planReward, setPlanReward] = useState();

	const findBatches = async () => {
		try {
			const res: any = await baseuri.get(
				'/advanced/batches-plan-was-involved',
				{ params: { planId: params.id, planType: params.type } }
			);
			if (res.status === 200) {
				setBatches(res.data?.data?.batches);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const getPlanDetails = async () => {
		try {
			const res: any = await baseuri.get('/admin/savings-plan-details', {
				params: { planId: params.id, planType: params.type },
			});
			if (res.status === 200) {
				setPlan(res.data.data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const getPlanRewardInfo = async (planId: string) => {
		try {
			const res: any = await baseuri.get('/advanced/xend-reward-record', {
				params: { planId },
			});
			if (res.status === 200) {
				setPlanReward(res.data.data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		getPlanDetails();
	}, []);

	useEffect(() => {
		getPlanRewardInfo(plan._id);
	}, [plan]);

	console.info(plan);
	console.info({ planReward });

	return (
		<AdvancedSectionLayout noMetamask>
			<p>
				<strong>{plan.planName}</strong>
			</p>
			<Divider />
			<div>
				<p>Created</p>
				<p>{plan.createdAt && moment(plan.createdAt).format('lll')}</p>
			</div>
			<br />
			<div>
				<p>Expiring</p>
				<p>{plan.lockPeriod && moment.unix(plan.lockPeriod).format('lll')}</p>
			</div>

			<Divider />

			<div>
				<div style={{ display: 'inline-block', marginRight: 20 }}>
					<p>Current Value</p>
					<p>
						<strong>
							{getCurrentValue(
								plan.shareBalance,
								plan.protocolName,
								sharePrices
							)}{' '}
							BUSD
						</strong>
					</p>
				</div>
				<div style={{ display: 'inline-block' }}>
					<p>Share balance</p>
					<p>{plan.shareBalance}</p>
				</div>
			</div>

			<Divider />

			<div>
				<p>Plan Reward</p>
				<div>
					<InlineInfo>PAID</InlineInfo>
					<InlineInfo>0.01 USD</InlineInfo>
				</div>
			</div>

			<Divider />
			<p>
				{plan.userId?.username} | {plan.userId?.email} |{' '}
				<Link to={`/user-profile/${plan.userId?._id}`}>profile</Link>
			</p>
			<Divider />
			<div>
				<Button onClick={() => findBatches()}>Find Batches</Button>
			</div>
			<Divider />
			<div>
				{batches.map((batch) => (
					<FixShareBalanceIssue batch={batch} planId={params.id} />
				))}
			</div>
		</AdvancedSectionLayout>
	);
};

export default SavingsPlan;
