import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import baseuri from '../../../methods/baseuri';

interface IChangeRequestStatus {
	item: any;
}
const ChangeRequestStatus = ({ item }: IChangeRequestStatus) => {
	const { authRole } = useSelector((store: any) => store.auth);

	const [visible, setVisible] = useState(false);

	const changeStatus = (status: any) => {
		baseuri
			.put('/admin/withdrawal-request-update', { status, requestId: item._id })
			.then((res: any) => {
				console.info(res.data.data);
				message.success('Status changed!');
				setVisible(false);
			})
			.catch((e) => {
				message.error('Status change FAILED');
				console.error(e);
			});
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Change Status</Button>

			<Modal
				visible={visible}
				title="Change Request Status"
				onCancel={() => setVisible(false)}
			>
				<p>
					Make sure the funds robot won&apos;t be affected by the change
					you&apos;re about to make
				</p>
				<br />
				<Button onClick={() => changeStatus('0')}>Set to Pending</Button>
				<Button onClick={() => changeStatus('7')}>Set to Hold</Button>
				{authRole && authRole.name === 'Super Admin' && (
					<>
						<Button onClick={() => changeStatus('8')}>Set to Review</Button>
						<Button onClick={() => changeStatus('5')}>Set to Processing</Button>
						<Button onClick={() => changeStatus('6')}>Set to Retry</Button>
					</>
				)}
			</Modal>
		</>
	);
};

export default ChangeRequestStatus;
