import {
	Avatar,
	Card,
	Divider,
	message,
	Select,
	Table,
	Tag,
	Tooltip,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ResponsiveSection, SearchSection } from '.';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { Country, genderEnum, statusColor } from '../../../methods/utils';
import { TableProfile } from '../Transactions/Transactions';
import { FakeRef } from '../userProfile/ReferralDetailTable';
import ImageComponent from './ImageComponent';
import NextKyc from './NextKyc';
import RejectKYC from './RejectKYC';

const MyCard = styled(Card)`
	border-radius: 10px;
`;
const Level1Section = styled.div`
	min-width: 250px;

	& .sus {
		color: red;
	}
`;

const MainColumn = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 0.5em;
`;

const Levels = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 1em;
	min-width: 200px;
`;

const SusLink = styled.div`
	display: flex;
	gap: 2em;
	min-width: 220px;
	& .sus {
		color: red;
		cursor: pointer;
	}
`;
const KYCHistory = () => {
	const { id } = useParams<{ id: string }>();
	const [refresh, setRefresh] = useState(0);
	const [data, setData] = useState({} as any);
	const [kycHistory, setKycHistory] = useState({} as any);
	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);

	const history = useHistory();

	const gateWays = [
		{ name: 'https://cf-ipfs.com/ipfs/', value: 'https://cf-ipfs.com/ipfs/' },
		{ name: 'https://ipfs.io/ipfs/', value: 'https://ipfs.io/ipfs/' },
		{
			name: 'https://cloudflare-ipfs.com/ipfs/',
			value: 'https://cloudflare-ipfs.com/ipfs/',
		},
		{
			name: 'https://gateway.ipfs.io/ipfs/',
			value: 'https://gateway.ipfs.io/ipfs/',
		},
		{
			name: 'https://gateway.pinata.cloud/ipfs/',
			value: 'https://gateway.pinata.cloud/ipfs/',
		},
	];

	const approve = async (userId: string, url: string) => {
		try {
			const res: any = await baseuri.post(url, {
				id: userId,
				kycStatus: 'approved',
			});

			if (res.status === 200) {
				message.success(`Approved successfully `);
				const k = data.record.filter((n: any) => n?.userId?._id !== userId);
				setData({
					...data,
					record: k,
				});
				setRefresh(Math.random());
			} else {
				message.error(res?.message);
			}
		} catch (e: any) {
			console.error(e);
			message.error('something went wrong');
		}
	};

	const getKycHistory = async () => {
		setKycHistory({ loading: true });
		try {
			const res: any = await baseuri.get('admin/kyc/rejected-kyc-records', {
				params: {
					userId: id,
				},
			});

			if (res.status === 200) {
				setKycHistory({ ...kycHistory, ...res.data.data, loading: false });
			} else {
				setKycHistory({ loading: false });
			}
		} catch (error) {
			setKycHistory({ loading: false });
		}
	};
	const getSingleKYC = async () => {
		setData({ loading: true });
		try {
			const res: any = await baseuri.get('admin/kyc/getkyc-by-level', {
				params: {
					userId: id,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data, loading: false });
			} else {
				setData({ loading: false });
			}
		} catch (error) {
			setData({ loading: false });
		}
	};

	useEffect(() => {
		getKycHistory();
		getSingleKYC();
	}, [refresh, id]);

	const columns = [
		{
			title: 'User',
			key: '_id',
			width: 150,
			render: (text: any) => (
				// eslint-disable-next-line no-underscore-dangle
				<TableProfile>
					<div className="avatarSection">
						{text.profileUrl ? (
							<Avatar src={text?.userId?.profileUrl} />
						) : (
							<Avatar className="avatar">
								{' '}
								{text?.userId?.username?.charAt(0)}
							</Avatar>
						)}
						<Link
							title="View Profile"
							to={`/user-profile/${text.userId._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p>
								<strong>@{text?.userId?.username}</strong>
							</p>
							<p>{text?.userId?.email}</p>
						</Link>
					</div>
					<br />
					<div>
						<p>
							FULL NAME: <span>{text?.userId?.fullName || '---'}</span>
						</p>
						<p>
							PHONE NO: <span>{text?.userId?.phoneNo || '---'}</span>
						</p>
						<br />
						<p>
							Referred by: <strong>{text?.userId?.refereeName || '---'}</strong>
						</p>
					</div>
				</TableProfile>
			),
		},
		{
			title: 'Level 1',
			key: '9024352',
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{' '}
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
					<p>
						Device Id: <strong>{text?.deviceId || 'N/A'}</strong>
					</p>
					<p>
						Date Create:{' '}
						<strong>{moment(text?.createdAt).format('lll') || 'N/A'}</strong>
					</p>
					<p>
						Date Updated:{' '}
						<strong>{moment(text?.updatedAt).format('lll') || 'N/A'}</strong>
					</p>
					<br />
					<br />
					<p className={data?.blacklistDetails?.blacklisted ? 'sus' : ''}>
						BlackListed User/Device:{' '}
						<strong>
							{data?.blacklistDetails?.blacklisted ? 'Yes' : 'No'}
						</strong>
					</p>
					<SusLink
						className={Number(data?.kycsRegisteredWithDevice) > 1 ? ' sus' : ''}
					>
						<>
							KYCs with same device:{' '}
							<strong>{data?.kycsRegisteredWithDevice || 0}</strong>
						</>
						{Number(data?.kycsRegisteredWithDevice) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="processing"
								onClick={() =>
									history.push(`/kyc-suspects?deviceId=${text?.deviceId}`)
								}
							>
								View
							</Tag>
						)}
					</SusLink>
					<SusLink
						className={Number(data?.duplicatephoneNumber) > 1 ? ' sus' : ''}
					>
						<>
							KYCs with same Phone Number:{' '}
							<strong>{data?.duplicatephoneNumber || 0}</strong>
						</>
						{Number(data?.duplicatephoneNumber) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="warning"
								onClick={() =>
									history.push(`/kyc-suspects?phoneNumber=${text?.phoneNumber}`)
								}
							>
								view
							</Tag>
						)}
					</SusLink>
					<SusLink
						className={Number(data?.duplicateIdcardNumber) > 1 ? ' sus' : ''}
					>
						<>
							KYCs with same ID Number:{' '}
							<strong>{data?.duplicateIdcardNumber || 0}</strong>
						</>
						{Number(data?.duplicateIdcardNumber) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="error"
								onClick={() =>
									history.push(
										`/kyc-suspects?idcardNumber=${text?.idcardNumber}`
									)
								}
							>
								View
							</Tag>
						)}
					</SusLink>
					<SusLink
						className={Number(data?.userRegisteredWithDevice) > 1 ? ' sus' : ''}
					>
						<>
							Users with same device:{' '}
							<strong>{data?.userRegisteredWithDevice || 0}</strong>
						</>
						{Number(data?.userRegisteredWithDevice) > 1 && (
							<Tag
								style={{
									cursor: 'pointer',
								}}
								color="error"
								onClick={() => history.push(`/user-search/${text?.deviceId}`)}
							>
								View
							</Tag>
						)}
					</SusLink>
				</Level1Section>
			),
		},
		{
			title: 'Level 2',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Level 2 Status:{' '}
						<strong
							style={{
								color: `${statusColor(text?.userId?.kycLevelTwoStatus)}`,
							}}
						>
							{' '}
							{text?.userId?.kycLevelTwoStatus || 'N/A'}
						</strong>
					</p>
					<p>
						Last Updated by:{' '}
						<strong>
							{text?.userId?.KycLevelTwoApprovedBy?.email || 'N/A'}
						</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${selectedGateway}${text.idcardIpfsHash}`}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${selectedGateway}${text.selfieIpfsHash}`}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					<Levels>
						{text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/update-levelTwo-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels>
				</MainColumn>
			),
		},
	];
	const columns2 = [
		{
			title: 'Level 1',
			key: 9024352,
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
				</Level1Section>
			),
		},
		{
			title: 'Level 2',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Rejected by: <strong>{text?.rejectedBy?.email || 'N/A'}</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${selectedGateway}${text.idcardIpfsHash}`}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${selectedGateway}${text.selfieIpfsHash}`}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					{/* <Levels>
						{text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/update-levelTwo-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels> */}
				</MainColumn>
			),
		},
		{
			title: 'Rejection Message',
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Rejection Time:
						<strong>{moment(text?.rejectionTime).format('lll')}</strong>
					</p>
					<p>
						<strong>Rejection Reason</strong>
						<div>{text?.levelTwoRejectionReason}</div>
					</p>
					<p>
						<strong>Rejection Message</strong>
						<div>{text?.levelTwoRejectionMessage}</div>
					</p>
				</MainColumn>
			),
		},
	];
	return (
		<FullLayout
			pageTitle={
				data?.record &&
				`KYC Reject History for ${data?.record[0]?.userId?.email}`
			}
		>
			<SearchSection>
				<div>
					Select gateway :{' '}
					<Select
						// mode="multiple"
						placeholder="Select GateWay"
						// value={newsletter?.maillist}
						onChange={(e: any) => setSelectedGateway(e)}
						defaultValue="https://cf-ipfs.com/ipfs/"
						// style={{ width: '100%' }}
					>
						{gateWays?.map((gateway: any) => (
							<Select.Option key={gateway.name} value={gateway.value}>
								{gateway.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<NextKyc text="Next KYC" />
			</SearchSection>
			<br />
			<MyCard>
				<Table
					loading={data?.loading}
					columns={columns}
					dataSource={data.record}
					pagination={false}
					showHeader={false}
				/>
			</MyCard>
			<Divider />
			<ResponsiveSection>
				<strong style={{ color: 'red' }}>Rejected KYC History</strong>
				<Table
					loading={kycHistory?.loading}
					columns={columns2}
					dataSource={kycHistory.record}
					pagination={false}
				/>
			</ResponsiveSection>
		</FullLayout>
	);
};

export default KYCHistory;
