import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, Upload } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

interface IAddShopImage {
	userId: string;
}
const ImageContainer = styled.div`
	display: inline-flex;
`;

const ImageMain = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const UploadSection = styled.div``;
const AddShopImg: FC<IAddShopImage> = ({ userId }) => {
	const [uploadLoading, setUploadLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [shopImage, setSetImage] = useState({
		loading: false,
		imageUrl: '',
		imageFile: '',
	});

	function getBase64(img: any, callback: Function) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const beforeUpload = (file: any) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	};

	const handleChange = (info: any) => {
		setSetImage({ ...shopImage, loading: true });

		// Get this url from response in real world.
		getBase64(info.file.originFileObj, (imageUrl: any) =>
			setSetImage({
				imageUrl,
				loading: false,
				imageFile: info.file.originFileObj,
			})
		);
	};

	const uploadButton = (
		<div>
			{shopImage?.loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('shopUrl', shopImage?.imageFile);
		try {
			setUploadLoading(true);
			const res: any = await baseuri.put(
				`/admin/update-shopImage?userid=${userId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);

			if (res.status === 200) {
				setUploadLoading(false);
				setSetImage({
					imageUrl: '',
					loading: false,
					imageFile: '',
				});

				message.success('Image Uploaded Successfully');
			} else {
				setUploadLoading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setUploadLoading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<>
			<Button type="primary" onClick={() => setShowModal(true)}>
				UPDATE
			</Button>
			<Modal
				title="Upload Shop Image"
				visible={showModal}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleUpload}>
						<Button
							loading={uploadLoading}
							type="primary"
							disabled={!shopImage?.imageUrl}
						>
							Update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setShowModal(false)}
			>
				<UploadSection>
					Upload Shop Image :
					<ImageContainer>
						<Upload
							name="avatar"
							listType="picture-card"
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleChange}
						>
							{shopImage?.imageUrl ? (
								<ImageMain
									src={shopImage?.imageUrl}
									alt="avatar"
									className="actualImage"
									style={{ width: '100%' }}
								/>
							) : (
								uploadButton
							)}
						</Upload>
					</ImageContainer>
				</UploadSection>
			</Modal>
		</>
	);
};

export default AddShopImg;
