import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';
import SelectUserType from './SelectUserType';

const CreateNewAgent = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [userType, setUserType] = useState({} as any);
	const [email, setEmail] = useState('');

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(`/admin/assign-usertype`, {
				email: email?.trim().toLowerCase(),
				usertype: userType?._id,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Agent created Successfully');
			}
			setloading(false);
			message.info(res.data.message);
		} catch (e: any) {
			setloading(false);
			message.error('something went wrong');
		}
	};
	return (
		<>
			<Button
				type="primary"
				shape="round"
				ghost
				onClick={() => setVisible(true)}
			>
				Create new Agent
			</Button>
			<Modal
				title="Create New Agent"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button
							loading={loading}
							type="primary"
							disabled={!(email && userType?.name)}
						>
							Create
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<Input
					addonAfter={
						<SelectUserType userType={userType} setUserType={setUserType} />
					}
					defaultValue="...email"
					placeholder="input email"
					value={email}
					onChange={(e: any) => setEmail(e.target.value)}
				/>
				{/* <p>
					UserType Name :{' '}
					<Input
						placeholder="input UserType name"
						value={inputDetails?.name}
						onChange={(e: any) =>
							setInputDetails({ ...inputDetails, name: e.target.value })
						}
					/>
				</p> */}
			</Modal>
		</>
	);
};

export default CreateNewAgent;
