import { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Divider } from 'antd';
// import { commas, shortAmount } from '../../methods/utils';
import BusdIcon from '../../binance-usd-busd-logo.svg';
import XendIcon from '../../xend.svg';
import baseuri from '../../methods/baseuri';

const XendUserBalanceStyle = styled.section`
	& .balance {
		display: flex;
		flex-direction: column;

		& > * {
			margin-bottom: 10px;
			display: flex;
			margin-right: 10px;
			font-weight: 600;

			& > *:first-child {
				margin-right: 5px;
			}
		}
	}
`;

const XendUsersBalance = () => {
	const [details, setDetails] = useState({} as any);
	// const [loading, setLoading] = useState(false);
	// const [totalInUSD, setTotalInUSD] = useState(0);

	const getxenduser = async () => {
		try {
			const res: any = await baseuri.get('/admin/search-details', {
				params: { id: '61b0d0f47c12186751196256' },
			});
			if (res.status === 200) {
				setDetails({ ...details, ...res.data.data });
			}
		} catch (e) {
			console.error(e);
		}
	};

	// const pendingPlanRewardsToPay = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const res: any = await baseuri.get(
	// 			'/admin/unpaid-fixed-plan-xend-reward'
	// 		);
	// 		if (res.status === 200) {
	// 			const { data } = res.data;
	// 			if (Array.isArray(data)) {
	// 				const total = data.reduce(
	// 					(acc, item) => acc + Number(item.totalInUSD),
	// 					0
	// 				);
	// 				setTotalInUSD(total);
	// 			}
	// 		}
	// 		setLoading(false);
	// 	} catch (e) {
	// 		console.error(e);
	// 		setLoading(false);
	// 	}
	// };

	useEffect(() => {
		getxenduser();
		// pendingPlanRewardsToPay();
	}, []);

	return (
		<XendUserBalanceStyle>
			<p>
				<strong>Xend Users Balance ({details?.profile?.email})</strong>
			</p>
			<p style={{ opacity: 0.7, fontSize: 14 }}>
				Account that pays referral BUSD rewards and fixed plan XEND rewards
			</p>
			<br />
			<section className="balance">
				{details?.balances?.map((balance: any) => (
					<>
						<div>
							<img src={BusdIcon} alt="" width={20} />
							<p>{balance?.busd} BUSD</p>
						</div>
						<div>
							<img src={XendIcon} alt="" width={20} />
							<p>{Number(balance?.xend)} XEND</p>
						</div>
					</>
				))}
				{/* <div>
					<img src={BusdIcon} alt="" width={20} />
					<p>{commas(shortAmount(details?.busd))} BUSD</p>
				</div>
				<div>
					<img src={XendIcon} alt="" width={20} />
					<p> {commas(shortAmount(details?.xend))} XEND</p>
				</div> */}
			</section>
			{/* <Divider />
			<div>
				<p>
					<strong>Rewards to be paid</strong>
				</p>
				<p>
					A total of <strong>{totalInUSD} USD</strong> worth of XEND to be paid
					as rewards for savings plans.
				</p>
				<p>{loading}</p>
			</div> */}
		</XendUserBalanceStyle>
	);
};

export default XendUsersBalance;
