import { Card, Divider, Input, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';

const XendBridgeSellRequests = () => {
	const [searchResult, setSearchResult] = useState<Array<any>>([]);
	const [reference, setReference] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	const search = async () => {
		try {
			if (!reference) {
				message.error('Input a reference');
			}

			setLoading(true);

			const res: any = await baseuri.get(
				'/advanced/search-xend-bridge-sell-request',
				{ params: { reference } }
			);
			const result: any = res.data;

			if (res.status === 200 && result.data) {
				message.success('Search Done');
				setSearchResult(result.data);
				if (result.data?.length === 0) {
					message.info('No data with order reference');
				}
			}

			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	return (
		<AdvancedSectionLayout>
			<p style={{ marginBottom: 10 }}>Find XendBridge Sell Request</p>

			<Input.Search
				size="large"
				loading={loading}
				disabled={loading}
				onChange={(e) => setReference(e.target.value)}
				onSearch={search}
				enterButton
				placeholder="Search with Order Reference"
			/>

			<Divider />

			<Card>
				{searchResult.map((result) => (
					<div>
						<div>
							<p>Order Reference:</p>
							<p>{result.orderReference}</p>
						</div>
						<hr style={{ opacity: 0.2 }} />
						<div>
							<p>User:</p>
							<p>{result.userEmail}</p>
						</div>
						<hr style={{ opacity: 0.2 }} />
						<div>
							<p>Amount</p>
							<p>
								{result.amount} {result.payInCurrency}
							</p>
						</div>
						<hr style={{ opacity: 0.2 }} />
						<div>
							<p>Destination</p>
							<p>{result.destinationAddress}</p>
						</div>
						<hr style={{ opacity: 0.2 }} />
						<div>
							<p>
								Created: {moment(result.createdAt).format('lll')} | Updated:{' '}
								{moment(result.updatedAt).format('lll')}
							</p>
						</div>
					</div>
				))}
			</Card>
		</AdvancedSectionLayout>
	);
};

export default XendBridgeSellRequests;
