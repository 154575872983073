import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { isAuth } from './Authenticated';

/**
 * Any component wrapped should be authenticated to work
 * @param Component
 * @returns
 */
function Authorize(Route: any) {
	return class Protect extends React.PureComponent {
		render() {
			const { response, data } = isAuth();
			if (response) {
				// check if user is verified or has completed profile

				if (data.enable2FA) {
					if (data.token) {
						return <Route />;
					}
					createBrowserHistory().push('/login');
					createBrowserHistory().go(0);
					return null;
				}
				const { pathname } = window.location;

				if (pathname !== '/enable-2fa') {
					createBrowserHistory().push('/enable-2fa');
					createBrowserHistory().go(0);
				}
				return <Route />;
			}
			createBrowserHistory().push('/login');
			createBrowserHistory().go(0);
			return null;
		}
	};
}

export default Authorize;
