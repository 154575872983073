import { FC, useEffect, useState } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { commas } from '../../../methods/utils';
// import totalUsersIcon from "../../../assets/images/totalUsersIcon.jpg";
// import signUpsTodayIcon from "../../../assets/images/signUpsTodayIcon.png";
// import verifiedUserIcon from "../../../assets/images/verifiedUserIcon.png";
// import unverifiedUserIcon from "../../../assets/images/unverifiedUserIcon.png";

interface IUserStats {
	data: any;
}
const UsersStatCard: FC<IUserStats> = ({ data }) => {
	const { Title } = Typography;

	const [details, setDetails] = useState({
		dailySignup: 0,
		totalReferalPayout: 0,
		unVerifiedUsers: 0,
		users: 0,
		verifiedUsers: 0,
	});

	useEffect(() => {
		setDetails({ ...details, ...data });
	}, [data]);

	return (
		<Row gutter={[16, 24]} justify="space-between">
			<Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
				<Card bordered={false} className="criclebox ">
					<div className="number">
						<Row align="middle" gutter={[24, 0]}>
							<Col xs={18}>
								<span>Total Users</span>
								<Title level={3}>
									{commas(details.users, 2)}
									{/* <small className="bnb2">10%</small> */}
								</Title>
							</Col>
							<Col xs={6}>
								<div className="icon-box">
									{/* <img src={totalUsersIcon} alt="" className="icon-box-img" /> */}
								</div>
							</Col>
						</Row>
					</div>
				</Card>
			</Col>

			<Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
				<Card bordered={false} className="criclebox ">
					<div className="number">
						<Row align="middle" gutter={[24, 0]}>
							<Col xs={18}>
								<span>SignUp Today</span>
								<Title level={3}>
									{commas(details.dailySignup, 2)}{' '}
									{/* <small className="bnb2">10%</small> */}
								</Title>
							</Col>
							<Col xs={6}>
								<div className="icon-box">
									{/* <img src={signUpsTodayIcon} alt="" className="icon-box-img" /> */}
								</div>
							</Col>
						</Row>
					</div>
				</Card>
			</Col>

			<Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
				<Card bordered={false} className="criclebox ">
					<div className="number">
						<Row align="middle" gutter={[24, 0]}>
							<Col xs={18}>
								<span>Verified User</span>
								<Title level={3}>
									{commas(details.verifiedUsers, 2)}
									{/* <small className="bnb2">10%</small> */}
								</Title>
							</Col>
							<Col xs={6}>
								<div className="icon-box">
									{/* <img src={verifiedUserIcon} alt="" className="icon-box-img"/> */}
								</div>
							</Col>
						</Row>
					</div>
				</Card>
			</Col>

			<Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
				<Card bordered={false} className="criclebox ">
					<div className="number">
						<Row align="middle" gutter={[24, 0]}>
							<Col xs={18}>
								<span>Unverified Users</span>
								<Title level={3}>
									{commas(details.unVerifiedUsers, 2)}
									{/* <small className="bnb2">10%</small> */}
								</Title>
							</Col>

							<Col xs={6}>
								<div className="icon-box">
									{/* <img src={unverifiedUserIcon} alt="" className="icon-box-img" /> */}
								</div>
							</Col>
						</Row>
					</div>
				</Card>
			</Col>
		</Row>
	);
};

export default UsersStatCard;
