/* eslint-disable no-param-reassign */
import {
	Avatar,
	Divider,
	Input,
	Pagination,
	Select,
	Space,
	Table,
	Tag,
	Tooltip,
} from 'antd';
import moment from 'moment';
// import { Option } from 'antd/lib/mentions';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, Link } from 'react-router-dom';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { Country, genderEnum, statusColor } from '../../../methods/utils';
import { TableProfile } from '../Transactions/Transaction3';
import { FakeRef } from '../userProfile/ReferralDetailTable';
import CreateLevel from './CreateLevel';
import ImageComponent from './ImageComponent';
import ViewKYCLevels from './ViewKYCLevels';
import NextKyc from './NextKyc';
import RejectKYC from './RejectKYC';

const MainColumn = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 0.5em;
`;
const Level1Section = styled.div`
	min-width: 250px;

	& .sus {
		color: red;
	}
`;
const Levels = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 1em;
	min-width: 200px;
`;
export const ResponsiveSection = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;
`;

export const SearchSection = styled.div`
	display: flex;
	justify-content: space-between;
`;
const UsersKYC = () => {
	const [refresh, setRefresh] = useState(0);
	const [loading, setLoading] = useState(false);
	const [levelList, setLevelList] = useState([] as any);
	const [params, setParams] = useState({
		kycLevel: '',
		kycStatus: 'submitted',
	});
	const [isSelected, setIsSelected] = useState('LV2S');
	const [data, setData] = useState({
		totalRecords: 0,
		pageSize: 0,
		currentPage: 0,
		totalPage: 0,
		record: [],
		kycCount: {} as any,
	} as any);
	const [selectedGateway, setSelectedGateway] = useState(
		'https://cf-ipfs.com/ipfs/'
	);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const history = useHistory();
	const gateWays = [
		{ name: 'https://cf-ipfs.com/ipfs/', value: 'https://cf-ipfs.com/ipfs/' },
		{ name: 'https://ipfs.io/ipfs/', value: 'https://ipfs.io/ipfs/' },
		{
			name: 'https://cloudflare-ipfs.com/ipfs/',
			value: 'https://cloudflare-ipfs.com/ipfs/',
		},
		{
			name: 'https://gateway.ipfs.io/ipfs/',
			value: 'https://gateway.ipfs.io/ipfs/',
		},
		{
			name: 'https://gateway.pinata.cloud/ipfs/',
			value: 'https://gateway.pinata.cloud/ipfs/',
		},
	];
	// const approve = async (userId: string, url: string) => {
	// 	try {
	// 		const res: any = await baseuri.post(url, {
	// 			id: userId,
	// 			kycStatus: 'approved',
	// 		});

	// 		if (res.status === 200) {
	// 			message.success(`Approved successfully `);
	// 			const k = data.record.filter((n: any) => n?.userId?._id !== userId);
	// 			setData({
	// 				...data,
	// 				record: k,
	// 			});
	// 			setRefresh(Math.random());
	// 		} else {
	// 			message.error(res?.message);
	// 		}
	// 	} catch (e: any) {
	// 		console.error(e);
	// 		message.error('something went wrong');
	// 	}
	// };
	const getKyc = async (page = 1, pageSize = 20) => {
		const userId = urlParams.get('userId');
		const email = urlParams.get('email');

		setLoading(true);
		setData({
			totalRecords: 0,
			pageSize: 0,
			currentPage: 0,
			totalPage: 0,
			record: [],
			kycCount: {} as any,
		});
		try {
			const res: any = await baseuri.get('admin/kyc/getkyc-by-level', {
				params: {
					...params,
					pageSize,
					page,
					userId,
					email,
				},
			});

			if (res.status === 200) {
				setData({ ...data, ...res.data.data });
				setLoading(false);
			}
			await setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const getLevelList = async (page = 1) => {
		try {
			const res: any = await baseuri.get(`admin/kycLevel/get-kyclevel`, {
				params: {
					pageSize: 10,
					page,
				},
			});

			if (res.status === 200) {
				setLevelList(res.data.data.levelList);
				setParams({ ...params, kycLevel: res.data.data.levelList[1]?._id });
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	useEffect(() => {
		getLevelList();
	}, []);
	useEffect(() => {
		setData({
			totalRecords: 0,
			pageSize: 0,
			currentPage: 0,
			totalPage: 0,
			record: [],
			kycCount: {} as any,
		});
		if (levelList[1]?._id) {
			getKyc();
		}
	}, [params, selectedGateway, location.search, refresh]);

	const columns = [
		{
			title: 'User',
			key: '_id',
			width: 150,
			render: (text: any) => (
				// eslint-disable-next-line no-underscore-dangle
				<TableProfile>
					<div className="avatarSection">
						{text.profileUrl ? (
							<Avatar src={text?.userId?.profileUrl} />
						) : (
							<Avatar className="avatar">
								{' '}
								{text?.userId?.username?.charAt(0)}
							</Avatar>
						)}
						<Link
							title="View Profile"
							to={`/user-profile/${text.userId._id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<p>
								<strong>@{text?.userId?.username}</strong>
							</p>
							<p>{text?.userId?.email}</p>
						</Link>
					</div>
					<br />
					<div>
						<p>
							FULL NAME: <span>{text?.userId?.fullName || '---'}</span>
						</p>
						<p>
							PHONE NO: <span>{text?.userId?.phoneNo || '---'}</span>
						</p>
						<br />
						<p>
							Referred by: <strong>{text?.userId?.refereeName || '---'}</strong>
						</p>
					</div>
				</TableProfile>
			),
		},
		{
			title: <span>{levelList[0]?.levelName || 'Level 1'}</span>,
			key: 9024352,
			render: (text: any) => (
				<Level1Section>
					<p>
						FULL NAME:{' '}
						<strong>
							{`${text?.lastName}  ${text?.firstName}  ${text?.middleName}` ||
								'N/A'}
						</strong>
					</p>
					<p>
						PHONE NUMBER: <strong>+{text?.phoneNumber || 'N/A'}</strong>
					</p>
					<p>
						DATE OF BIRTH: <strong>{text?.dateOfBirth || 'N/A'}</strong>
					</p>
					<p>
						Age:{' '}
						<strong>
							{Math.floor(
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							) <= 18 ? (
								<FakeRef>
									<Tooltip title="User is too young" color="#a10909">
										{moment().diff(
											moment(text?.dateOfBirth, 'DD/MM/YYYY'),
											'years'
										)}
									</Tooltip>
								</FakeRef>
							) : (
								moment().diff(moment(text?.dateOfBirth, 'DD/MM/YYYY'), 'years')
							)}
						</strong>
					</p>
					<p>
						Gender: <strong>{genderEnum(text?.gender) || 'N/A'}</strong>
					</p>
					<p>
						ADDRESS:{' '}
						<strong
							className={`${text?.residentAddress.length < 15 ? 'sus' : ''}`}
						>
							{' '}
							{text?.residentAddress || 'N/A'}
						</strong>
					</p>
					<p>
						COUNTRY:{' '}
						<strong>{Country(text?.countryCode.toUpperCase()) || 'N/A'}</strong>
					</p>
				</Level1Section>
			),
		},
		{
			title: <span>{levelList[1]?.levelName || 'Level 2'}</span>,
			key: 309536,
			render: (text: any) => (
				<MainColumn>
					<p>
						Level 2 Status:{' '}
						<strong
							style={{
								color: `${statusColor(text?.userId?.kycLevelTwoStatus)}`,
							}}
						>
							{' '}
							{text?.userId?.kycLevelTwoStatus || 'N/A'}
						</strong>
					</p>
					<p>
						Last Updated by:{' '}
						<strong>
							{text?.userId?.KycLevelTwoApprovedBy?.email || 'N/A'}
						</strong>
					</p>
					<Levels>
						<p>
							<strong>ID Card</strong>
							<ImageComponent
								secret={text.idcardEncryptKey}
								url={`${selectedGateway}${text.idcardIpfsHash}`}
							/>
						</p>
						<p>
							<strong>Live Photo</strong>
							<ImageComponent
								secret={text.selfieEncryptKey}
								url={`${selectedGateway}${text.selfieIpfsHash}`}
							/>
						</p>
					</Levels>
					<p>
						ID Type: <strong> {text?.idcardType || 'N/A'}</strong>
					</p>
					<p>
						ID NUMBER: <strong> {text?.idcardNumber || 'N/A'}</strong>
					</p>
					{/* <Divider /> */}

					<Levels>
						<Tag
							style={{ cursor: 'pointer' }}
							color="#1e05c2"
							// onClick={() => setVisible(true)}
						>
							<Link to={`/manage-kyc/${text?.userId?._id}`}>
								{`Manage ${text?.userId?.username}'s KYC`}
							</Link>
						</Tag>
						{/* {text?.userId?.kycLevelTwoStatus === 'submitted' && (
							<Tag
								style={{ cursor: 'pointer' }}
								color="blue"
								onClick={() =>
									approve(text?.userId?._id, 'admin/kyc/update-levelTwo-kyc')
								}
							>
								Approve
							</Tag>
						)} */}
						{text?.userId?.kycLevelTwoStatus === 'submitted' ||
						text?.userId?.kycLevelTwoStatus === 'approved' ? (
							<RejectKYC
								text="Reject"
								url="admin/kyc/update-levelTwo-kyc"
								userId={text?.userId}
								data={data}
								setData={setData}
								setRefresh={setRefresh}
							/>
						) : (
							''
						)}
					</Levels>
				</MainColumn>
			),
		},
		// {
		// 	title: <span>{levelList[2]?.levelName || 'Level 3'}</span>,
		// 	key: 2451,
		// 	render: (text: any) => (
		// 		<MainColumn>
		// 			<p>
		// 				Level 3 Status:{' '}
		// 				<strong
		// 					style={{
		// 						color: `${statusColor(text?.userId?.kycLevelThreeStatus)}`,
		// 					}}
		// 				>
		// 					{' '}
		// 					{text?.userId?.kycLevelThreeStatus || 'N/A'}
		// 				</strong>
		// 			</p>
		// 			<p>
		// 				Last Updated by:{' '}
		// 				<strong>
		// 					{text?.userId?.KycLevelThreeApprovedBy?.email || 'N/A'}
		// 				</strong>
		// 			</p>
		// 			<Levels>
		// 				<p>
		// 					<strong>Bank Statement</strong>
		// 					<ImageComponent
		// 						secret={text.bankStatmentEncryptKey}
		// 						url={text.bankStatementUrl}
		// 					/>
		// 				</p>
		// 				<p>
		// 					<strong>Utility Bill</strong>
		// 					<ImageComponent
		// 						secret={text.utilityEncryptKey}
		// 						url={text.utilityIpfsHash}
		// 					/>
		// 				</p>
		// 			</Levels>
		// 			<Divider />
		// 			{text?.userId?.kycLevelThreeStatus === 'submitted' && (
		// 				<Levels>
		// 					<Tag
		// 						style={{ cursor: 'pointer' }}
		// 						color="blue"
		// 						onClick={() =>
		// 							approve(text?.userId?._id, 'admin/kyc/update-levelThree-kyc')
		// 						}
		// 					>
		// 						Approve
		// 					</Tag>
		// 					<RejectKYC
		// 						text="Reject"
		// 						url="admin/kyc/update-levelThree-kyc"
		// 						userId={text?.userId}
		// 						data={data}
		// 						setData={setData}
		// 					/>
		// 				</Levels>
		// 			)}
		// 		</MainColumn>
		// 	),
		// },
		// {
		// 	title: 'Action',
		// 	key: 'createdAt',
		// 	render: (text: any) => (
		// 		<MainColumn>
		// 			<Tag
		// 				color="blue"
		// 				onClick={() =>
		// 					approve(text?.userId?._id, 'admin/kyc/update-both-level-kyc')
		// 				}
		// 			>
		// 				Approve All
		// 			</Tag>
		// 			<RejectKYC
		// 				text="Reject All"
		// 				url="admin/kyc/update-both-level-kyc"
		// 				userId={text?.userId}
		// 				data={data}
		// 				setData={setData}
		// 			/>
		// 		</MainColumn>
		// 	),
		// },
	];

	const handleSelect = (
		kycLevel: string,
		kycStatus: string,
		selected: string
	) => {
		setParams({ ...params, kycLevel, kycStatus });
		setIsSelected(selected);
	};

	return (
		<FullLayout pageTitle="Users KYC">
			<Space>
				<CreateLevel />
				<ViewKYCLevels levelList={levelList} />
			</Space>
			<Divider />
			{levelList ? (
				<>
					<p>
						{levelList[1]?.levelName || 'level 2'}:{' '}
						<span>
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV2S' ? '2px solid' : ''}`,
								}}
								color="blue"
								onClick={() =>
									handleSelect(levelList[1]?._id, 'submitted', 'LV2S')
								}
							>
								Submitted ({data?.kycCount?.levelTwosubmittedKyc || 0})
							</Tag>
							,{' '}
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV2A' ? '2px solid' : ''}`,
								}}
								color="cyan"
								onClick={() =>
									handleSelect(levelList[1]?._id, 'approved', 'LV2A')
								}
							>
								Approved ({data?.kycCount?.levelTwoapprovedKyc || 0})
							</Tag>
							,{' '}
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV2R' ? '2px solid' : ''}`,
								}}
								color="volcano"
								onClick={() =>
									handleSelect(levelList[1]?._id, 'rejected', 'LV2R')
								}
							>
								Rejected ({data?.kycCount?.levelTworejectedKyc || 0})
							</Tag>
						</span>
					</p>
					<br />
					<p>
						{levelList[2]?.levelName || 'level 3'}:{' '}
						<span>
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV3S' ? '2px solid' : ''}`,
								}}
								color="blue"
								onClick={() =>
									handleSelect(levelList[2]?._id, 'submitted', 'LV3S')
								}
							>
								Submitted ({data?.kycCount?.levelThreeSubmittedKyc || 0})
							</Tag>
							,{' '}
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV3A' ? '2px solid' : ''}`,
								}}
								color="cyan"
								onClick={() =>
									handleSelect(levelList[2]?._id, 'approved', 'LV3A')
								}
							>
								Approved ({data?.kycCount?.levelThreeApprovedKyc || 0})
							</Tag>
							,{' '}
							<Tag
								style={{
									cursor: 'pointer',
									border: `${isSelected === 'LV3R' ? '2px solid' : ''}`,
								}}
								color="volcano"
								onClick={() =>
									handleSelect(levelList[2]?._id, 'rejected', 'LV3R')
								}
							>
								Rejected ({data?.kycCount?.levelThreeRejectedKyc || 0})
							</Tag>
						</span>
					</p>
				</>
			) : (
				'Loading...'
			)}
			<br />
			<NextKyc text="Manage Random KYC" />
			<Divider />
			<SearchSection>
				<div>
					Select gateway :{' '}
					<Select
						// mode="multiple"
						placeholder="Select GateWay"
						// value={newsletter?.maillist}
						onChange={(e: any) => setSelectedGateway(e)}
						defaultValue="https://cf-ipfs.com/ipfs/"
						// style={{ width: '100%' }}
					>
						{gateWays?.map((gateway: any) => (
							<Select.Option key={gateway.name} value={gateway.value}>
								{gateway.name}
							</Select.Option>
						))}
					</Select>
				</div>
				<div>
					<Input.Search
						type="email"
						placeholder="Search with email only"
						enterButton
						onSearch={(e) => history.push(`/users-kyc?email=${e}`)}
					/>
				</div>
			</SearchSection>
			<Divider />
			<p>
				<strong>{data?.totalRecords} records</strong>
			</p>
			<ResponsiveSection>
				<Table
					loading={loading}
					columns={columns}
					dataSource={data.record}
					pagination={false}
				/>

				<br />

				<Pagination
					onChange={(page, pageSize) => getKyc(page, pageSize)}
					disabled={loading}
					pageSize={data?.pageSize}
					current={data?.currentPage}
					total={data?.totalRecords}
					showSizeChanger
					pageSizeOptions={['2', '5', '10', '20']}
				/>
			</ResponsiveSection>
		</FullLayout>
	);
};

export default UsersKYC;
