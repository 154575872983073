import { Card } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { getBatchRecord, getFeesRecord } from '../../../methods/fees';

const FeesRecords = () => {
	// const dispatch = useDispatch();

	const { totalFees } = useSelector((store: any) => store.fees);
	const { totalGasFees } = useSelector((store: any) => store.batch);

	useEffect(() => {
		// dispatch(getFeesRecord({}));
		// dispatch(getBatchRecord({}));
	}, []);

	return (
		<>
			{totalGasFees}

			<Card>Total Fees: {totalFees} USD</Card>
		</>
	);
};

export default FeesRecords;
