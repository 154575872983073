import { Avatar, Card, Divider } from 'antd';
import { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import bndIcon from '../../../assets/images/bndIcon.png';
// import busdIcon from '../../../assets/images/busdIcon.png';
// import saveIcon from '../../../assets/images/saveIcon.jpg';
// import warningGIF2 from '../../../assets/images/warningGIF2.gif';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';
import BusdIcon from '../../binance-usd-busd-logo.svg';
import BNBIcon from '../../bnb.svg';
import XendIcon from '../../xend.svg';
import {
	commas,
	shortAmount,
	// commas,
	// lowPriceChecker,
	// shortAmount,
	// truncateAddress,
} from '../../methods/utils';
import { getBalances } from '../../methods/web3/amounts';

// const WarningIcon = styled.div`
// 	display: inline-flex;
// 	width: 15px;
// 	height: 15px;

// 	& .image3 {
// 		width: 100%;
// 		height: 100%;
// 		object-fit: contain;
// 	}
// `;

const BalanceStyle = styled.section`
	font-weight: 600;
	font-size: 18px;
	margin-top: 10px;

	& .explorers {
		display: flex;
		margin-bottom: 20px;

		& a {
			text-decoration: underline;
			font-size: 14px;
			color: inherit;

			&:hover {
				color: #007bff;
			}
		}
	}

	& .funds {
		display: flex;
		margin-top: 7px;
		font-weight: 600;

		& > div {
			display: flex;
			margin-right: 30px;
			align-items: center;

			& > *:first-child {
				margin-right: 5px;
			}
		}
	}
`;

const CustodialWallets = () => {
	const [loading, setLoading] = useState(false);
	const [custodialAddresses, setCustodialAddresses] = useState<Array<any>>([]);
	const [balances, setBalances] = useState<Array<any>>([]);

	// const column = [
	// 	{
	// 		title: 'Custodial Wallet Address',
	// 		dataIndex: 'address',
	// 		render: (text: any) => (
	// 			<Title level={4}>
	// 				{' '}
	// 				<Tooltip title="Click to copy address" color="green">
	// 					<span
	// 						role="none"
	// 						onClick={() => {
	// 							navigator.clipboard.writeText(text);
	// 							message.success('Address copied');
	// 						}}
	// 					>
	// 						{truncateAddress(text, 6)}{' '}
	// 					</span>
	// 				</Tooltip>
	// 				<Tooltip title="View on BscScan" color="blue">
	// 					<a
	// 						href={`https://bscscan.com/address/${text}`}
	// 						target="_blank"
	// 						rel="noreferrer"
	// 					>
	// 						<FullscreenOutlined />
	// 					</a>
	// 				</Tooltip>
	// 			</Title>
	// 		),
	// 	},

	// 	{
	// 		title: '',
	// 		render: (text: any) => (
	// 			<div className="">
	// 				<Title level={4}>
	// 					{shortAmount(text?.BNB)} BNB{' '}
	// 					<sup>
	// 						<WarningIcon>
	// 							{lowPriceChecker(text?.BNB, 'BNB') && (
	// 								<Tooltip title="Low Balance" color="#9b9606">
	// 									<img className="image3" src={warningGIF2} alt="" />
	// 								</Tooltip>
	// 							)}
	// 						</WarningIcon>
	// 					</sup>
	// 				</Title>
	// 			</div>
	// 		),
	// 	},
	// 	{
	// 		title: '',
	// 		dataIndex: 'BUSD',
	// 		render: (text: any) => (
	// 			<div className="">
	// 				{/* <span className="bnb2"> .</span> */}
	// 				<Title level={4}>
	// 					{commas(shortAmount(text))} BUSD
	// 					<sup>
	// 						<WarningIcon>
	// 							{lowPriceChecker(text, 'BUSD') && (
	// 								<Tooltip title="Low Balance" color="#9b9606">
	// 									<img className="image3" src={warningGIF2} alt="" />
	// 								</Tooltip>
	// 							)}
	// 						</WarningIcon>
	// 					</sup>
	// 				</Title>
	// 			</div>
	// 		),
	// 	},
	// 	{
	// 		title: '',
	// 		dataIndex: 'XEND',
	// 		render: (text: any) => (
	// 			<div className="">
	// 				{/* <span className="bnb2"> .</span> */}
	// 				<Title level={4}>
	// 					{commas(shortAmount(text))} XEND
	// 					<sup>
	// 						<WarningIcon>
	// 							{lowPriceChecker(text, 'XEND') && (
	// 								<Tooltip title="Low Balance" color="#9b9606">
	// 									<img className="image3" src={warningGIF2} alt="" />
	// 								</Tooltip>
	// 							)}
	// 						</WarningIcon>
	// 					</sup>
	// 				</Title>
	// 			</div>
	// 		),
	// 	},
	// ];

	const getAddresses = async () => {
		try {
			const response: any = await baseuri?.get(
				'/custodialWallet/get-addresses'
			);
			if (response.status === 200) {
				setCustodialAddresses(response.data?.data?.result);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const getBalancesForAddress = async () => {
		setLoading(true);
		try {
			const results: any[] = [];
			for (let i = 0; i < custodialAddresses?.length; i++) {
				const current = custodialAddresses[i];
				const x = getBalances(current.address);
				if (x) {
					results.push(x);
				}
			}
			const main = await Promise.all(results);
			setBalances(main);
			setLoading(false);
		} catch (e) {
			console.error(e);
			setLoading(false);
		}
	};

	useEffect(() => {
		getBalancesForAddress();
	}, [custodialAddresses]);

	useEffect(() => {
		getAddresses();
	}, []);

	return (
		<Card>
			<p>Custodial Wallet(s) {loading && 'loading'}</p>

			{balances &&
				balances.map((balance: any) => (
					<BalanceStyle>
						<p>{balance?.address}</p>
						<div className="explorers">
							<a
								target="_blank"
								href={`https://bscscan.com/address/${balance?.address}`}
								rel="noreferrer"
							>
								Bsc explorer
							</a>
						</div>
						<div className="funds">
							<div>
								<img src={BNBIcon} alt="" width={20} />
								<p>{commas(shortAmount(balance?.BNB))} BNB</p>
							</div>
							<div>
								<img src={BusdIcon} alt="" width={20} />
								<p>{commas(shortAmount(balance?.BUSD))} BUSD</p>
							</div>
							<div>
								<img src={XendIcon} alt="" width={20} />
								<p>{commas(shortAmount(balance?.XEND))} XEND</p>
							</div>
							<div>
								<Avatar size={20} icon={<QuestionOutlined />} />
								<p>{commas(shortAmount(balance?.xBUSD))} xBUSD</p>
							</div>
						</div>
						<Divider />
					</BalanceStyle>
				))}
		</Card>
	);
};

export default CustodialWallets;
