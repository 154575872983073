import Web3 from 'web3';
import { message } from 'antd';
import createContract from './create-contract';
import { bep20 } from './assets';

export interface ITransferFunds {
	amount: any;
	currency: any;
	destination: string;
	client: any;
	setLoading: Function;
	retrieveHash: Function;
}

export const transferFunds = async (params: ITransferFunds) => {
	const { retrieveHash, currency, destination, amount, client, setLoading } =
		params;

	setLoading(true);

	try {
		let tokenContractAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'; // busd token address
		if (String(currency).toLowerCase() === 'xend') {
			tokenContractAddress = '0x4a080377f83d669d7bb83b3184a8a5e61b500608';
		}

		const token = await createContract(bep20, tokenContractAddress);

		const receipt = await token.methods
			.transfer(destination, Web3.utils.toWei(amount.toString(), 'ether'))
			.send({ from: client });

		if (receipt.status === true) {
			retrieveHash(receipt.transactionHash);
			message.success('Funds transfer was successful. Continue process.');
		}

		setLoading(false);
	} catch (e) {
		setLoading(false);
	}
};
