import { Button, Card } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const FilterSection = styled.div``;
const CustomCard = styled(Card)`
	padding: 10px;
	border-radius: 10px;
`;
const MyButton = styled(Button)`
	color: #383838;
	border-radius: 10px;
	margin: 5px;

	&:hover {
		background-color: #7eb1f5;
		color: white;
	}
	&:focus {
		background-color: blue;
		color: white;
	}

	&.selected {
		background-color: blue;
		color: white;
	}
`;
const Filter = () => {
	const history = useHistory();

	const location = useLocation();
	const selectedFilter = location.search.split(/[=]/)[1];

	const filterEnum = [
		{ name: 'Pending', value: 'pending' },
		{ name: 'Deleted', value: 'deleted' },
	];

	return (
		<FilterSection>
			<CustomCard>
				<p>
					<strong>Double Funding</strong>
				</p>
				<strong>Filters: </strong>
				{filterEnum.map((n) => (
					<MyButton
						className={selectedFilter === n.value ? 'selected' : ''}
						key={n.value}
						onClick={() => history.push(`/double-funding?status=${n.value}`)}
					>
						{n.name}
					</MyButton>
				))}
			</CustomCard>
		</FilterSection>
	);
};

export default Filter;
