import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { tokenName } from '../methods/authenticate';

export const isAuth = () => {
	if (localStorage.getItem(tokenName) !== null) {
		const t = JSON.parse(localStorage.getItem(tokenName) || '{}');
		if (moment().isBefore(moment(t.expiresIn))) {
			return {
				data: t,
				response: true,
			};
		}
	}
	return { data: null, response: false };
};

/**
 * Any Component wrapped with this function should not work if the app is already authenticated. Eg... Login and Register
 * @param Component
 * @returns
 */
function Authenticated(Route: any) {
	return class Protect extends React.PureComponent {
		render() {
			const { response, data } = isAuth();

			if (response && data.token) {
				createBrowserHistory().push('/');
				createBrowserHistory().go(0);
			}

			return <Route />;
		}
	};
}

export { Authenticated };
