import { Col, Descriptions, Row } from 'antd';

const AdminInfoCard = () => {
	return (
		<Row gutter={[24, 0]}>
			<Col span={24} md={24}>
				<Descriptions>
					<Descriptions.Item label="Full Name" span={3}>
						<strong> Obinna Emmanuel</strong>
					</Descriptions.Item>
					<Descriptions.Item label="Mobile" span={3}>
						07065477408
					</Descriptions.Item>
					<Descriptions.Item label="Email" span={3}>
						Obinna@gmail.com
					</Descriptions.Item>
					<Descriptions.Item label="Location" span={3}>
						Nigeria
					</Descriptions.Item>
				</Descriptions>
			</Col>
		</Row>
	);
};

export default AdminInfoCard;
