import { FC, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface IEditorComponent {
	newsletter: any;
	setNewsLetter: any;
}
const EditorComponent: FC<IEditorComponent> = ({
	newsletter,
	setNewsLetter,
}) => {
	const editorRef = useRef<any>(null);

	return (
		<Editor
			// eslint-disable-next-line no-return-assign
			onInit={(evt: any, editor: any) => (editorRef.current = editor)}
			// initialValue={newsletter?.content}
			value={newsletter?.content}
			init={{
				height: 500,
				max_width: 300,
				branding: false,
				overflow: 'auto',
				menubar: true,
				menu: {
					file: {
						title: 'File',
						items: 'newdocument restoredraft | preview | print ',
					},
					edit: {
						title: 'Edit',
						items: 'undo redo | cut copy paste | selectall | searchreplace',
					},
					view: {
						title: 'View',
						items:
							'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
					},
					insert: {
						title: 'Insert',
						items:
							'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
					},
					format: {
						title: 'Format',
						items:
							'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
					},
					tools: {
						title: 'Tools',
						items: 'spellchecker spellcheckerlanguage | code wordcount',
					},
					table: {
						title: 'Table',
						items: 'inserttable | cell row column | tableprops deletetable',
					},
					help: { title: 'Help', items: 'help' },
				},
				plugins: [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media table paste code help wordcount',
					'lineheight',
				],
				toolbar:
					'formatselect |' +
					'bold italic backcolor | alignleft aligncenter ' +
					'alignright alignjustify | bullist numlist outdent indent |' +
					'removeformat | help',
				content_style:
					'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-wrap: break-word }',
			}}
			onEditorChange={(newText) =>
				setNewsLetter({ ...newsletter, content: newText })
			}
		/>
	);
};
export default EditorComponent;
