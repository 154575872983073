import {
	Avatar,
	Button,
	Card,
	DatePicker,
	Divider,
	message,
	Pagination,
	Radio,
	Select,
	Space,
	Table,
	Tabs,
} from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { enum2Currency, enum2TxnType } from '../../../methods/transactions';
import { commas, shortAmount } from '../../../methods/utils';
import CSVComponent from '../../_ReUsable/CSVComponent';

const HeaderExtraStyle = styled.section`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;
export const TableProfile = styled.div`
	cursor: pointer;
	transition: all 600ms ease;
	text-decoration: none;

	& .avatarSection {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	& .avatar {
		background-color: hsl(235.89, 57.48%, 55%);
	}

	&:hover {
		& .avatar {
			background-color: hsl(235.89, 57.48%, 40%);
		}
	}
`;
const tnxTableLabels = (history: any) => [
	{
		title: 'User',
		dataIndex: 'userId',
		width: 150,
		render: (text: any) => (
			// eslint-disable-next-line no-underscore-dangle
			<TableProfile onClick={() => history.push(`/user-profile/${text._id}`)}>
				{text.profileUrl ? (
					<Avatar src={text.profileUrl} />
				) : (
					<Avatar className="avatar"> {text?.username?.charAt(0)}</Avatar>
				)}
				<>
					<p>
						<strong>@{text.username}</strong>
					</p>
					<p>{text.email}</p>
				</>
			</TableProfile>
		),
	},
	{
		title: 'Amount',
		width: 100,
		render: (text: any) => (
			<span>
				<strong>{text.amount}</strong> {enum2Currency(text.currency)}
			</span>
		),
	},
	{
		title: 'Network',
		width: 100,
		dataIndex: 'network',
	},
	{
		title: 'Hash',
		width: 100,
		dataIndex: 'txnHash',
		render: (text: any) => (
			<a href={text} target="_blank" rel="noreferrer">
				link out
			</a>
		),
	},
	// {
	//   title: "Plan",
	//   width: 150,
	//   dataIndex: "planId",
	// },
	{
		title: 'Type',
		width: 100,
		dataIndex: 'transactionState',
		render: (text: any) => <p>{enum2TxnType(text)}</p>,
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('lll'),
	},
];
const tnxTableLabels2 = () => [
	{
		title: 'User',
		dataIndex: 'username',
		width: 150,
		render: (text: any) => <p>{text}</p>,
	},
	{
		title: 'No. of Txs',
		dataIndex: 'transactionCount',
		width: 100,
		render: (text: any) => <strong>{text}</strong>,
	},
	{
		title: 'Total Amount',
		dataIndex: 'totalTransactionAmount',
		width: 100,
		render: (text: any) => <strong>{commas(shortAmount(text))}</strong>,
	},
];
interface IHeaderExtra {
	setLoading: Function;
	startDate: any;
	startCallback: Function;
	endDate: any;
	endCallback: Function;
	handleSearchByDate: Function;
}
const HeaderExtra: FC<IHeaderExtra> = ({
	setLoading,
	startDate,
	startCallback,
	endDate,
	endCallback,
	handleSearchByDate,
}) => {
	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, []);

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date) =>
							date?.isSameOrBefore(endDate) && startCallback(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={startDate}
						defaultValue={startDate}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={endDate}
						onChange={(date) =>
							date?.isSameOrAfter(startDate) && endCallback(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={endDate}
					/>
				</div>
				<div>
					<Button type="primary" onClick={() => handleSearchByDate()}>
						Search
					</Button>
				</div>
			</div>
		</HeaderExtraStyle>
	);
};

const Transaction3 = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(moment());
	const [txsState, setTxsState] = useState('6');
	const [radioSelect, setRadioSelect] = useState('6');
	const [queryURL, setQueryURL] = useState(
		`admin/transactions?type=1&transactionState=${txsState}&start=${startDate.format(
			'YYYY-MM-DD'
		)}&end=${endDate.format('YYYY-MM-DD')}`
	);
	const [trans, setTrans] = useState({
		records: [],
		Query: [],
		DocumentCount: '0',
		currentPage: 1,
		totalRecords: 0,
		totalPages: 0,
		pageSize: 0,
		totalTransactionAmount: '0',
	});

	const enumTxsState = [
		{
			name: 'Fund',
			value: '0',
		},
		{
			name: 'Withdraw',
			value: '1',
		},
		{
			name: 'TransferSend',
			value: '2',
		},
		{
			name: 'TransferReceive',
			value: '3',
		},
		{
			name: 'Swap',
			value: '4',
		},
		{
			name: 'Save',
			value: '5',
		},
		{
			name: 'Fixed Savings Save',
			value: '6',
		},
		{
			name: 'Fixed Savings Withdrawal',
			value: '7',
		},
		{
			name: 'Flexible Save',
			value: '8',
		},
		{
			name: 'Flexible Withdrawal',
			value: '9',
		},
	];

	const startCallback = (date: any) => {
		setStartDate(date);
	};
	const endCallback = (date: any) => {
		setEndDate(date);

		// refresh(queryURL);
	};

	const hangleGetTsx = async (page = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get(`${queryURL}&page=${page}`);

			if (res.status === 200) {
				setLoading(false);
				setTrans({ ...trans, ...res.data.data });
				// message.success('Successfully fetched records');
			} else {
				setLoading(false);
				message.info(res.data.message);
			}
		} catch (error: any) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};

	useEffect(() => {
		hangleGetTsx();
	}, [queryURL, endDate]);

	const refresh = (key: any) => {
		setQueryURL(key);
	};
	return (
		<FullLayout pageTitle="Referral Report">
			<Divider />

			<Card>
				<Tabs
					// defaultActiveKey={`admin/transactions?type=1&state=${txsState}&start=${startDate.format(
					// 	'YYYY-MM-DD'
					// )}&end=${endDate.format('YYYY-MM-DD')}`}
					onChange={(key) => setQueryURL(key)}
				>
					<Tabs.TabPane
						tab="Savings Txs"
						key={`admin/transactions?type=1&transactionState=${txsState}&start=${startDate.format(
							'YYYY-MM-DD'
						)}&end=${endDate.format('YYYY-MM-DD')}`}
					>
						<HeaderExtra
							setLoading={setLoading}
							endCallback={endCallback}
							startDate={startDate}
							startCallback={startCallback}
							endDate={endDate}
							handleSearchByDate={() =>
								refresh(
									`admin/transactions?type=1&transactionState=${txsState}&start=${startDate.format(
										'YYYY-MM-DD'
									)}&end=${endDate.format('YYYY-MM-DD')}`
								)
							}
						/>
						<Divider />
						<Space>
							<CSVComponent
								url="admin/transactions"
								startDate={startDate.format('YYYY-MM-DD')}
								endDate={endDate.format('YYYY-MM-DD')}
								otherParams={{ type: 2, transactionState: `${txsState}` }}
							/>

							<Select
								style={{ width: 150 }}
								placeholder="Select Type"
								value="6"
								onChange={(e) => {
									setTxsState(e);
									setQueryURL(
										`admin/transactions?type=1&transactionState=${e}&start=${startDate.format(
											'YYYY-MM-DD'
										)}&end=${endDate.format('YYYY-MM-DD')}`
									);
								}}
							>
								<Select.Option value="8">Flexible Savings</Select.Option>
								<Select.Option value="6">Fixed Savings</Select.Option>
								{/* {enumTxsState?.map((n: any) => (
									<Select.Option key={n.value} value={n.value}>
										{n.name}
									</Select.Option>
								))} */}
							</Select>
						</Space>
						<div>
							<p>
								<strong>{trans.totalRecords} records</strong>
							</p>

							<Table
								loading={loading}
								columns={tnxTableLabels(history)}
								dataSource={trans?.records}
								pagination={false}
							/>

							<br />
							<Pagination
								onChange={hangleGetTsx}
								disabled={loading}
								pageSize={trans.pageSize}
								current={trans.currentPage}
								total={trans.totalRecords}
								showSizeChanger={false}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="Wallet Txs"
						key={`admin/transactions?type=2&transactionState=${txsState}&start=${startDate.format(
							'YYYY-MM-DD'
						)}&end=${endDate.format('YYYY-MM-DD')}`}
					>
						<HeaderExtra
							setLoading={setLoading}
							endCallback={endCallback}
							startDate={startDate}
							startCallback={startCallback}
							endDate={endDate}
							handleSearchByDate={() =>
								refresh(
									`admin/transactions?type=2&transactionState=${txsState}&start=${startDate.format(
										'YYYY-MM-DD'
									)}&end=${endDate.format('YYYY-MM-DD')}`
								)
							}
						/>
						<Divider />
						<Space>
							<CSVComponent
								url="admin/transactions"
								startDate={startDate.format('YYYY-MM-DD')}
								endDate={endDate.format('YYYY-MM-DD')}
								otherParams={{ type: 1, transactionState: `${txsState}` }}
							/>
							<Select
								style={{ width: 150 }}
								placeholder="Select Type"
								value={txsState}
								onChange={(e) => {
									setTxsState(e);
									setQueryURL(
										`admin/transactions?type=2&transactionState=${e}&start=${startDate.format(
											'YYYY-MM-DD'
										)}&end=${endDate.format('YYYY-MM-DD')}`
									);
								}}
							>
								{enumTxsState?.map((n: any) => (
									<Select.Option key={n.value} value={n.value}>
										{n.name}
									</Select.Option>
								))}
							</Select>
						</Space>
						<div>
							<p>
								<strong>{trans.totalRecords} records</strong>
							</p>

							<Table
								loading={loading}
								columns={tnxTableLabels(history)}
								dataSource={trans?.records}
								pagination={false}
							/>

							<br />
							<Pagination
								onChange={hangleGetTsx}
								disabled={loading}
								pageSize={trans.pageSize}
								current={trans.currentPage}
								total={trans.totalRecords}
								showSizeChanger={false}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab="Total Savings per user"
						key={`admin/transactions/group?transactionState=${radioSelect}&start=${startDate.format(
							'YYYY-MM-DD'
						)}&end=${endDate.format('YYYY-MM-DD')}`}
					>
						<HeaderExtra
							setLoading={setLoading}
							endCallback={endCallback}
							startDate={startDate}
							startCallback={startCallback}
							endDate={endDate}
							handleSearchByDate={() =>
								refresh(
									`admin/transactions/group?transactionState=${radioSelect}&start=${startDate.format(
										'YYYY-MM-DD'
									)}&end=${endDate.format('YYYY-MM-DD')}`
								)
							}
						/>
						<Divider />
						<Space size={300}>
							<CSVComponent
								url="/admin/transactions/group"
								startDate={startDate.format('YYYY-MM-DD')}
								endDate={endDate.format('YYYY-MM-DD')}
								otherParams={{ transactionState: radioSelect }}
							/>
							<Radio.Group
								onChange={({ target: { value } }) => {
									setRadioSelect(value);
									setQueryURL(
										`admin/transactions/group?transactionState=${value}&start=${startDate.format(
											'YYYY-MM-DD'
										)}&end=${endDate.format('YYYY-MM-DD')}`
									);
								}}
								value={radioSelect}
							>
								{/* <Radio value="">ALL</Radio> */}
								<Radio value="8">FLEXIBLE</Radio>
								<Radio value="6">FIXED</Radio>
							</Radio.Group>
						</Space>
						<strong>
							<p>Users (Total: {trans?.DocumentCount})</p>
							<p>
								Amount (Total:{' '}
								{commas(shortAmount(trans?.totalTransactionAmount))})
							</p>
						</strong>
						<Table
							loading={loading}
							scroll={{ x: 'max-content' }}
							columns={tnxTableLabels2()}
							dataSource={trans?.Query}
						/>

						<br />
					</Tabs.TabPane>
				</Tabs>{' '}
			</Card>
		</FullLayout>
	);
};

export default Transaction3;
