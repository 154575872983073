import moment from 'moment';
import { Button, Divider, Spin, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getDepositQueueItems } from '../methods/queue-items';
import CustodianQueueProcessing from './CustodianQueueProcessing';

const CustodianInfo = styled.section`
	display: flex;
	justify-content: space-between;
`;

const Internals = () => {
	const dispatch = useDispatch();

	const { deposits } = useSelector((store: any) => store.queue);

	useEffect(() => {
		dispatch(getDepositQueueItems());
	}, []);

	const depositsCol = [
		{
			title: 'Status',
			dataIndex: 'status',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
		},
		{
			title: 'Plan Type',
			dataIndex: 'planType',
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Updated',
			dataIndex: 'updatedAt',
			render: (text: any) => moment(text).format('lll'),
		},
		{
			title: 'Plan Id',
			dataIndex: 'planId',
		},
		{
			title: 'Plan',
			dataIndex: 'planId',
			render: () => <Button size="small">View Plan</Button>,
		},
	];

	return (
		<>
			<Helmet>
				<title>Internal Operations</title>
			</Helmet>

			<h1>Internal Workings</h1>

			<Divider />
			{/* <i className="fa fa-spinner fa-spin" style={{ color: 'blue' }}></i>  */}
			<CustodianInfo>
				<div>
					<p>Custodian</p>
					<p>0x53F34GH...</p>
					<br />
					<p style={{ display: 'flex', alignItems: 'center' }}>
						<Tag color="green">Active</Tag>{' '}
						<abbr title="Processing Queue">
							<Spin size="small" />
						</abbr>
					</p>
					<p />
					<br />
					<CustodianQueueProcessing />
				</div>
				<div>
					<p>Balance: 1.2 BNB</p>
					<p>Token: 2,000</p>
				</div>
			</CustodianInfo>

			<Divider />

			<div>
				<p>Deposit Queue</p>
				<Table columns={depositsCol} dataSource={deposits} />
			</div>
		</>
	);
};

export default Internals;
