import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reduxStore from './methods/store';
import 'antd/dist/antd.css';

let store;

reduxStore().then((value) => {
	store = value;

	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);
});
