import { FC, useEffect, useState } from 'react';
import {
	Table,
	Space,
	Card,
	Button,
	Pagination,
	Input,
	message,
	DatePicker,
	Tabs,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import UsersByCountry from './UsersByCountry';
import { commas } from '../../../methods/utils';

const HeaderExtraStyle = styled.section`
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-end;
	& .date-search {
		display: flex;
		align-items: flex-end;
	}
`;

interface IHeaderExtra {
	setLoading: Function;
	users: any;
	setUsers: Function;
	startDate: any;
	setStartDate: Function;
	endDate: any;
	setEndDate: Function;
	handleSearchByDate: Function;
}
export const HeaderExtra: FC<IHeaderExtra> = ({
	setLoading,
	users,
	setUsers,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleSearchByDate,
}) => {
	useEffect(() => {
		setLoading(true);
		handleSearchByDate();
	}, []);

	const handleSearch = async (search: any) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/search-user', {
				params: { search },
			});
			if (res.status === 200) {
				setUsers({
					...users,
					records: res.data.data,
					currentPage: 1,
					totalRecords: res.data.data?.length,
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<HeaderExtraStyle>
			<div className="date-search">
				<div>
					<p>From</p>
					<DatePicker
						placeholder="Start"
						allowClear={false}
						format="ll"
						onChange={(date) =>
							date?.isSameOrBefore(endDate) && setStartDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						value={startDate}
						defaultValue={startDate}
					/>
				</div>
				<div>
					<p>To</p>
					<DatePicker
						placeholder="End"
						allowClear={false}
						format="ll"
						value={endDate}
						onChange={(date) =>
							date?.isSameOrAfter(startDate) && setEndDate(date)
						}
						disabledDate={(current) =>
							current && current > moment().endOf('day')
						}
						defaultValue={endDate}
					/>
				</div>
				<div>
					<Button type="primary" onClick={() => handleSearchByDate()}>
						Search
					</Button>
				</div>
			</div>
			<div>
				<Input.Search
					placeholder="Search with username, email or wallet address"
					enterButton
					onSearch={(e) => handleSearch(e)}
				/>
			</div>
		</HeaderExtraStyle>
	);
};

const AllUsers = () => {
	const [loading, setLoading] = useState(true);

	const [startDate, setStartDate] = useState(moment('2021/10/01'));
	const [endDate, setEndDate] = useState(moment());
	const [users, setUsers] = useState({
		records: [],
		currentPage: 1,
		totalRecords: 0,
	});

	const handleSearchByDate = async (page = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get('/admin/search-user-date', {
				params: {
					start: startDate.format('YYYY-MM-DD'),
					end: endDate.format('YYYY-MM-DD'),
					pageSize: 10,
					page,
				},
			});

			if (res.status === 200) {
				setUsers({ ...users, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNo',
			key: 'phoneNo',
			render: (text: any) => <h3>{text}</h3>,
		},
		{
			title: 'Number of Referrals',
			dataIndex: 'referralCount',
			key: 'referralCount',
		},
		{
			title: 'SignUp Date',
			dataIndex: 'createdAt',
			render: (text: any) => moment(text).subtract(1, 'hour').format('lll'),
			key: 'createdAt',
		},

		{
			title: '',
			// key: '_id',
			render: (user: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${user._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];

	return (
		<FullLayout pageTitle="Users">
			<Card>
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane tab="All Users" key="1">
						<HeaderExtra
							setLoading={setLoading}
							users={users}
							setUsers={setUsers}
							setEndDate={setEndDate}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							handleSearchByDate={handleSearchByDate}
						/>
						<br />
						<div>
							<p>
								<strong>{commas(users.totalRecords, 2)} records</strong>
							</p>
							{/* <p>
              <CSVComponent url={currentUrl} />
            </p> */}
							<Table
								loading={loading}
								columns={columns}
								dataSource={users.records}
								pagination={false}
							/>

							<br />

							<Pagination
								onChange={handleSearchByDate}
								disabled={loading}
								pageSize={10}
								current={users.currentPage}
								total={users.totalRecords}
								showSizeChanger={false}
							/>
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Users By Country" key="2">
						<UsersByCountry />
					</Tabs.TabPane>
				</Tabs>
			</Card>
		</FullLayout>
	);
};

export default AllUsers;
