// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { message, notification } from 'antd';
import { coordTokenName, noauthBaseuri } from './baseuri';

export const setupSuperAdmin = async (data: any, loading: Function) => {
	try {
		loading(true);
		await noauthBaseuri.post('/admin/setup', data);

		loading(false);

		notification.success({
			message: 'Administrator Successfully Setup',
			description:
				'The super admin account has been successfully setup. Confirm your account to continue',
		});

		setTimeout(() => {
			// navigate to next page
			// createBrowserHistory().push('/');
			// createBrowserHistory().go(0);
		}, 2000);
	} catch (e: any) {
		loading(false);
		console.error(e);
		message.error(`Action failed. ${e.toString()}`);
	}
};

export const tokenName = 'xfma-token';
export const saveToken = (token: any) => {
	localStorage.setItem(tokenName, JSON.stringify(token));
};
export const retrieveToken = () => {
	const token = localStorage.getItem(tokenName);
	if (!token) return null;
	return JSON.parse(token);
};

export const deleteToken = () => {
	localStorage.removeItem(tokenName);
	localStorage.removeItem(coordTokenName);
};

export const logout = () => {
	deleteToken();
	createBrowserHistory().push('/login');
	createBrowserHistory().go(0);
};

export const adminLogin = (
	data: any,
	show2FA: Function,
	setLoading: Function
) => {
	return async () => {
		try {
			setLoading(true);
			const res: any = await noauthBaseuri.post('/admin/login', data);

			const auth = res.data.data;

			if (res.status === 200) {
				// save token

				saveToken(auth);

				if (auth.enable2FA) {
					show2FA({ show: true, email: data.email, password: data.password });
				} else {
					createBrowserHistory().push('/');
					createBrowserHistory().go(0);
				}

				setLoading(false);
			}

			setLoading(false);
		} catch (e: any) {
			setLoading(false);
			console.error(e);
			message.error(`Login failed. ${e.response?.data?.data.message}`);
		}
	};
};
