import { useEffect, useState } from 'react';
import { Menu, Modal } from 'antd';
import {
	UserOutlined,
	DashboardOutlined,
	SyncOutlined,
	TeamOutlined,
	ControlOutlined,
	LogoutOutlined,
	MailOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../../methods/authenticate';
import { ReactComponent as XendLogo } from '../../logo.svg';

const SideBar = () => {
	const { authRole } = useSelector((store: any) => store.auth);
	const { pathname } = useLocation();
	const [selectedMenu, setSelectedMenu] = useState<any>('');

	const history = useHistory();

	const linksAndKeys: any = {
		'/': 'dashboard',
		'/all-users': 'all-users',
		'/transactions': 'transactions',
		'/locked-savings': 'locked-savings',
		'/admin-team': 'admin-team',
		'/userType': 'userType',
		'/user-search': 'user-search',
		'/users-kyc': 'users-kyc',
		'/balance-search': 'balance-search',
	};

	useEffect(() => {
		setSelectedMenu(linksAndKeys[pathname]);
	}, [pathname]);

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100px',
				}}
			>
				<div>
					<XendLogo width={100} />
				</div>
			</div>

			<Menu
				theme="light"
				mode="inline"
				selectedKeys={[selectedMenu]}
				defaultSelectedKeys={['1']}
				style={{ width: '100%' }}
			>
				<Menu.Item key="dashboard" icon={<DashboardOutlined />}>
					<Link className="link" to="/">
						Dashboard
					</Link>
				</Menu.Item>
				<Menu.Item
					key="greetings"
					icon={<span className="material-icons">waving_hand</span>}
				>
					<Link className="link" to="/greetings">
						Greetings
					</Link>
				</Menu.Item>
				<Menu.Item key="newsletter" icon={<MailOutlined />}>
					<Link className="link" to="/newsletter">
						NewsLetter
					</Link>
				</Menu.Item>

				<Menu.Item key="all-users" icon={<UserOutlined />}>
					<Link className="link" to="/all-users">
						All Users
					</Link>
				</Menu.Item>
				<Menu.Item key="users-kyc" icon={<UserOutlined />}>
					<Link className="link" to="/users-kyc">
						Users KYC
					</Link>
				</Menu.Item>
				<Menu.Item
					icon={<span className="material-icons">account_balance</span>}
					key="balance-search"
					onClick={() => history.push('/balance-search')}
				>
					Wallet Balance Search
				</Menu.Item>

				<Menu.Item
					icon={<span className="material-icons">person_search</span>}
					key="user-search"
					onClick={() => history.push('/user-search')}
				>
					User Search
				</Menu.Item>
				<Menu.Item key="userType" icon={<UserOutlined />}>
					<Link className="link" to="/userType">
						User Type
					</Link>
				</Menu.Item>

				<Menu.Item
					key="referral-report"
					icon={<span className="material-icons">report_gmailerrorred</span>}
				>
					<Link className="link" to="referral-report">
						Referral Report
					</Link>
				</Menu.Item>

				<Menu.Item key="transactions" icon={<SyncOutlined />}>
					<Link className="link" to="/transactions">
						Transactions
					</Link>
				</Menu.Item>
				<Menu.Item
					key="locked-savings"
					icon={<span className="material-icons">sync_lock</span>}
				>
					<Link className="link" to="/compliance/locked-fixed">
						Locked Savings
					</Link>
				</Menu.Item>
				<Menu.Item
					key="accounts"
					icon={<span className="material-icons">currency_exchange</span>}
				>
					<Link className="link" to="/accounts">
						Accounts
					</Link>
				</Menu.Item>

				{authRole &&
					(authRole.name === 'Super Admin' ||
						authRole.name === 'Compliance') && (
						<>
							<Menu.Item key="admin-team" icon={<TeamOutlined />}>
								<Link className="link" to="/admin-team">
									Admin Team
								</Link>
							</Menu.Item>

							<Menu.Item key="10" icon={<ControlOutlined />}>
								<Link className="link" to="/advanced/deposit-queue">
									Advanced
								</Link>
							</Menu.Item>
						</>
					)}

				<Menu.Item
					onClick={() =>
						Modal.confirm({ title: 'Are you sure?', onOk: () => logout() })
					}
					icon={<LogoutOutlined />}
				>
					Logout
				</Menu.Item>
			</Menu>
		</div>
	);
};

export default SideBar;
