import { Input } from 'antd';

const FinderSearch = () => {
	return (
		<>
			<p>Enter the email address of the user to find user activity</p>
			<Input.Search size="large" />
		</>
	);
};

export default FinderSearch;
