import { Space } from 'antd';
import CreateAdmin from './CreateAdmin';
import CreateRole from './CreateRole';

const AdminManagement = () => {
	return (
		<Space>
			<CreateAdmin />
			<CreateRole />
		</Space>
	);
};

export default AdminManagement;
