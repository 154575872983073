import { Button, Input, message, Modal, Popconfirm, Table } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getLiquidityProviders from '../../../methods/actions/liquidity-providers';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';

interface IRemoveLiquidityProvider {
	id: any;
	isActive: boolean;
}
const RemoveLiquidityProvider: FC<IRemoveLiquidityProvider> = ({
	id,
	isActive,
}) => {
	const dispatch = useDispatch();

	const toggleProvider = async () => {
		try {
			const res = await baseuri.put(
				'/advanced/toggle-liquidity-provider-status',
				{ recordId: id }
			);
			if (res.status === 200) {
				message.success('done');
				dispatch(getLiquidityProviders());
			}
		} catch (e) {
			console.error(e);
		}
	};

	return isActive ? (
		<Popconfirm title="Are you sure?" onConfirm={() => toggleProvider()}>
			<Button danger size="small">
				Deactivate
			</Button>
		</Popconfirm>
	) : (
		<Popconfirm title="Are you sure?" onConfirm={() => toggleProvider()}>
			<Button type="primary" size="small">
				Activate
			</Button>
		</Popconfirm>
	);
};

const AddLiquidityProvider = () => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({ name: '', address: '' });

	const submit = async () => {
		try {
			const res = await baseuri.post('/advanced/add-liquidity-provider', state);
			if (res.status === 200) {
				setVisible(false);
				message.success(`${state.name} has been whitelisted`);
				dispatch(getLiquidityProviders());
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)} type="primary">
				Whitelist Provider
			</Button>
			<Modal
				title="Whitelist Liquidity Provider"
				footer={null}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>
					Whitelist a Liquidity Providers so that Xend Finance only accepts Buy
					and Selling from the Whitelisted providers.
				</p>
				<br />
				<div>
					<p>Liquidity Providers Name</p>
					<Input
						size="large"
						value={state.name}
						onChange={(e) => setState({ ...state, name: e.target.value })}
					/>

					<br />
					<br />

					<p>Wallet Address</p>
					<Input
						size="large"
						value={state.address}
						onChange={(e) => setState({ ...state, address: e.target.value })}
					/>

					<br />
					<br />

					<div>
						<Button onClick={submit} block type="primary" size="large">
							Submit
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

const LiquidityProviders = () => {
	const dispatch = useDispatch();
	const { liquidityProviders } = useSelector((store: any) => store.general);

	useEffect(() => {
		dispatch(getLiquidityProviders());
	}, []);

	const columns = [
		{
			title: 'Status',
			dataIndex: 'isActive',
			render: (isActive: any) =>
				isActive ? (
					<span className="material-icons" style={{ color: '#007bff' }}>
						toggle_on
					</span>
				) : (
					<span className="material-icons" style={{ color: 'gray' }}>
						toggle_off
					</span>
				),
		},
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Address',
			dataIndex: 'walletAddress',
		},
		{
			title: 'Last Edit By',
			dataIndex: 'editedBy',
			render: (editor: any) => editor.email,
		},
		{
			title: 'Last Updated',
			dataIndex: 'updatedAt',
			render: (date: any) => moment(date).format('lll'),
		},
		{
			title: '',
			dataIndex: '_id',
			render: (id: any, other: any) => (
				<RemoveLiquidityProvider id={id} isActive={other.isActive} />
			),
		},
	];

	return (
		<AdvancedSectionLayout title="Liquidity Providers">
			<div>
				<p>Whitelisted Liquidity Providers</p>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<AddLiquidityProvider />
			</div>
			<div>
				<Table
					columns={columns}
					pagination={false}
					dataSource={liquidityProviders}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default LiquidityProviders;
