import { Pagination, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import baseuri from '../../methods/baseuri';
import AdvancedSectionLayout from '../Layouts/Advanced';
import { TableProfile } from '../views/Transactions/Transactions';

const FraudList = () => {
	const history = useHistory();

	const [data, setData] = useState({
		loading: false,
		records: [],
		currentPage: 1,
		totalRecords: 0,
	});

	const fetchList = async (page = 1) => {
		try {
			setData({ ...data, loading: true });
			const res: any = await baseuri.get('/security/retrieve-watchlist', {
				params: { page },
			});
			const record = res.data.data;
			// setRecord(record);
			setData({ ...data, ...record, loading: false });
		} catch (e) {
			setData({ ...data, loading: false });
		}
	};

	useEffect(() => {
		fetchList();
	}, []);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'user',
			render: (x: any) => (
				<TableProfile onClick={() => history.push(`/user-profile/${x._id}`)}>
					<p>{x.fullName}</p>
					<p>@{x.username}</p>
				</TableProfile>
			),
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('lll'),
		},
	];

	return (
		<AdvancedSectionLayout title="Fraud List">
			<div>
				<p>{data.totalRecords} users</p>

				<Table
					loading={data.loading}
					pagination={false}
					dataSource={data.records}
					columns={columns}
				/>

				<br />

				<Pagination
					current={data.currentPage}
					onChange={(page) => fetchList(page)}
					showSizeChanger={false}
					total={data.totalRecords}
				/>
			</div>
		</AdvancedSectionLayout>
	);
};

export default FraudList;
