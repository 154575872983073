import { Button, message, Modal, Table } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import { commas } from '../../../methods/utils';
import UpdateLevel from './UpdateLevel';

interface IDeleteUserType {
	id: any;
}
const DeleteUserType: FC<IDeleteUserType> = ({ id }) => {
	const [loading, setloading] = useState(false);

	const deleteLevel = async () => {
		try {
			setloading(true);
			const res = await baseuri.delete(`admin/kycLevel/delete-kyclevel`, {
				params: {
					id,
				},
			});
			if (res.status === 200) {
				setloading(false);
				message.success('Level deleted successfully');
			}
		} catch (e) {
			setloading(false);
			console.error(e);
			message.error('Could not complete operation');
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete this Level?',
			onOk() {
				deleteLevel();
			},
		});
	};

	return (
		<Button
			danger
			shape="round"
			size="small"
			loading={loading}
			onClick={confirm}
			disabled
		>
			Remove Level
		</Button>
	);
};

interface IAllUserTypes {
	levelList: any;
}
const ViewKYCLevels: FC<IAllUserTypes> = ({ levelList }) => {
	const [visible, setVisible] = useState(false);

	const tableColumns = [
		{
			title: 'Level Name',
			key: 'levelName',
			render: (text: any) => <p>{text?.levelName}</p>,
		},
		{
			title: 'Level Number',
			key: 'levelNumber',
			render: (text: any) => <p>{text?.levelNumber}</p>,
		},
		{
			title: 'Maximum Daily Withdrawal',
			key: 'maximumWithdrawal',
			render: (text: any) => (
				<p>
					<strong>$</strong> {commas(text?.dailyWithdrawal, 10) || '0'}
				</p>
			),
		},
		{
			title: 'Maximum Monthly Withdrawal',
			key: 'maximumWithdrawal',
			render: (text: any) => (
				<p>
					<strong>$</strong> {commas(text?.monthlyWithdrawal, 10) || '0'}
				</p>
			),
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => <UpdateLevel details={text} />,
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => <DeleteUserType id={text?._id} />,
		},
	];

	return (
		<>
			<Button
				type="primary"
				shape="round"
				ghost
				onClick={() => setVisible(true)}
			>
				View KYC Levels
			</Button>
			<Modal
				title="All KYC Levels"
				visible={visible}
				onCancel={() => setVisible(false)}
				width={800}
				footer={null}
			>
				<Table columns={tableColumns} dataSource={levelList} />
			</Modal>
		</>
	);
};

export default ViewKYCLevels;
