import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import queue from './queue';
import fees from './fees';
import batch from './batch';
import web3conn from './web3conn';
import processList from './processList';
import general from './general';
import auth from './auth';

const reducers = combineReducers({
	queue,
	fees,
	batch,
	web3conn,
	processList,
	general,
	auth,
});

async function reduxStore() {
	const init: any = undefined;
	return createStore(reducers, init, applyMiddleware(thunk));
}

export default reduxStore;
