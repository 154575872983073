import { Button, message } from 'antd';
import { FC, useState } from 'react';
import baseuri from '../../methods/baseuri';

interface ICSVComponent {
	url: string;
	startDate: any;
	endDate: any;
	otherParams: any;
}
const CSVComponent: FC<ICSVComponent> = ({
	url,
	startDate,
	endDate,
	otherParams,
}) => {
	const [loading, setLoading] = useState(false);

	const getCsvData = async () => {
		setLoading(true);
		await baseuri
			.get(url, {
				params: {
					start: startDate,
					end: endDate,
					...otherParams,
					download: true,
				},
			})
			.then((res: any) => {
				setLoading(false);
				message.success('CSV file downloaded');

				const StaticURL = process.env.REACT_APP_BASE_URI?.replace('/v1/', '');
				const csvName = res?.data?.data?.downloadlink
					? res?.data?.data?.downloadlink
					: res?.data?.data?.downloadLink;
				const win = window.open(
					`${StaticURL}/static/front/downloads/${csvName}`,
					'_blank'
				);
				win?.focus();
			})
			.catch((err: any) => {
				setLoading(false);
				message.error(err?.response?.data?.message);
			});
	};
	return (
		<>
			<Button type="primary" loading={loading} onClick={getCsvData}>
				{loading ? 'Loading csv...' : 'Download CSV'}
			</Button>
			{/* <CSVLink data={csvData}>
        <span ref={ref}></span>
      </CSVLink> */}
		</>
	);
};
export default CSVComponent;
