import { Card, message } from 'antd';
import { useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';
import UpdateAPY from './UpdateAPY';

const InternalSettings = () => {
	const [loading, setLoading] = useState(true);
	const [apyData, setApyData] = useState({});
	const getCurrentAPY = async () => {
		setLoading(true);
		try {
			const res = await baseuri.get(`admin/all-Process-settings`);

			// eslint-disable-next-line no-console
			console.log(res);

			if (res.status === 200) {
				setApyData({ ...apyData, ...res.data.data[0] });
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};
	useEffect(() => {
		getCurrentAPY();
	}, []);
	// eslint-disable-next-line no-console
	console.log(loading);
	return (
		<AdvancedSectionLayout title="Internal Settings">
			<Card>
				<p>
					BUSD Token USD Price:{' '}
					<strong> {apyData?.busdTokenUsdPrice || '0'}</strong>
				</p>
				<p>
					XEND Token USD Price:{' '}
					<strong> {apyData?.xendTokenUsdPrice || '0'}</strong>
				</p>
				<p>
					XEND Token APR: <strong> {apyData?.xendTokenAPR || '0'}%</strong>
				</p>
			</Card>
			<br />
			<UpdateAPY details={apyData} setApyData={setApyData} />
		</AdvancedSectionLayout>
	);
};

export default InternalSettings;
