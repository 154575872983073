import { Modal, Button, Input, message } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

const AddBlacklistedDevice = () => {
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState({
		deviceId: '',
		userId: '',
	});

	const addDevice = async () => {
		try {
			await baseuri.post('/advanced/blacklisted-device', state);
			message.success('done');
		} catch (e: any) {
			message.error('failed');
		}
	};

	return (
		<>
			<Button onClick={() => setVisible(true)}>Add Blacklisted Device</Button>

			<Modal
				visible={visible}
				onOk={addDevice}
				onCancel={() => setVisible(false)}
				title="Add Device"
			>
				<div>
					<p>Device Id</p>
					<Input
						value={state.deviceId}
						onChange={(e) => setState({ ...state, deviceId: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>user Id</p>
					<Input
						value={state.userId}
						onChange={(e) => setState({ ...state, userId: e.target.value })}
					/>
				</div>
			</Modal>
		</>
	);
};

export default AddBlacklistedDevice;
