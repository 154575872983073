import baseuri from './baseuri';

const getUsersByWalletBalance = async (
	currency: string,
	amount: any,
	page: any
) => {
	try {
		const res: any = await baseuri.get('/admin/users-by-wallet-balance', {
			params: { page, pageSize: 10, currency, balance: amount },
		});

		if (res && res.status === 200) {
			return res.data.data;
		}
		return null;
	} catch (e) {
		console.error(e);
		return null;
	}
};

export default getUsersByWalletBalance;
