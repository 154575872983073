import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';

interface ISelectMailList {
	newsletter: any;
	setNewsLetter: Function;
}

const SelectMailList: FC<ISelectMailList> = ({ newsletter, setNewsLetter }) => {
	const [allMailList, setAllMailList] = useState({} as any);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters/maillist`)
			.then((res: any) => {
				setAllMailList(res?.data?.data);
			});
	}, []);

	return (
		<Select
			// mode="multiple"
			placeholder="Select user type"
			value={newsletter?.maillist}
			onChange={(e: any) => setNewsLetter({ ...newsletter, maillist: e })}
			style={{ width: '100%' }}
		>
			{Object.entries(allMailList)?.map(([key, value1]) => (
				<Select.Option key={`${key}`} value={`${value1}`}>
					{key}
				</Select.Option>
			))}
		</Select>
	);
};

export default SelectMailList;
