import { Card, message, Pagination, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { commas } from '../../../methods/utils';
import LockedFilters from './LockedFilters';
import TableHeaderSection from './TableHeaderSection';
import UnlockSavings from './UnlockSavings';

export const MyTable = styled(Table)`
	min-width: 250px;
	overflow-x: auto;

	& .ant-table-thead > tr > th {
		/* color: #8c8c8c; */
		padding: 16px 25px;
		font-weight: 700;
		background-color: transparent;
		font-size: 12px;
		color: red;
		min-width: 140px;
		/* max-width: 300px; */
		text-overflow: wrap;
	}

	& .ant-table-tbody > tr > td {
		padding: 16px 25px;
		min-width: 140px;
		/* max-width: 300px; */
		text-overflow: wrap;
		/* color: red; */
	}
`;
export const UsersListPage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1em;

	& .myCard {
		border-radius: 10px;
		gap: 1em;
	}
`;
export const MainSection = styled(Card)`
	border-radius: 10px;
	gap: 2em;
`;
export const MyTableCard = styled(Card)`
	/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
	margin-top: 20px;
	padding-top: 0px;
	border: 1px solid #d5d3d3;
	border-radius: 10px;
`;
const ColumnItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;

	& > div {
		display: flex;
		flex-direction: column;

		& .value {
			font-size: 12px;
			font-weight: 300;
		}
		& .title {
			font-size: 10px;
			font-weight: 100;
			font-style: italic;
			color: #bbb;
		}
	}
`;

const LockedFlexibleSavings = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		records: [],
		currentPage: 1,
		totalRecords: 0,
		totalPages: 0,
		pageSize: 0,
	} as any);

	const location = useLocation();

	const columns = [
		{
			key: '1',
			title: 'User',
			render: (text: any) => (
				<>
					<p>
						<Link
							title="View Profile"
							to={`/user-profile/${text?.userId?._id}`}
						>
							<p> {text?.userId?.fullName}</p>
							<p> @{text?.userId?.username}</p>
							<p> {text?.email}</p>
						</Link>
					</p>
					<br />
					<br />
					<br />
					<UnlockSavings
						details={text}
						url="admin/lock-flexible-savings"
						text="flexible"
					/>
				</>
			),
		},
		{
			key: '2',
			title: 'Plan Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">{text?.planName || '--'}</span>
						<span className="title">Plan Name</span>
					</div>
					<div>
						<span className="value">
							{text?.depositAmount ? commas(text?.depositAmount) : '0'} BUSD
						</span>
						<span className="title">Deposit Amount</span>
					</div>
					<div>
						<span className="value">
							{moment(text?.depositTime).format('lll')}
						</span>
						<span className="title">Deposite Time</span>
					</div>

					{/* <div>
						<span className="value">
							{moment.duration(text?.lockPeriod, 'milliseconds').asDays()} Day
						</span>
						<span className="title">Duration</span>
					</div> */}
				</ColumnItem>
			),
		},
		{
			key: '6',
			title: 'savings Info',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">{text?.protocolName}Protocol Name</span>
					</div>
					{text?.planState && (
						<div>
							<span className="value">{text?.planState}Plan State</span>
						</div>
					)}
					<div>
						<span className="value">
							{text?.shareBalance ? commas(text?.shareBalance) : '0'} BUSD
						</span>
						<span className="title">Share Balance</span>
					</div>
					{/* <div>
						<span className="value">{moment(text?.endDate).format('lll')}</span>
						<span className="title">End Date</span>
					</div>
					<div>
						<span className="value">
							{text?.cacheProfitLastDate &&
								moment(text?.cacheProfitLastDate).format('lll')}
						</span>
						<span className="title">last Profit Date</span>
					</div> */}
				</ColumnItem>
			),
		},

		{
			key: '3',
			title: 'Withdrawal Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{text?.withdrawalAmount ? commas(text?.withdrawalAmount) : '0'}{' '}
							BUSD
						</span>
						<span className="title">Withdrawal Amount</span>
					</div>
					<div>
						<span className="value">
							{text?.withdrawStatus ? commas(text?.withdrawStatus) : '0'} BUSD
						</span>
						<span className="title">Withdrawal Status</span>
					</div>
					<div>
						<span className="value">
							{moment(text?.withdrawalTime).format('lll')}
						</span>
						<span className="title">Withdrawal Time</span>
					</div>
				</ColumnItem>
			),
		},
		{
			key: '385',
			title: 'Lock Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{text?.locked ? 'Locked' : 'Unlocked'}
						</span>
						<span className="title">Lock Status</span>
					</div>
					<div>
						<span className="value">
							{text?.lockedDate ? moment(text?.lockedDate).format('lll') : '--'}
						</span>
						<span className="title">Lock Date</span>
					</div>
					<div>
						<span className="value">{text?.lockedComment}</span>
						<span className="title">Lock Comment</span>
					</div>
					<div>
						<span className="value">{text?.lockedAdmin?.email}</span>
						<span className="title">Lock Initiator</span>
					</div>
				</ColumnItem>
			),
		},
		{
			key: '385',
			title: 'Unlock Details',
			render: (text: any) => (
				<ColumnItem>
					<div>
						<span className="value">
							{text?.unLockedDate
								? moment(text?.unLockedDate).format('lll')
								: '--'}
						</span>
						<span className="title">UnLock Date</span>
					</div>

					{text?.unLockedAdmin && (
						<div>
							<span className="value">{text?.unLockedAdmin?.email}</span>
							<span className="title">UnLock Initiator</span>
						</div>
					)}
				</ColumnItem>
			),
		},
		// {
		// 	key: '62',
		// 	title: 'Lock Details',
		// 	render: (text: any) => (
		// 		<ColumnItem>
		// 			<div>
		// 				<span className="value">
		// 					{text?.locked ? 'Locked' : 'Not Locked'}
		// 				</span>
		// 				<span className="title">Lock Status</span>
		// 			</div>
		// 			{text?.locked && (
		// 				<>
		// 					<div>
		// 						<span className="value">{commas(text?.lockedAmount)}</span>
		// 						<span className="title">Locked Amount</span>
		// 					</div>
		// 					<div>
		// 						<span className="value">
		// 							{moment(text?.lockedDate).format('lll')}
		// 						</span>
		// 						<span className="title">Locked Date</span>
		// 					</div>
		// 					<div>
		// 						<span className="value">{text?.lockedAdmin?.email}</span>
		// 						<span className="title">Lock Initiator</span>
		// 					</div>
		// 					{text?.unLockedDate && (
		// 						<>
		// 							<div>
		// 								<span className="value">
		// 									{moment(text?.unLockedDate).format('lll')}
		// 								</span>
		// 								<span className="title">UnLock Date</span>
		// 							</div>
		// 							<div>
		// 								<span className="value">{text?.unLockedAdmin?.email}</span>
		// 								<span className="title">UnLock Initiator</span>
		// 							</div>
		// 						</>
		// 					)}
		// 				</>
		// 			)}
		// 		</ColumnItem>
		// 	),
		// },
		{
			key: '49',
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('lll'),
		},
		{
			key: '498325',
			render: (text: any) => (
				<UnlockSavings
					details={text}
					url="admin/lock-flexible-savings"
					text="flexible"
				/>
			),
		},
	];
	const getLocked = async () => {
		setData({
			records: [],
			currentPage: 1,
			totalRecords: 0,
			totalPages: 0,
			pageSize: 0,
		});
		try {
			const res: any = await baseuri.get('admin/locked-flexible-savings');

			if (res.status === 200) {
				setData(res.data.data);
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getLocked();
	}, [location.search]);
	return (
		<FullLayout pageTitle="Locked Savings">
			<LockedFilters />
			<br />
			<MainSection>
				<TableHeaderSection
					tableName="Locked Flexible savings"
					currentPage={data.currentPage || 0}
					totalRecords={data.totalRecords || 0}
				/>
				<MyTableCard>
					<MyTable
						loading={loading}
						columns={columns}
						dataSource={data?.records}
						pagination={false}
					/>

					<Pagination
						onChange={getLocked}
						disabled={loading}
						pageSize={data.pageSize}
						current={data.currentPage}
						total={data.totalRecords}
						showSizeChanger={false}
					/>
				</MyTableCard>
			</MainSection>
		</FullLayout>
	);
};

export default LockedFlexibleSavings;
