import { Card, Col, DatePicker, Row } from 'antd';

const FeatureReports = () => {
	return (
		<div>
			<h3 style={{ margin: '20px 0px' }}>Feature Usage Reports</h3>
			<Card
				style={{
					boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
					margin: '5px 0px',
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<div>
						<h3>Start Date</h3>
						<DatePicker />
					</div>
					<div>
						<h3>End Date</h3>
						<DatePicker />
					</div>
					<div>
						<h3>Feature</h3>
						{/* <Dropdown overlay={}>
							<Button>
								All <DownOutlined />
							</Button>
						</Dropdown> */}
					</div>
				</div>
			</Card>
			<Card style={{ margin: '15px 0px' }}>
				<Row gutter={[16, 24]} justify="space-between">
					<Col className="gutter-row" span={8}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									alignItems: 'center',
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
								}}
							>
								<h3> Used by:</h3>
								<p
									style={{
										color: 'black',
										fontSize: '25px',
										fontWeight: 'bolder',
									}}
								>
									85 %
								</p>
								<p
									style={{
										color: 'black',
										fontSize: '15px',
										fontWeight: 'bold',
									}}
								>
									23424 Users
								</p>
							</div>
						</Card>
					</Col>
					<Col className="gutter-row" span={8}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									alignItems: 'center',
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
								}}
							>
								<h3>Not Used By:</h3>
								<p
									style={{
										color: 'black',
										fontSize: '25px',
										fontWeight: 'bolder',
									}}
								>
									10 %
								</p>
								<p
									style={{
										color: 'black',
										fontSize: '15px',
										fontWeight: 'bold',
									}}
								>
									3424 Users
								</p>
							</div>
						</Card>
					</Col>
					<Col className="gutter-row" span={8}>
						<Card bordered={false}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'relative',
									alignItems: 'center',
									boxShadow: '0px 7px 30px -10px rgba(150,170,180,0.5)',
									borderRadius: '10px',
									justifyContent: 'center',
									padding: '15px ',
									height: '115px',
								}}
							>
								<h3>Total Users:</h3>

								<p
									style={{
										color: 'black',
										fontSize: '25px',
										fontWeight: 'bolder',
									}}
								>
									23424 Users
								</p>
							</div>
						</Card>
					</Col>
				</Row>
			</Card>
		</div>
	);
};

export default FeatureReports;
