import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';

const CreateLevel = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [levelDetails, setInputDetails] = useState({
		levelName: '',
		levelNumber: '',
		dailyWithdrawal: '',
		monthlyWithdrawal: '',
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.post(
				`admin/kycLevel/create-KycLevel`,
				levelDetails
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Level created Successfully');
				setInputDetails({
					levelName: '',
					levelNumber: '',
					dailyWithdrawal: '',
					monthlyWithdrawal: '',
				});
			} else {
				setloading(false);
				message.error(res?.message);
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error('something went wrong');
		}
	};

	return (
		<>
			<Button shape="round" type="primary" onClick={() => setVisible(true)}>
				Add Level
			</Button>
			<Modal
				title="Add Level"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Level Name :{' '}
					<Input
						placeholder="input level name"
						value={levelDetails?.levelName}
						onChange={(e: any) =>
							setInputDetails({ ...levelDetails, levelName: e.target.value })
						}
					/>
				</p>
				<p>
					Level Number (must be number 1, 2 or 3):{' '}
					<Input
						type="number"
						placeholder="input level Number"
						value={levelDetails?.levelNumber}
						onChange={(e: any) =>
							setInputDetails({ ...levelDetails, levelNumber: e.target.value })
						}
					/>
				</p>
				<p>
					Maximum Daily Withdrawal (in dollars) :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.dailyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								dailyWithdrawal: e.target.value,
							})
						}
					/>
				</p>
				<p>
					Maximum Monthly Withdrawal (in dollars) :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={levelDetails?.monthlyWithdrawal}
						onChange={(e: any) =>
							setInputDetails({
								...levelDetails,
								monthlyWithdrawal: e.target.value,
							})
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default CreateLevel;
