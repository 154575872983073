import { useEffect, useState } from 'react';
import { Table, Space, Card, message, Pagination, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { getUserTypes } from '../../../methods/users';
import CreateUserType from './CreateUserType';
import AllUserTypes from './AllUserTypes';
import KYCData from './KYCData';
import RemoveAsAgent from './RemoveAsAgent';
import CreateNewAgent from './CreateNewAgent';

const XforceAgents = () => {
	const [loading, setLoading] = useState(true);
	const [userTypeList, setUserTypeList] = useState([] as any);
	const [query, setQuery] = useState({
		page: 1,
		userType: '',
	});
	const [users, setUsers] = useState({
		getUserTypes: [],
		currentPage: 1,
		totalRecords: 0,
		totalPages: 0,
		pageSize: 0,
	});

	useEffect(() => {
		getUserTypes().then((res: any) => {
			setUserTypeList(res.data);
			setQuery({ ...query, userType: res.data[0]?._id });
		});
	}, []);

	const handleGetAgents = async (page = 1) => {
		setLoading(true);
		try {
			const res: any = await baseuri.get(
				`/admin/sortuser-by-usertype?userType=${query?.userType}`,
				{
					params: {
						page,
					},
				}
			);

			if (res.status === 200) {
				setUsers({ ...users, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			message.error(error.response.data.data.message);
		}
	};

	useEffect(() => {
		if (query?.userType) {
			setLoading(true);
			handleGetAgents();
		}
	}, [query]);

	const columns = [
		{
			title: 'Username',
			key: '_id',
			render: (text: any) => <strong>{text?.userId?.username}</strong>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => <KYCData userDetails={text?.userId} />,
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => (
				<RemoveAsAgent email={text.email} usertype={query?.userType} />
			),
		},
		{
			title: '',
			key: '_id',
			render: (text: any) => (
				<Space size="middle">
					<Link className="link" to={`/user-profile/${text?.userId?._id}`}>
						View Profile
					</Link>
				</Space>
			),
		},
	];

	return (
		<FullLayout pageTitle="User Type">
			<Card>
				<Space>
					<CreateUserType />
					<AllUserTypes userTypeList={userTypeList} />
					<CreateNewAgent />
				</Space>
				<Tabs
					defaultActiveKey={userTypeList[0]?._id}
					onChange={(key) => setQuery({ ...query, userType: key })}
				>
					{userTypeList.map((userType: any) => (
						<Tabs.TabPane tab={userType?.name} key={userType?._id}>
							<div>
								<p>
									<strong>{users.totalRecords} records</strong>
								</p>

								<Table
									loading={loading}
									columns={columns}
									dataSource={users?.getUserTypes}
									pagination={false}
								/>

								<br />
								<Pagination
									onChange={handleGetAgents}
									disabled={loading}
									pageSize={users.pageSize}
									current={users.currentPage}
									total={users.totalRecords}
									showSizeChanger={false}
								/>
							</div>
						</Tabs.TabPane>
					))}
				</Tabs>{' '}
			</Card>
		</FullLayout>
	);
};

export default XforceAgents;
