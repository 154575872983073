import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../methods/baseuri';

interface INewsLetterConfig {
	userId: string;
}
const BankInfoPage = styled.div``;
const ItemsSection = styled.section`
	display: flex;
	position: relative;
	/* border: 2px solid black; */
	flex-wrap: wrap;
	min-height: 150px;
	max-height: 300px;
	overflow-y: auto;
	align-items: center;
	justify-content: center;
	margin: 10px;
`;
const ItemMain = styled.div`
	display: flex;
	flex-direction: column;
	border: 2px solid black;
	border-radius: 10px;
	min-width: 250px;
	padding: 5px;
	margin: 5px;
`;
const BankDetails: FC<INewsLetterConfig> = ({ userId }) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({} as any);

	const getDetails = async () => {
		try {
			const res: any = await baseuri.get(
				`admin/user-bank-details?userId=${userId}`
			);

			if (res.status === 200) {
				setData(res.data.data);
			}
		} catch (error) {
			message.error('something went wrong');
		}
	};

	// eslint-disable-next-line no-console
	console.log(data);

	useEffect(() => {
		getDetails();
	}, []);

	return (
		<BankInfoPage>
			<Button
				type="primary"
				// shape="round"
				size="small"
				// ghost
				onClick={() => setVisible(true)}
			>
				View Bank Details
			</Button>
			<Modal
				width="350px"
				footer={null}
				visible={visible}
				closeIcon={<CloseOutlined onClick={() => setVisible(false)} />}
			>
				<ItemsSection>
					<ItemMain>
						<div>
							Payment Methods: <strong>Bank Transfer</strong>
						</div>
						<div>
							Payment Type: <strong>Bank</strong>
						</div>
						<div>
							Bank Name: <strong>Fidelity</strong>
						</div>
						<div>
							Account Name: <strong>Ekwealor Emmanuel</strong>
						</div>
						<div>
							Account Number: <strong>6171719931</strong>
						</div>
					</ItemMain>
					<ItemMain>
						<div>
							Payment Methods: <strong>Bank Transfer</strong>
						</div>
						<div>
							Payment Type: <strong>Bank</strong>
						</div>
						<div>
							Bank Name: <strong>Fidelity</strong>
						</div>
						<div>
							Account Name: <strong>Ekwealor Emmanuel</strong>
						</div>
						<div>
							Account Number: <strong>6171719931</strong>
						</div>
					</ItemMain>
					<ItemMain>
						<div>
							Payment Methods: <strong>Bank Transfer</strong>
						</div>
						<div>
							Payment Type: <strong>Bank</strong>
						</div>
						<div>
							Bank Name: <strong>Fidelity</strong>
						</div>
						<div>
							Account Name: <strong>Ekwealor Emmanuel</strong>
						</div>
						<div>
							Account Number: <strong>6171719931</strong>
						</div>
					</ItemMain>
				</ItemsSection>
			</Modal>
		</BankInfoPage>
	);
};

export default BankDetails;
