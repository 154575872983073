import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { FC, useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';

interface IUpdateAPY {
	details: any;
	setApyData: Function;
}

const UpdateAPY: FC<IUpdateAPY> = ({ details, setApyData }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [data, setData] = useState({
		xendTokenUsdPrice: details?.xendTokenUsdPrice,
		busdTokenUsdPrice: details?.busdTokenUsdPrice,
		xendTokenAPR: details?.xendTokenAPR,
	});

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await baseuri.put(
				`/admin/update-Process-settings`,
				data
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				setApyData(res.data.data);
				message.success('level updated Successfully');
			} else {
				setloading(false);
				message.error(res?.message);
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error('something went wrong');
		}
	};

	useEffect(() => {
		setData({
			xendTokenUsdPrice: details?.xendTokenUsdPrice,
			busdTokenUsdPrice: details?.busdTokenUsdPrice,
			xendTokenAPR: details?.xendTokenAPR,
		});
	}, [details]);

	return (
		<>
			<Button shape="round" size="small" onClick={() => setVisible(true)}>
				Update
			</Button>
			<Modal
				title="Update APY"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							update
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					Xend Token USD Price :{' '}
					<Input
						placeholder="input new xend token price"
						value={data?.xendTokenUsdPrice}
						onChange={(e: any) =>
							setData({ ...data, xendTokenUsdPrice: e.target.value })
						}
					/>
				</p>
				<p>
					BUSD token USD Price :{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="$"
						value={data?.busdTokenUsdPrice}
						onChange={(e: any) =>
							setData({ ...data, busdTokenUsdPrice: e.target.value })
						}
					/>
				</p>
				<p>
					Xend Token APY:{' '}
					<Input
						type="number"
						placeholder="input maximum withdrawal"
						prefix="%"
						value={data?.xendTokenAPR}
						onChange={(e: any) =>
							setData({ ...data, xendTokenAPR: e.target.value })
						}
					/>
				</p>
			</Modal>
		</>
	);
};

export default UpdateAPY;
