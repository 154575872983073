import { Button, Input, message, Modal, Popconfirm, Select } from 'antd';
import { useEffect, useState } from 'react';
import baseuri from '../../../methods/baseuri';
import { getRequests } from '../../../methods/users';

const CreateAdmin = () => {
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [roleId, setRoleId] = useState('');
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [roleList, setRoleList] = useState([] as any);

	useEffect(() => {
		getRequests('admin/roles?page=1&pageSize=10').then((res: any) => {
			setRoleList(res?.data?.records);
		});
	}, []);

	const handleCreate = async () => {
		try {
			setloading(true);
			const res = await baseuri.post('/admin/setup', {
				fullName,
				email,
				roleId,
			});

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('Admin created Successfully');
			}
		} catch (e: any) {
			setloading(false);
			console.error(e);
			message.error(e.toString());
		}
	};
	return (
		<>
			<Button
				type="primary"
				shape="round"
				size="middle"
				onClick={() => setVisible(true)}
			>
				Create New Admin
			</Button>
			<Modal
				title="Create New Admin User"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Submit
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				<p>
					FullName :{' '}
					<Input
						placeholder="input fullname"
						value={fullName}
						onChange={(e: any) => setFullName(e.target.value)}
					/>
				</p>
				<p>
					Email:{' '}
					<Input
						placeholder="input email"
						value={email}
						onChange={(e: any) => setEmail(e.target.value)}
					/>
				</p>

				<p>Select Role</p>
				<Select
					size="large"
					style={{ width: '100%', marginTop: 5 }}
					placeholder="Please Select"
					onChange={(e: any) => setRoleId(e)}
				>
					{roleList?.map((role: any) => (
						<Select.Option value={role?.id} key={role?.id}>
							{role?.title}
						</Select.Option>
					))}

					{/* <Select.Option value="super">Super Admin</Select.Option> */}
				</Select>
			</Modal>
		</>
	);
};

export default CreateAdmin;
