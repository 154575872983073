import { Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import baseuri from '../../../methods/baseuri';

const DetailStyle = styled.section`
	display: flex;
	margin-left: 30px;

	& > * {
			margin-right: 15px;
		}
	}
`;

interface IQueueStatuses {
	queue: 'deposit' | 'withdraw' | 'withdrawout';
}
const QueueStatuses: FC<IQueueStatuses> = ({ queue }) => {
	const [state, setState] = useState({
		pending: 0,
		processing: 0,
		completed: 0,
		canceled: 0,
		retry: 0,
		hold: 0,
		review: 0,
	});

	const getStatusDetails = async () => {
		try {
			const res: any = await baseuri.get('/advanced/queue-status', {
				params: { queue },
			});
			if (res.status === 200) {
				setState({ ...state, ...res.data.data });
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		getStatusDetails();
	}, []);

	return (
		<DetailStyle>
			{queue === 'withdrawout' && (
				<Tag color="yellow">
					<p>{state.review}</p>
					<p>Review</p>
				</Tag>
			)}
			<Tag color="orange">
				<p>{state.pending}</p>
				<p>Pending</p>
			</Tag>
			<Tag color="blue">
				<p>{state.processing}</p>
				<p>Processing</p>
			</Tag>
			{queue === 'withdrawout' && (
				<Tag color="maroon">
					<p>{state.retry}</p>
					<p>Retry</p>
				</Tag>
			)}
			{queue === 'withdrawout' && (
				<Tag color="red">
					<p>{state.hold}</p>
					<p>On-Hold</p>
				</Tag>
			)}
			<Tag color="green">
				<p>{state.completed}</p>
				<p>Completed</p>
			</Tag>
			<Tag color="gray">
				<p>{state.canceled}</p>
				<p>Cancelled</p>
			</Tag>
		</DetailStyle>
	);
};

export default QueueStatuses;
