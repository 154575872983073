import {
	Button,
	Divider,
	Input,
	message,
	Modal,
	Popconfirm,
	Select,
	Space,
} from 'antd';
import axios from 'axios';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EditorComponent from './EditorComponent';
// import PreviewNewsLetter from './PreviewNewsLetter';
import SelectMailList from './SelectMailList';
import SendTestNewsLetter from './SendTestNewsLetter';

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5em;
`;
interface IEditNewsLetter {
	newsLetter: any;
}
const EditNewsletter: FC<IEditNewsLetter> = ({ newsLetter }) => {
	const { user } = useSelector((store: any) => store.auth);
	const [visible, setVisible] = useState(false);
	const [loading, setloading] = useState(false);
	const [newsletter, setNewsLetter] = useState({
		subject: newsLetter.subject,
		bannerUrl: newsLetter.bannerUrl,
		previewMessage: newsLetter.previewMessage,
		maillist: newsLetter.maillist,
		access_code: 'password',
		deliveryType: newsLetter.deliveryType,
		instantProcessing: newsLetter.instantProcessing,
		creatorEmail: user?.email,
		content: newsLetter.content,
	});

	const instantProcessing = [
		{
			name: 'YES',
			value: true,
		},
		{
			name: 'No',
			value: false,
		},
	];

	const handleCreate = async () => {
		try {
			setloading(true);
			const res: any = await axios.post(
				`${process.env.REACT_APP_NEWSLETTER_URL}v1/newsletters`,
				newsletter
			);

			if (res.status === 200) {
				setVisible(false);
				setloading(false);
				message.success('NewsLetter sent');
			} else {
				setloading(false);
				message.info(res.data.message);
			}
		} catch (e: any) {
			setloading(false);
			message.error(e.response.data.data.message);
		}
	};

	return (
		<div>
			<Button
				type="primary"
				// shape="round"
				size="small"
				ghost
				onClick={() => setVisible(true)}
			>
				Edit
			</Button>
			<Modal
				title="Create Newsletter"
				width="50%"
				visible={visible}
				footer={[
					<Popconfirm title="Are you sure?" onConfirm={handleCreate}>
						<Button loading={loading} type="primary">
							Save
						</Button>
					</Popconfirm>,
				]}
				onCancel={() => setVisible(false)}
			>
				{' '}
				<ModalContent>
					<p>
						Access Code :{' '}
						<Input
							placeholder="input access code"
							value={newsLetter?.access_code}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, access_code: e.target.value })
							}
						/>
					</p>
					<p>
						Subject :{' '}
						<Input
							placeholder="input Title"
							value={newsLetter?.subject}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, subject: e.target.value })
							}
						/>
					</p>
					<p>
						Preview Message :{' '}
						<Input
							placeholder="input preview message"
							value={newsLetter?.previewMessage}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, previewMessage: e.target.value })
							}
						/>
					</p>
					<p>
						Banner URL :{' '}
						<Input
							placeholder="banner url"
							value={newsLetter?.bannerUrl}
							onChange={(e: any) =>
								setNewsLetter({ ...newsLetter, bannerUrl: e.target.value })
							}
						/>
						<br />
						You can use{' '}
						<a
							href="https://imgbb.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://imgbb.com
						</a>{' '}
						to Upload/generate banner url
					</p>
					<p>
						<p>Delivery Type: </p>
						<Select
							style={{ width: 200 }}
							placeholder="Select Type"
							value={newsLetter.instantProcessing}
							onChange={(e) => {
								setNewsLetter({ ...newsLetter, instantProcessing: e });
							}}
						>
							{instantProcessing?.map((n: any) => (
								<Select.Option key={n.value} value={n.value}>
									{n.name}
								</Select.Option>
							))}
						</Select>
					</p>
					<p>
						<p>Select MailList: </p>
						<SelectMailList
							newsletter={newsLetter}
							setNewsLetter={setNewsLetter}
						/>
					</p>
					<Divider />
					<strong>Hi {`${'{{Name}}'}`}</strong>
					<p>
						<EditorComponent
							newsletter={newsLetter}
							setNewsLetter={setNewsLetter}
						/>
					</p>
					<Space direction="horizontal">
						{/* <PreviewNewsLetter /> */}
						<SendTestNewsLetter newsletter={newsLetter} />
					</Space>
					<strong>List Of Test Emails</strong>
					<p>cabafor@gmail.com</p>
					<p> mercyemmanuelotomowo@gmail.com</p>
					<p>ugochukwuaronu@gmail.com</p> <p>emekaece@gmail.com</p>{' '}
					<p>ifihear@gmail.com</p>
					<p>olowogbayigbenga@gmail.com</p> <p>ekwealorobinna112@gmail.com</p>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default EditNewsletter;
