/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Card, Divider, Input, Space } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { adminLogin } from '../../methods/authenticate';
import Verify2FA from './Verify2FA';
import XendLogo from '../../logo.svg';

const LoginWrapper = styled.section`
	display: flex;
	justify-contents: center;
	align-items: center;
	height: 100%;
`;

const FormStyle = styled(Card)`
	padding: 20px;
	margin: 20px auto;
	width: 100%;
	max-width: 500px;
`;

const Login = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: '',
		password: '',
	});
	const [show2FA, setShow2FA] = useState({
		show: false,
		email: '',
		password: '',
	});

	const handleInput = (e: any) => {
		e.preventDefault();
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const submitForm = (e: any) => {
		e.preventDefault();
		const authData = { email: state.email, password: state.password };
		dispatch(adminLogin(authData, setShow2FA, setLoading));
		setState({ email: '', password: '' });
	};

	return (
		<LoginWrapper>
			{show2FA.show && (
				<Verify2FA email={show2FA.email} password={show2FA.password} />
			)}

			<FormStyle>
				<div>
					<img src={XendLogo} alt="" width={100} />
					<h2>Admin Dashboard</h2>
				</div>

				<br />

				<form onSubmit={submitForm}>
					<Space direction="vertical" size={20} style={{ width: '100%' }}>
						<div>
							<label htmlFor="">Email Address</label>
							<Input
								name="email"
								type="email"
								required
								size="large"
								value={state.email}
								onChange={handleInput}
							/>
						</div>
						<div>
							<label htmlFor="">Password</label>
							<Input.Password
								name="password"
								required
								type="password"
								size="large"
								value={state.password}
								onChange={handleInput}
							/>
						</div>
						<div>
							<Button
								loading={loading}
								htmlType="submit"
								block
								type="primary"
								size="large"
							>
								Submit
							</Button>
						</div>
					</Space>
				</form>
				<Divider />
				<div>
					<a
						target="_blank"
						href="https://admin.xend.finance/"
						rel="noreferrer"
					>
						Main application
					</a>
				</div>
				<div>
					<a
						title="Go to the dashboard for Staging environment"
						target="_blank"
						href="https://adminstaging.xend.finance/"
						rel="noreferrer"
					>
						Staging application
					</a>
				</div>
			</FormStyle>
		</LoginWrapper>
	);
};

export default Login;
