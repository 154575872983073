import { Tag, Pagination, Tabs, Tooltip, message, Table } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { FullscreenOutlined } from '@ant-design/icons';
import { enum2Currency, enum2TxnType } from '../../../methods/transactions';
import { truncateAddress } from '../../../methods/utils';
import { getRequests } from '../../../methods/users';
import baseuri from '../../../methods/baseuri';
import UpdateTransactionRecordStatus from './UpdateTransactionRecordStatus';

const tnxTableLabels = () => [
	{
		title: 'Visibility',
		dataIndex: 'isActive',
		render: (text: any) => text.toString(),
	},
	{
		title: 'Amount',
		render: (text: any) => (
			<span>
				<strong>{text.amount}</strong> {enum2Currency(text.currency)}
			</span>
		),
	},
	{
		title: 'Transaction Type',
		dataIndex: 'typeOfTransaction',
	},
	{
		title: 'Description Wallet',
		dataIndex: 'description',
	},
	{
		title: 'Hash',
		dataIndex: 'txnHash',
		render: (hash: any) =>
			hash &&
			hash.length > 1 && (
				<a href={hash} target="_blank" rel="noreferrer">
					Verify
				</a>
			),
	},
	{
		title: 'Destination',
		dataIndex: 'toAddress',
		render: (text: any) =>
			text && (
				<>
					{' '}
					<Tooltip title="Click to copy address" color="green">
						<span
							role="none"
							onClick={() => {
								navigator.clipboard.writeText(text);
								message.success('Address copied');
							}}
						>
							{truncateAddress(text, 6)}{' '}
						</span>
					</Tooltip>
					<Tooltip title="View on BscScan" color="blue">
						<a
							href={`https://bscscan.com/address/${text}`}
							target="_blank"
							rel="noreferrer"
						>
							<FullscreenOutlined />
						</a>
					</Tooltip>
				</>
			),
	},
	{
		title: 'From',
		dataIndex: 'fromAddress',
		render: (text: any) =>
			text && (
				<>
					{' '}
					<Tooltip title="Click to copy address" color="green">
						<span
							role="none"
							onClick={() => {
								navigator.clipboard.writeText(text);
								message.success('Address copied');
							}}
						>
							{truncateAddress(text, 6)}{' '}
						</span>
					</Tooltip>
					<Tooltip title="View on BscScan" color="blue">
						<a
							href={`https://bscscan.com/address/${text}`}
							target="_blank"
							rel="noreferrer"
						>
							<FullscreenOutlined />
						</a>
					</Tooltip>
				</>
			),
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('MMM DD, YYYY h:mm:ss a'),
	},
	{
		title: '',
		render: (row: any) => (
			<UpdateTransactionRecordStatus
				transactionId={row._id}
				transactionType="wallet"
			/>
		),
	},
];

const tnxTableLabels2 = () => [
	{
		title: 'Amount',
		render: (text: any) => (
			<span>
				<strong>{text.amount}</strong> {enum2Currency(text.currency)}
				<br />
				<br />
			</span>
		),
	},
	{
		title: 'Network',
		dataIndex: 'network',
	},
	{
		title: 'Hash',
		dataIndex: 'txnHash',
		render: (text: any) => (
			<a href={text} target="_blank" rel="noreferrer">
				Verify
			</a>
		),
	},
	{
		title: 'Type',
		width: 100,
		dataIndex: 'transactionState',
		render: (text: any) => <Tag>{enum2TxnType(text)}</Tag>,
	},
	{
		title: 'Description',
		dataIndex: 'description',
	},
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text: any) => moment(text).format('MMM DD, YYYY h:mm:ss a'),
	},
	{
		title: 'status',
		dataIndex: 'isActive',
		render: (x: any) => (x ? 'true' : 'false'),
	},
	{
		title: '',
		render: (row: any) => (
			<UpdateTransactionRecordStatus
				transactionId={row._id}
				transactionType="savings"
			/>
		),
	},
];

interface IUserRecentTable {
	userId: string;
}
const UserRecentTable: FC<IUserRecentTable> = ({ userId }) => {
	const [loading, setLoading] = useState(false);
	const [userSavingsTxs, setUserSavingsTxs] = useState({
		records: [],
		currentPage: 1,
		totalRecords: 0,
	});

	const [walletTxn, setWalletTxn] = useState({
		records: [],
		currentPage: 1,
		totalRecords: 0,
	});

	const fetchWalletTxn = async (page: any) => {
		setLoading(true);

		const response: any = await baseuri.get('admin/user-wallet-transactions', {
			params: { id: userId, page, pageSize: 10 },
		});
		if (response.status === 200) {
			setWalletTxn({ ...walletTxn, ...response.data.data });
		}

		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);

		fetchWalletTxn(walletTxn.currentPage);

		getRequests(`admin/user-savings-transactions?id=${userId}`).then(
			(res: any) => {
				setUserSavingsTxs(res?.data);

				setLoading(false);
			}
		);
	}, []);

	const handlePageSelection = (page: any) => {
		setLoading(true);
		getRequests(
			`admin/user-savings-transactions?id=${userId}&page=${page}`
		).then((res: any) => {
			setUserSavingsTxs(res?.data);

			setLoading(false);
		});
	};

	return (
		<div>
			<Tabs defaultActiveKey="1">
				<Tabs.TabPane tab="Wallet Txs" key="1">
					<Table
						loading={loading}
						pagination={false}
						columns={tnxTableLabels()}
						dataSource={walletTxn.records}
						size="small"
					/>
					<br />

					<Pagination
						onChange={fetchWalletTxn}
						disabled={loading}
						current={walletTxn.currentPage}
						total={walletTxn.totalRecords}
						showSizeChanger={false}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Savings Txs" key="2">
					<div className="table-responsive">
						<Table
							loading={loading}
							pagination={false}
							columns={tnxTableLabels2()}
							dataSource={userSavingsTxs.records}
							size="small"
							className="ant-border-space"
						/>
					</div>
					<br />

					<Pagination
						onChange={handlePageSelection}
						disabled={loading}
						current={userSavingsTxs?.currentPage}
						total={userSavingsTxs?.totalRecords}
						showSizeChanger={false}
					/>
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default UserRecentTable;

// amount: "20"
// createdAt: "2021-11-21T08:22:42.639Z"
// currency: 1
// description: "Wallet funding with SwitchWallet"
// fromAddress: "0x4769FB8cBb51535533a485fD91d1649a688af192"
// toAddress: "0xc8b45Ce75923fe7bF7fbBdc4B8A23c0a896F9125"
// txnHash: "https://testnet.bscscan.com/tx/0x63025440072905cbac3586d500e642aecd7b6c464d4c7916d71be6a809fe22bc"
// typeOfTransaction: "funding"
// updatedAt: "2021-11-21T08:22:42.639Z"
// userId: "6140c624cbdfda4e9261728e"
// __v: 0
// _id: "619a01d28961d677b0764b32"
