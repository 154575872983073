import { Button, Input, Table } from 'antd';
import { useState } from 'react';
import baseuri from '../../../methods/baseuri';
import AdvancedSectionLayout from '../../Layouts/Advanced';

const ViewRewardRecord = () => {
	const [planId, setPlanId] = useState('');
	const [data, setData] = useState<Array<any>>([]);

	const onSearchRecord = async () => {
		try {
			const x: any = await baseuri.get('/advanced/xend-reward-record', {
				params: { planId },
			});
			if (x.status === 200) {
				setData(x.data.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<AdvancedSectionLayout title="View Reward Record">
			<p />

			<Input onChange={(e) => setPlanId(e.target.value)} />
			<Button onClick={onSearchRecord}>Search</Button>

			<Table dataSource={data} />
		</AdvancedSectionLayout>
	);
};

export default ViewRewardRecord;
