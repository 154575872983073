import { FullscreenOutlined } from '@ant-design/icons';
import { message, Pagination, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { format } from 'timeago.js';
import FullLayout from '../../../layouts/layout-components/FullLayout';
import baseuri from '../../../methods/baseuri';
import { commas, truncateAddress } from '../../../methods/utils';
import { DateSection } from '../../Advanced/DepositQueue/DepositQueue';
import Filter from './Filter';

const setColor = (status: string) => {
	switch (status) {
		case 'done':
			return 'green';
		case 'pending':
			return 'orange';
		case 'failed':
			return 'red';
		case 'processing':
			return 'blue';
		default:
			return 'gray';
	}
};
const DoubleFunding = () => {
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState({
		totalRecords: 0,
		pageSize: 10,
		currentPage: 1,
		totalPage: 0,
		result: [],
	});

	const getData = async (page = 1, pageSize = 10) => {
		setLoading(true);
		setList({
			totalRecords: 0,
			pageSize: 10,
			currentPage: 1,
			totalPage: 0,
			result: [],
		});
		try {
			const res: any = await baseuri.get('admin/compliance/double-fundings', {
				params: {
					page,
					pageSize,
				},
			});

			if (res.status === 200) {
				setList({ ...list, ...res.data.data });
				message.success('Successfully fetched records');
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	// amountReceived: '100';
	// charge: '0';
	// createdAt: '2022-09-01T06:00:31.855Z';
	// depositAddress: '0x44665E3C36c1aC07fB0a336277dAf9E676Ee6eB1';
	// fundingStatus: 0;
	// receivingAddress: '0x3348aAa6B4168d5aa9b8A2B0E8743dd77651F5b7';
	// status: '3';
	// totalAmountDeposited: '100';
	// transactionHash: '0x40203c80318365ae0e95a7bb623582de857c8fa88993fc18666f9a24f2e3fbe0';
	// updatedAt: '2022-09-01T06:00:31.855Z';
	// userEmail: 'evaobianuju01@gmail.com';
	// __v: 0;
	// _id: '63104a7f22347affef6d353f';

	const columns = [
		// {
		// 	key: 0,
		// 	title: '',
		// 	render: (x: any) => <Selection data={x} selectChange={rowSelectChange} />,
		// },
		// {
		// 	key: 1,
		// 	title: 'User',
		// 	render: (data: any) => (
		// 		<>
		// 			<p>
		// 				<Link
		// 					title="View Profile"
		// 					to={`/user-profile/${data?.userId?._id}`}
		// 				>
		// 					{data.userEmail}
		// 				</Link>
		// 			</p>
		// 			<p>
		// 				<Link
		// 					title="View Profile"
		// 					to={`/user-profile/${data?.userId?._id}`}
		// 				>
		// 					@{data.userId?.username}
		// 				</Link>
		// 			</p>
		// 		</>
		// 	),
		// },
		{
			key: 'userEmail',
			title: 'Email',
			render: (data: any) => data.userEmail,
		},
		{
			key: 'status',
			title: 'Status',
			dataIndex: 'status',
			render: (x: any) => <Tag color={setColor(x)}>{x}</Tag>,
		},
		{
			key: 'fundingStatus',
			title: 'Funding Status',
			dataIndex: 'fundingStatus',
			render: (x: any) => <Tag color={setColor(x)}>{x}</Tag>,
		},
		{
			key: '5423545',
			title: 'Amount Received',
			dataIndex: 'amountReceived',
			render: (x: any) => `${x} BUSD`,
		},
		{
			key: '435362',
			title: 'Amount Received',
			dataIndex: 'totalAmountDeposited',
			render: (x: any) => `${x} BUSD`,
		},
		{
			key: '3452',
			title: 'Deposit Address',
			dataIndex: 'depositAddress',
			render: (x: any) => (
				<>
					{' '}
					<Tooltip title="Click to copy address" color="green">
						<span
							role="none"
							onClick={() => {
								navigator.clipboard.writeText(x);
								message.success('Address copied');
							}}
						>
							{truncateAddress(x)}
						</span>
					</Tooltip>
					<Tooltip title="View on BscScan" color="blue">
						<a
							href={`https://bscscan.com/address/${x}`}
							target="_blank"
							rel="noreferrer"
						>
							<FullscreenOutlined />
						</a>
					</Tooltip>
				</>
			),
		},
		{
			key: 'receivingAddress',
			title: 'Receiving Address',
			dataIndex: 'receivingAddress',
			render: (x: any) => (
				<>
					{' '}
					<Tooltip title="Click to copy address" color="green">
						<span
							role="none"
							onClick={() => {
								navigator.clipboard.writeText(x);
								message.success('Address copied');
							}}
						>
							{truncateAddress(x)}
						</span>
					</Tooltip>
					<Tooltip title="View on BscScan" color="blue">
						<a
							href={`https://bscscan.com/address/${x}`}
							target="_blank"
							rel="noreferrer"
						>
							<FullscreenOutlined />
						</a>
					</Tooltip>
				</>
			),
		},
		{
			key: 99,
			title: 'Processed By',
			dataIndex: 'processList',
			render: (data: any) => <p>{data?.creator?.email}</p>,
		},
		{
			key: 4,
			title: 'Date Updated',
			dataIndex: 'updatedAt',
			render: (x: any) => (
				<DateSection>
					{' '}
					{moment(x).format('MMM Do YY, h:mm:ss a')}{' '}
					<strong>{format(x)}</strong>
				</DateSection>
			),
		},
		{
			key: 49,
			title: 'Date Created',
			dataIndex: 'createdAt',
			render: (x: any) => moment(x).format('MMM Do YY, h:mm:ss a'),
		},
		// {
		// 	key: 10,
		// 	title: '',
		// 	render: (x: any) => <CancelDepositRequest data={x} />,
		// },
		// {
		// 	key: 50,
		// 	title: '',
		// 	render: (x: any) => (
		// 		<UpdateQueueItemStatus
		// 			removeItem={(status: any) => updateStatus(x._id, status)}
		// 			queueItemId={x._id}
		// 			whichQueue="deposit"
		// 		/>
		// 	),
		// },
		// {
		// 	key: 111,
		// 	title: '',
		// 	render: (x: any) => (
		// 		<UpdateQueueStatus queueId={x._id} queueType="deposit" />
		// 	),
		// },
	];

	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	return (
		<FullLayout pageTitle="Double Funding">
			<Filter />
			<p>
				<br />
				<strong>{commas(list.totalRecords, 2)} records</strong>
			</p>
			{/* <p>
              <CSVComponent url={currentUrl} />
            </p> */}
			<Table
				loading={loading}
				columns={columns}
				dataSource={list.result}
				pagination={false}
			/>
			<br />
			<Pagination
				onChange={getData}
				disabled={loading}
				pageSize={10}
				current={list.currentPage}
				total={list.totalRecords}
				showSizeChanger={false}
			/>
		</FullLayout>
	);
};

export default DoubleFunding;
